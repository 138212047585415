import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MainReport from './MainReport';

function PdfExport(props) {
    const componentRef = React.useRef(null);

    return (
        <span>
            <ReactToPrint
                trigger={() => (
                    <IconButton className={"mr-2 floate-right default"} color="primary">
                        <Tooltip title="Export PDF" placement="top">
                            <PictureAsPdfIcon />
                        </Tooltip>
                    </IconButton>
                )}
                content={() => {
                    return componentRef.current;
                }}
            />
            <div style={{ display: "none" }}>
                {props.pdfExport && <MainReport ref={componentRef} />}
            </div>
        </span>
    )
}

export default PdfExport;