import React, { Component } from 'react';

import TreeBase from '../../components/diff-view/tree/components/TreeBase';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import RepoAnalysisFileView from './RepoAnalysisFileView';
import RepoAnalysisTreeBase from './RepoAnalysisFileTreeView/RepoAnalysisTreeBase';

var nodeInfo = [];
var filePath = '';
var commentsCount = '';
class RepoAnalysisTreeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filePath: null,
            commentsCount: ''
        }
        this.getTreeObject = this.getTreeObject.bind(this);
        this.updateFile = this.updateFile.bind(this);
    }

    getTreeObject() {
        var result = [];
        var level = { result };
        this.props.data.map((path, index) => {
            path.path.toString.split('/').reduce((r, name, i, a) => {
                if (!r[name]) {
                    nodeInfo.push(name);
                    r[name] = { result: [] };
                    path.type = path.type ? path.type : '';
                    if(r.result !== undefined){
                        r.result.push({ name, children: r[name].result, path: path });
                    }
                }
                return r[name];
            }, level)
        })
        filePath = result[0]?.path?.path?.toString;
        commentsCount = result[0]?.path?.commentsCount;
        return result;
    }

    updateFile(filePath, commentsCount) {
        this.setState({filePath: filePath});
        this.setState({commentsCount : commentsCount});
    }

    render() {
        return (
            <div className="row m-0 p-0 tree-view">
                <div className="file-list p-0 fit-hight overflow-auto" style={{ width: this.state.expand ? '10%' : '25%', transition: '0.3s all' }}>
                    <div className="head bg-white w-100 p-2 position-sticky sticky-top">
                        <h4>Files <span className="float-right align-middle" onClick={() => { this.setState({ expand: !this.state.expand }) }} style={{ transform: this.state.expand ? 'rotate(0deg)' : 'rotate(180deg)', transition: '0.3s all' }}><DoubleArrowIcon /></span></h4>
                    </div>
                    <div onClick={() => { this.setState({ expand: false }) }}>
                        <RepoAnalysisTreeBase data={this.getTreeObject()}  node={nodeInfo} updateFile={this.updateFile} />
                    </div>
                </div>
                <div className="file-view p-0 fit-hight" style={{ width: this.state.expand ? '90%' : '75%' }}>
                    <RepoAnalysisFileView 
                    isTree={true} 
                    scanId={this.props.scanId} 
                    filePath = {this.state.filePath ? this.state.filePath : filePath}
                    commentsCount = {this.state.commentsCount || this.state.commentsCount === 0 ? this.state.commentsCount : commentsCount} 
                    type ={this.props.type}
                    />
                </div>
            </div>
        );
    }
}

export default RepoAnalysisTreeView;