import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Error500 from '../../../../src/core/error/error';
import GridView from './component/Grid';
import ListView from './component/ListView';
import { LocalToUTCTime } from '../../../core/service/timezone';
import Dropdown from 'react-bootstrap/Dropdown';
import { authHeader } from '../../../core/login/services/auth-header';
import GetAppIcon from '@material-ui/icons/GetApp';
import projectServices from '../../project/services/project-service';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Snackbars from '../../../core/error/Snackbars';

const user = JSON.parse(localStorage.getItem('users'));
const homePageStyle = theme => ({
    root: {
        width: "300px"
    },
    selected: {
        backgroundColor: "#3f51b5 !important",
        color: "white"
    }
});

const USERS_SUMMARY = gql`
    query userSummary($projectGroupName: String, $sortBy: String, $direction: Direction, $filter: String!, $startDate: Long, $endDate: Long, $category: String!) {
        users: userSummary(projectGroupName: $projectGroupName, sortBy: $sortBy, direction: $direction, filter: $filter, startDate: $startDate, endDate: $endDate, category: $category) {
            _id
            data {
                id
                developer {
                    id
                    username
                    name
                    emailId
                }
                numOfReviews
                repositories
                numOfLinesAdded
                numOfUnresolvedComments
                unresolvedReviewRequestCount
                unresolvedReviewRequest
                numOfReviewAttempts
                numOfRepositories
                qualityPercentage
                languages {
                    language
                    percentage
                }
            }
            lastUpdatedDate
        }
        projectGroupsWithoutPagination {  
            id   
            projectGroupName
        }
    }`;

const SUMMARY_FILTER_OPTIONS = gql`
    query summaryFilterOptions {
        filter: summaryFilterOptions
     }`;

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

class Users extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef();
        this.state = {
            showModal: false,
            showLanguages: null,
            configurationData: [],
            values: [],
            projectList: [],
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            dateLabel: 'Today',
            label: '',
            sortBy: 'numOfLinesAdded',
            direction: 'DESC',
            filter: '',
            flag: false,
            category: 'PULL REQUEST',
            project: 'ALL',
            loading: false,
            sort: 'LINES OF CODE',
            projectGroupName: 'All',
            projectGroup: 0,
            snackbarOpen: false,
        }
        this.viewType = this.viewType.bind(this);
        this.projectService = new projectServices();
        this.sortByUsers = this.sortByUsers.bind(this);
        this.filterByProject = this.filterByProject.bind(this);
        this.handlePopupClose = this.handlePopupClose.bind(this);
        this.handlePopupClick = this.handlePopupClick.bind(this);
        this.handleDropDown = this.handleDropDown.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleCustomDropDownProject = this.handleCustomDropDownProject.bind(this);
        this.handleDropDownProject = this.handleDropDownProject.bind(this);
    }

    viewType() {
        this.setState({
            flag: !this.state.flag
        })
    }

    componentDidMount() {
        var filterBy = {};
        this.projectService.getProjectList({
            pageSize: 7,
            page: 0,
            sortBy: '',
        }, filterBy).then((res) => {
            this.setState({ loading: false, projectList: res.data, filterData: res.data })
        })
    }

    handleChange = (e) => {
        var filterBy = { projectName: e.target.value };
        this.projectService.getProjectList({ pageSize: 7, page: 0 }, filterBy).then(res => {
            this.setState({ values: res.data });
        })
    }

    getDateOptions(picker) {
        var seletedDate = '';
        if (picker.startDate.format('YYYY-MM-DD') === picker.endDate.format('YYYY-MM-DD')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }

        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    reviewByDate(event, picker, refetch) {
        var data = this.getDateOptions(picker);
        if (picker.chosenLabel !== 'Date Range' && data && data.dateObj) {
            this.setState({
                dateLabel: data.label,
                groupByDateFilter: null,
                startDate: data.dateObj.startDate.format('YYYY-MM-DD'),
                endDate: data.dateObj.endDate.format('YYYY-MM-DD')
            }, () => {
                refetch();
            });
        } else {
            this.setState({
                dateLabel: 'Date Range',
                groupByDateFilter: null,
                startDate: null,
                endDate: null,
            }, () => {
                refetch();
            });
        }
    }

    handlePopupClose() {
        this.setState({ showPopup: null });
    }

    handlePopupClick(event, filter) {
        this.setState({
            showPopup: event.currentTarget,
            defaultFilter: filter
        });
    }

    handleDropDown(e) {
        this.setState({
            category: e
        })
    }

    handleDropDownProject(e) {
        this.setState({
            sort: e
        })
    }

    handleCustomDropDownProject(projectId, projectGroupName) {
        this.setState({
            projectGroup: projectId,
            projectGroupName: projectGroupName

        })
    }

    filterByProject(refetch, direction, sortBy, filter) {
        this.setState({
            sortBy: sortBy,
            direction: direction,
            filter: filter,
            showPopup: null
        }, () => {
            refetch();
        });
    }

    handleDownload(projectGroupId, organizationId, startDate, endDate) {
        fetch(`/code-review-service/getQualityReportWithDefects?projectGroupId=${projectGroupId}&startDate=${startDate}&endDate=${endDate}&organizationId=${organizationId}`, {
            headers: authHeader()
        }).then(async res => {
            if (res.ok) {
                let data = await res.blob();
                var a = document.createElement("a");
                a.href = URL.createObjectURL(data);
                a.download = "Quality-Report-With-Defect-Details(" + (startDate + ") - (" + endDate) + ").xls";
                a.click();
                this.setState({ snackbarOpen: true });
                return "success";
            }
        });        
    }

    sortByUsers(refetch, direction, sortBy, filter) {
        if (this.state.sortBy === sortBy) {
            this.setState({
                sortBy: sortBy,
                direction: direction === 'DESC' ? 'ASC' : 'DESC',
                filter: filter
            });
        } else {
            this.setState({
                sortBy: sortBy,
                direction: 'DESC',
                showPopup: null
            });
        }
        refetch();
    }

    render() {
        const { classes } = this.props;
        return (
            <Query query={SUMMARY_FILTER_OPTIONS}>
                {({ loading, error, data }) => {
                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                    if (error) return <div className="text-center">
                        <Error500 />
                    </div>
                    const { filter } = data;

                    return (
                        <div className="col-md-12 user-management mt-3">
                            <Query query={USERS_SUMMARY} variables={{ projectGroupName: this.state.projectGroupName, sortBy: this.state.sortBy, startDate: LocalToUTCTime(this.state.startDate, 'x'), endDate: LocalToUTCTime(this.state.endDate, 'x'), direction: this.state.direction, filter: this.state.filter || (filter && filter[0]), category: this.state.category ? this.state.category : "" }}>
                                {({ loading, error, data, refetch }) => {
                                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                                    if (error) return <div className="text-center">
                                        <Error500 />
                                    </div>
                                    const { users } = data || { users: { data: [] } };
                                    return (
                                        <div className="row">
                                            <div className="project-icon-container p-0">
                                                <Button>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="primary" >
                                                            {this.state.category}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('PULL REQUEST');
                                                            }}>PULL REQUEST</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('COMPARE');
                                                            }}>COMPARE</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('COMMIT ID');
                                                            }}>COMMIT ID</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('ALL');
                                                            }}>ALL</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown >
                                                </Button>
                                                <Button>
                                                    <Dropdown >
                                                        <Dropdown.Toggle variant="primary" >
                                                            {this.state.sort}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDownProject('LINES OF CODE');
                                                                this.sortByUsers(refetch, this.state.direction, 'numOfLinesAdded', this.state.filter);
                                                            }}>
                                                                {this.state.sortBy == "numOfLinesAdded" ? this.state.direction === 'DESC' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon /> : ''}
                                                                LINES OF CODE</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDownProject('QUALITY');
                                                                this.sortByUsers(refetch, this.state.direction, 'qualityPercentage', this.state.filter)
                                                            }}>
                                                                {this.state.sortBy == "qualityPercentage" ? this.state.direction === 'DESC' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon /> : ''}
                                                                QUALITY</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDownProject('REVIEWS');
                                                                this.sortByUsers(refetch, this.state.direction, 'numOfReviews', this.state.filter)
                                                            }}>
                                                                {this.state.sortBy == "numOfReviews" ? this.state.direction === 'DESC' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon /> : ''}
                                                                REVIEWS</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown >
                                                </Button>
                                                <DateRangePicker containerStyles={{ display: "inline-block", marginRight: "10px" }}
                                                    ranges={this.ranges}
                                                    maxDate={moment().format('MM/DD/YYYY')}
                                                    onApply={(event, picker) => this.reviewByDate(event, picker, refetch)}>
                                                    <div className={`custom-select dateRange ${this.state.startDate && classes.selected}`}>
                                                        {this.state.dateLabel}
                                                    </div>
                                                </DateRangePicker>
                                                {
                                                    this.state.category === "PULL REQUEST"
                                                    &&
                                                    users.data.length !== 0
                                                    &&
                                                    user.currentProjectGroupName !== "All"
                                                    &&
                                                    <IconButton className="mr-2 default">
                                                        <Tooltip title="Download xls Report With Defect Details" placement="top">
                                                            <GetAppIcon onClick={() => this.handleDownload(user.currentProjectGroupId, user.currentOrganizationId, this.state.startDate, this.state.endDate)} />
                                                        </Tooltip>
                                                    </IconButton>
                                                }
                                                <IconButton className="button-style active mr-2" onClick={() => this.viewType()}>
                                                    {this.state.flag ? <Tooltip title="Grid View" placement="top">
                                                        <AppsOutlinedIcon />
                                                    </Tooltip> :
                                                        <Tooltip title="List View" placement="top">
                                                            <FormatListBulletedOutlinedIcon />
                                                        </Tooltip>}
                                                </IconButton>

                                                <IconButton className="mr-2 default button-style" onClick={() => refetch()}>
                                                    <Tooltip title="Refresh" placement="top">
                                                        <RefreshIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </div>
                                            <div className="col-md-12 p-0">
                                                <div
                                                    className="container-fluid pt-1 pl-0 pr-0 pb-0"
                                                    style={{ width: '100%' }}
                                                >
                                                    {!this.state.flag
                                                        ? <GridView users={users.data} />
                                                        : <ListView users={users.data} />
                                                    }
                                                </div>
                                            </div>
                                            {this.state.snackbarOpen &&
                                                <Snackbars
                                                    status="success"
                                                    autoHideDuration={3000}
                                                    message={"Report has been downloaded Successfully."}
                                                    showSnackbar={true}
                                                    closeSnackBar={false}
                                                />
                                            }

                                        </div>
                                    );
                                }}
                            </Query>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default withStyles(homePageStyle)(Users);
