import moment from 'moment';

const UTCtoLocalTime = (date, format) => {
    if (format) {
        return moment.utc(date).local().format(format);
    }
    return moment.utc(date).local().format();
}

const LocalToUTCTime = (date, format) => {
    if (format) {
        return moment.utc(date).local().format(format);
    }
    return moment.utc(date).format();
}

const LocalDate = () => {
    return LocalToUTCTime(moment().format('YYYY-MM-DD'), 'x');
}

export {
    UTCtoLocalTime,
    LocalToUTCTime,
    LocalDate
}