const navBar = [
    {
        path: '/admin',
        text: 'Admin',
        icon: 'dashboard',
        isSuperAdmin: true,
        role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
]

export {
    navBar
} 