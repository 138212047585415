import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import AccordionSummary1 from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { red } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        // fontSize: theme.typography.pxToRem(15),
        // flexBasis: '33.33%',
        // flexShrink: 0,
    },
    secondaryHeading: {
        // fontSize: theme.typography.pxToRem(15),
        // color: theme.palette.text.secondary,
    },
    mainHeader: {
        border: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: 10,
        marginTop: 10
    },
    summary: {
        backgroundColor: "lightgray",
        paddingLeft: 5
    },
    icon: {
        pointerEvents: 'auto',
    },
    allButton: {
        pointerEvents: 'auto',
    },
    copyBtn: {
        pointerEvents: 'auto'
    },
    grid: {
        paddingLeft: '2px',
        paddingRight: '8px',
    },
    hideRow: {
        display: 'none'
    },
    showRow: {

    },
    all: {
        display: "block"
    },
    tableCell: {
        borderBottom: 0,
        padding: "6px 6px 6px 10px !important"
    },
    pathContainer: {
        display: "flex"
    },
    path: {
        fontSize: 13
    },
    lineNumber: {
        fontSize: 13,
        color: "red"
    },
    isActive: {
        display: "block !important"
    },
    nonActive: {
        display: "none !important"
    },
    paper: {
        fontSize: '13px',
        padding: '10px',
        maxWidth: '100%',
        backgroundColor: 'mistyrose'
    },
    typography: {
        paddingTop: "4px !important",
        paddingBottom: "4px !important"
    },
    accDetails: {
        display: "initial !important",
        padding: "0px !important"
    },
    copyIcon: {
        fontSize: "13px !important",
        paddingLeft: 4
    },
    addRemoveIcon: {
        padding: "0 10px",
        color: "#007bff"
    },
    showMoreText: {
        fontSize: 13,
        fontWeight: 'bolder'
    }
}));

const AccordionSummary = withStyles({
    content: {
        '&.MuiExpansionPanelSummary-content': {
            display: "initial !important",
            margin: 0
        },
    }
})(AccordionSummary1);



export default function DuplicationTreeView(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel0');
    const [activeId, setActiveId] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [expandHeader, setExpandHeader] = React.useState(false);

    const [toggleOpen, setToggleOpen] = React.useState([])

    const duplications = props.data;
    const totalElements = props.totalElements;
    const size = props.size;
    const number = props.number;

    const handleChange = (panel) => (event, isExpanded) => {
        event.preventDefault();
        setExpanded(isExpanded ? panel : false);
    };

    function displayAllRow(event, index) {
        event.preventDefault();
        setActiveId(index)
    }

    const copyClip = (e, value) => {
        e.stopPropagation();
        e.preventDefault();
        var copyText = value;
        copyText = copyText.replace(/&amp;/g, '&');
        var textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setOpen(true);
        //this.setState({ open: true });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.parentFunct(newPage, rowsPerPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        props.parentFunct(0, rowsPerPage)
    };

    const handleExpandHeader = (event, data, indexValue) => {
        event.stopPropagation();
        setExpandHeader(!data);

        const res = toggleOpen.indexOf(indexValue) > -1;

        if(res){
            var index = toggleOpen.indexOf(indexValue);
            if (index !== -1) {
                toggleOpen.splice(index, 1);
            }
        }else{
            toggleOpen.push(indexValue);
        }

    };

    return (
        <div className={classes.root}>
            {
                duplications?.map((duplication, index) => (
                    <Accordion className={classes.mainHeader} expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.icon} />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className={classes.summary}
                        >
                            <TableContainer>
                                <Table
                                    className={classes.table} size="small" aria-label="a dense table">
                                    <TableBody>
                                        {
                                            duplication?.file?.map((filepath, key) => {
                                                let path = filepath.path;
                                                let half = Math.floor(path.length / 3);

                                                return (
                                                    <TableRow
                                                        key={key}
                                                        className={
                                                            `
                                                                ${activeId === index ? classes.isActive : ""}
                                                                ${
                                                                    key > 1
                                                                    ?
                                                                        toggleOpen.indexOf(index) > -1
                                                                        ?
                                                                        classes.showRow
                                                                        :
                                                                        classes.hideRow
                                                                    :
                                                                    classes.showRow
                                                                }
                                                            `
                                                        }
                                                    >
                                                        <TableCell className={classes.tableCell} scope="row" style={{ width: "5%", textAlign: "center" }}>
                                                            <Typography style={{ fontWeight: "bold" }}>{key === 0 ? parseInt(number * size) + parseInt(index + 1) : ""}</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} scope="row" style={{ width: "75%" }}>
                                                            <div className={classes.pathContainer}>
                                                                <Typography className={classes.path}>
                                                                    {
                                                                        path.length > 80
                                                                            ?
                                                                            path.slice(0, half) + "..." + path.slice(parseInt(half) + parseInt(half), path.length)
                                                                            :
                                                                            path
                                                                    }
                                                                </Typography>
                                                                <span
                                                                    title="Copy Url"
                                                                    className={classes.copyBtn}
                                                                    onClick={(e) => copyClip(e, filepath.path)}
                                                                >
                                                                    <i className={`material-icons align-middle ${classes.copyIcon}`}>file_copy</i>
                                                                </span>
                                                                {key === 1 && duplication.file.length > 2 &&
                                                                    <Tooltip title="Click to view all the file path">
                                                                        <span
                                                                            className={classes.addRemoveIcon}
                                                                            onClick={(e) => handleExpandHeader(e, expandHeader, index)}>
                                                                            <Typography className={classes.showMoreText}>
                                                                                {
                                                                                    toggleOpen.indexOf(index) > -1
                                                                                    ?
                                                                                    "Show less"
                                                                                    : 
                                                                                    "Show more"
                                                                                }
                                                                            </Typography>
                                                                        </span>
                                                                    </Tooltip>

                                                                }
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="right" style={{ width: "20%" }}>
                                                            <Typography className={classes.lineNumber}>Start Line : {filepath.line} , End Line : {filepath.endline}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accDetails}>
                            <Paper square variant="outlined" className={classes.paper}>
                                <Grid container xs={12} spacing={2}>
                                    {
                                        duplication?.duplicationCodeFragment?.text?.map((code, ckey) => (
                                            <>
                                                <Grid item sm={1} style={{ maxWidth: '5.333333%', textAlign: "center" }} className={classes.typography}>
                                                    {ckey + 1}
                                                </Grid>
                                                <Divider orientation="vertical" flexItem></Divider>
                                                <Grid item sm={11} justifyContent="center" className={classes.typography} style={{ whiteSpace: "pre-wrap" }}>
                                                    {code}
                                                </Grid>
                                            </>
                                        ))
                                    }
                                </Grid>
                            </Paper>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
            <TablePagination
                component="div"
                count={totalElements}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={event => handleChangeRowsPerPage(event)}
                rowsPerPageOptions={[10]}
            />
            <Snackbar
                open={open}
                onClose={handleClose}
                message="Copied"
                autoHideDuration={1500}
            />
        </div>
    );
}