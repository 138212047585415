import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { AgGridReact } from 'ag-grid-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import Add from '@material-ui/icons/AddCircle';
import Error500 from '../../../core/error/error';
import UpdateOrganization from './models/UpdateOrganization';

const GET_ORGANIZATION_LIST = gql`
query organizations {
    organizations {
            id
            organizationName
            organizationDescription,
            developersGroupId,
            reviewersGroupId,
            pushNotification,
            pluginToken,
            repositoryInfo {
                repository,
                host,
                port,
                scheme,
                userOrganizationMapping,
                syncRemoteReviewComments,
                repoScanFlag,
                sslCertificate,
                sslCertificateEnabled
            },
            workflowInfo {
                workflow,
                workflowUserId,
                workflowPassword,
                workflowBaseURL,
                defectTracking
            }
      }
}
`;

class UserManagement extends Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef();
        this.state = {
            showEditModal: false,
            filter: '',
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
            orgData: { id: null },
            columnDefs: [{
                headerName: "Organization Name", field: "organizationName", suppressSizeToFit: false,
            }, {
                headerName: "Organization Discription", field: "organizationDescription", suppressSizeToFit: false
            }, {
                headerName: "Repositories", field: "repositoryInfo", suppressSizeToFit: false, cellRenderer: function (params) {
                    return params.data.repositoryInfo.map(e => e.repository).join(", ");
                }
            }, {
                headerName: "Action", suppressSizeToFit: false, cellRenderer: function (params) {
                    var RulesConfigBtn = "<div><button class='btn btn-link " + (params.data.rules && params.data.rules.length ? "" : "text-danger") + " '><i class='material-icons'>more_horiz</i></button></div>";
                    return RulesConfigBtn;
                }
            }]
        }
        this.onGridReady = this.onGridReady.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.handleEditDialog = this.handleEditDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
    }

    onFilterTextBoxChanged(event) {
        this.setState({ filter: event.target.value })
        this.gridApi.setQuickFilter(event.target.value);
    }

    handleEditDialog(grid) {
        if (grid) {
            this.setState({
                orgData: grid.data
            })
        }
        if (!grid) {
            this.setState({ showEditModal: false });
        }
        if (grid && grid.colDef.headerName === 'Action') {
            this.setState({ showEditModal: true });
        }
    }

    handleClose() {
        this.setState({
            showEditModal: !this.state.showEditModal,
            orgData: { id: null }
        });
    }

    render() {
        return (
            <div className="col-md-12 user-management">
                <Query query={GET_ORGANIZATION_LIST}>
                    {({ loading, error, data, refetch }) => {
                        if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                        if (error) return <div className="text-center">
                            <Error500 />
                        </div>
                        return (
                            <div className="row">
                                <div className="col-md-12">
                                    <div
                                        className="ag-theme-material container-fluid pt-1 pl-0 pr-0 pb-0"
                                        style={{
                                            height: 'calc(100vh - 150px)', width: '100%'
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-md-3 mb-2">
                                                <TextField type="text"
                                                    label="Search"
                                                    id="filter-text-box"
                                                    value={this.state.filter}
                                                    onChange={this.onFilterTextBoxChanged}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="offset-md-3 col-md-6 col-6 mt-4 refresh-icon">
                                                <Tooltip title="Add" placement="top">
                                                    <Add onClick={() => this.handleClose()} style={{ verticalalign: 'middle' }} className="mr-3" color="primary" />
                                                </Tooltip>
                                                <Tooltip title="Refresh" placement="top">
                                                    <RefreshIcon onClick={() => refetch()} style={{ verticalalign: 'middle' }} color="primary" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={data.organizations}
                                            pagination={true}
                                            animateRows={true}
                                            onGridReady={this.onGridReady}
                                            defaultColDef={this.state.defaultColDef}
                                            suppressRowClickSelection={true}
                                            onCellClicked={this.handleEditDialog}
                                            paginationAutoPageSize={true}>
                                        </AgGridReact>
                                        {this.state.showEditModal && <UpdateOrganization open={this.state.showEditModal} data={this.state.orgData} handleClose={() => {
                                            refetch();
                                            this.handleClose();
                                        }} />}
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

export default UserManagement;