import Home from './../home/home';
import Admin from './../admin/';
import ProjectManagement from './../project/project-management-tabs';
import ReviewDetails from './../reviews/review-detail';
import ManageReviews from './../reviews/manage-reviews';
import Index from './../users/index';
import Auth from './../../core/service/auth';
import RepoAnalysisReviews from '../RepoAnalysis/RepoAnalysis-Reviews';
import RepoAnalysisDashboard from '../RepoAnalysis/RepoAnalysis-dashboard';
import FullScanDashboard from '../RepoAnalysis/FullScanDashboard';
var user = JSON.parse(localStorage.getItem('users'));

const router = [
    {
        path: '/reviews/manualReviewStatus',
        component: ManageReviews,
        access: true,
        exact: true
    },
    {
        path: '/reviews/approve',
        component: ManageReviews,
        access: true,
        exact: true
    },
    {
        path: '/reviews',
        component: ManageReviews,
        access: true,
        exact: true
    },
    user?.repoScanFlag && {
        path: '/fullscans',
        component: RepoAnalysisReviews,
        access: true,
        exact: true
    },
    {
        path: '/fullscans-dashboard/:id',
        component: FullScanDashboard,
        access: true
    },
    {
        path: '/review-details/:id/:approve',
        component: ReviewDetails,
        access: Auth.hasRole(user, ['ROLE_APPROVER'])
    },
    {
        path: '/review-details/:id',
        component: ReviewDetails,
        access: true
    },
    {
        path: '/home',
        component: Home,
        access: Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_ADMIN'])
    },
    {
        path: '/admin',
        component: Admin,
        isSuperAdmin: Auth.hasRole(user, ['ROLE_SUPER_ADMIN']),
        access: Auth.hasRole(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])
    },
    {
        path: '/projects',
        component: ProjectManagement,
        refresh: true,
        access: Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN'])
    },
    {
        path: '/users',
        component: Index,
        access: true,
        exact: true
    }
];

export {
    router
};

