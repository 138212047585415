import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#eaf1fb',
        fontWeight: 600
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function DependencyEvidence(props) {
  const classes = useStyles();
  const evidence = props.data


  return (
    <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Source</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Value</StyledTableCell>
                    <StyledTableCell>Confidence</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    evidence?.vendorEvidence && evidence.vendorEvidence.map((row, index) => (
                        <StyledTableRow  key={index}>
                            <StyledTableCell component="th" scope="row">
                                { row.type }
                            </StyledTableCell>
                            <StyledTableCell>{row.confidence}</StyledTableCell>
                            <StyledTableCell>{row.source}</StyledTableCell>
                            <StyledTableCell>{row.name}</StyledTableCell>
                            <StyledTableCell>{row.value}</StyledTableCell>
                        </StyledTableRow >
                    ))
                }
                {
                    evidence?.productEvidence && evidence.productEvidence.map((row, index) => (
                        <StyledTableRow  key={index}>
                            <StyledTableCell component="th" scope="row">
                                { row.type }
                            </StyledTableCell>
                            <StyledTableCell>{row.confidence}</StyledTableCell>
                            <StyledTableCell>{row.source}</StyledTableCell>
                            <StyledTableCell>{row.name}</StyledTableCell>
                            <StyledTableCell>{row.value}</StyledTableCell>
                        </StyledTableRow >
                    ))
                }
                {
                    evidence?.versionEvidence && evidence.versionEvidence.map((row, index) => (
                        <StyledTableRow  key={index}>
                            <StyledTableCell component="th" scope="row">
                                { row.type }
                            </StyledTableCell>
                            <StyledTableCell>{row.confidence}</StyledTableCell>
                            <StyledTableCell>{row.source}</StyledTableCell>
                            <StyledTableCell>{row.name}</StyledTableCell>
                            <StyledTableCell>{row.value}</StyledTableCell>
                        </StyledTableRow >
                    ))
                }
            </TableBody>
      </Table>
    </TableContainer>
  );
}
