export function colorByPercentage(percentage) {
    if (percentage >= 0 && percentage <= 40){
        return '#D30B34'
    }
    else if (percentage > 40 && percentage <= 70) {
        return '#FFBF00'
    }
    else if (percentage >= 71) {
        return '#229254'
    }
}


export function colorByPercentageDuplication(percentage) {
    if (percentage >= 0 && percentage <= 10) {
        return '#229254'
    }
    else if (percentage > 10 && percentage <= 15) {
        return '#FFBF00'
    }
    else if (percentage >= 15) {
        return '#D30B34'
    }
}

export function vulnerablityColor (count){
    if(count <= 0){
        return '#229254'
    }
    else{
        return '#D30B34'
    }
}

export function numberToK(number){
    if(number.toString().length >= 4 ) 
    {
        const numWithK = Math.trunc(number / 1000) + 'K';
        return numWithK;
    }
    else
    { 
        const plainNumber = number;
        return plainNumber; 
    }
}

export function qualityGateColor(qualityGate){
    if (qualityGate === 'Passed'){
        return '#229254'
    }
    else if (qualityGate === 'Warning'){
        return '#FFBF00'
    }
    else if (qualityGate === 'Failed'){
        return '#D30B34'
    }
}