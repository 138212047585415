import { Typography } from '@material-ui/core';
import React from 'react';

var user = JSON.parse(localStorage.getItem('users'));

function PdfHeader(props) {
    return (
        <div className="head text-center">
            <Typography variant='h4'>Reviews By Repository</Typography>
            <Typography variant='subtitle1'>Project Name : {user.currentProjectGroupName}</Typography>
            <Typography variant='subtitle2'>{props.startDate} - {props.endDate}</Typography>
        </div>
    );
}

export default PdfHeader;