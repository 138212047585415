import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import preferenceService from '../../../core/service/preference';

const qualityGate = [
    { label: 'All', value: 'All' },
    { label: 'Passed', value: 'Passed' },
    { label: 'Warning', value: 'Warning' },
    { label: 'Failed', value: 'Failed' }
];

function QualityGateStatusPicker(props) {

    const [fieldData, setFieldData] = React.useState("");

    React.useEffect( () => {
        setFieldData(preferenceService.getValue('FullScanGridValues')[props.columnDef.field]);
    })

    const handleQualityGateChange = (e) => {
        if (e.target.value === 'All') {
            props.onFilterChanged(props.columnDef.tableData.id, '')
        } else {
            props.onFilterChanged(props.columnDef.tableData.id, e.target.value) 
        }
        setFieldData(e.target.value)
    }

    return (
        <div>
            <Select
                value={fieldData === "" ? "All" : fieldData}
                onChange={(e) => handleQualityGateChange(e)}
            >
                {
                    qualityGate.map(status => {
                        return <MenuItem value={status.value}>{status.label}</MenuItem>
                    })
                }
            </Select>
        </div>
    );
}

export default QualityGateStatusPicker;