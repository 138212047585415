import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import RuleDescription from './RuleDescription';
import LazyLoad from 'react-lazyload';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";


import { forceCheck } from 'react-lazyload';
import Auth from './../../../core/service/auth';

var user = JSON.parse(localStorage.getItem('users'));
var ruleSetData = [];
var changedList = {
    enabled: [],
    disabled: []
};

class RuleSets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: [],
            showRuleModal: false,
            currentRuleSetData: this.props.data,
            currentRuleDetails: {},
            currentRule: {},
            filter: '',
            filtered: [],
            isSelectAll: this.props.data.every((value) => value.enabled),
        }
        this.handleToggle = this.handleToggle.bind(this);
        this.handleEditRule = this.handleEditRule.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.cleanData = this.cleanData.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    }

    componentDidMount() {
        forceCheck();
        this.prev = 0
        this.props.onRef(this);
        this.setState({
            filtered: this.props.data
        });
    }

    componentDidUpdate() {
        forceCheck();
    }

    handleNavigation = (e) => {

        const window = e.currentTarget;

        if (this.prev > window.scrollTop + 30) {
        } else if (this.prev < window.scrollTop + 30) {
            forceCheck();
        }
        this.prev = window.scrollTop;
    };

    cleanData() {
        // Child Method Trigger
        changedList = {
            enabled: [],
            disabled: []
        };
    }

    componentWillUnmount() {
        forceCheck()
        this.props.onRef(undefined)
    }

    handleEditRule = value => () => {
        if (value) {
            this.setState({
                showRuleModal: true,
                currentRule: value
            });
        }
    }

    handleDialogClose() {
        this.setState({ showRuleModal: false });
    }

    showInfo = value => () => {
        this.setState({
            currentRuleDetails: value
        })
    }

    selectAll = value => () => {
        var tempRuleSet = this.state.currentRuleSetData.map((rule, index) => {
            rule.enabled = !value;
            return rule;
        })
        this.setState({
            currentRuleSetData: tempRuleSet,
            isSelectAll: !this.state.isSelectAll
        })
        if (this.props.isQuery) {
            if (!this.state.isSelectAll) {
                changedList.enabled = Array.from(new Set(changedList.enabled.concat(tempRuleSet.map(rule => rule.ruleId))))
            } else {
                changedList.disabled = Array.from(new Set(changedList.enabled.splice(tempRuleSet.map(rule => rule.ruleId))))
            }

            this.props.ruleSets(null, {}, {}, changedList)
        }
    }

    handleToggle = value => () => {

        var currentIndex = this.state.checked.indexOf(value.ruleName);
        var newChecked = [...this.state.checked];
        if (!value.enabled) {
            changedList.enabled.push(value.ruleId);
            if (changedList.disabled.includes(value.ruleId)) {
                changedList.disabled.splice(changedList.disabled.indexOf(value.ruleId), 1);
            }
        }
        if (value.enabled) {
            changedList.disabled.push(value.ruleId);
            if (changedList.enabled.includes(value.ruleId)) {
                changedList.enabled.splice(changedList.enabled.indexOf(value.ruleId), 1);
            }
        }
        if (currentIndex === -1) {
            ruleSetData.push(value);
            newChecked.push(value.ruleName);
        } else {
            ruleSetData.splice(currentIndex, 1);
            newChecked.splice(currentIndex, 1);
        }
        this.props.ruleSets(ruleSetData, this.props.config, value, changedList)
        this.setState({ checked: newChecked });
    };

    onFilterTextBoxChanged(event) {
        let currentList = [];
        let newList = [];
        if (event.target.value !== "") {
            currentList = this.props.data;
            newList = currentList.filter(data => {
                const searchResult = data.ruleName.toLowerCase();
                const filter = event.target.value.toLowerCase();
                return searchResult.includes(filter);
            });
        } else {
            newList = this.props.data;
        }
        this.setState({
            filtered: newList
        });
    }

    render() {

        return (
            <div className="w-100">
                <div className="col-md-5 mb-3">
                    <TextField type="text"
                        label="Search"
                        id="filter-text-box"
                        onChange={this.onFilterTextBoxChanged}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <List className="rules-list" id={'ruleList' + this.props.keyss} onScroll={(e) => { this.handleNavigation(e) }}>
                            {<ListItem key={"all"} role={undefined} dense button >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={this.state.isSelectAll}
                                        tabIndex={-1}
                                        disabled={this.props.reviewFlag || !Auth.hasRole(user, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])}
                                        onClick={this.selectAll(this.state.isSelectAll)}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'all' }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={"Select All"} />
                            </ListItem>}
                            {this.state.filtered.map((value, index) => {
                                const labelId = `checkbox-list-label-${value.ruleName}`;
                                return (
                                    <LazyLoad key={index} height={300}
                                        throttle
                                        placeholder={
                                            <div className="row m-0 p-1">
                                                <div className="col-1 p-2">
                                                    <Skeleton variant="rect" width={30} height={30} />
                                                </div>
                                                <div className="col-10 my-auto">
                                                    <Skeleton variant="text" width={"100%"} />
                                                </div>
                                            </div>
                                        } scrollContainer={'#ruleList' + this.props.keyss}>
                                        <ListItem key={value.ruleName} role={undefined} dense button>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={value.enabled}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    disabled={this.props.reviewFlag || !Auth.hasRole(user, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])}
                                                    onClick={this.handleToggle(value)}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText 
                                                title={ value.ruleName }
                                                id={labelId} 
                                                primary={ value.ruleName.length > 32 ? value.ruleName.slice(0,32)+"..." : value.ruleName} 
                                            />
                                            <ListItemSecondaryAction>
                                                <InfoIcon onClick={this.showInfo(value)} className="cursor-pointer" />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </LazyLoad>
                                );
                            })}
                        </List>
                    </div>
                    <div className="col-md-7">
                        {this.state.currentRuleDetails.ruleName
                            ?
                            <RuleDescription projectId={this.props.projectId}
                                ruleFlag={this.props.ruleFlag}
                                data={this.state.currentRuleDetails}
                                onRef={ref => (this.child = ref)}
                                isRuleEditVisible={!(this.props.reviewFlag || !Auth.hasRole(user, ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPER_ADMIN']))}
                                refresh={(e) => {
                                    this.props.refresh && this.props.refresh(e)
                                }}
                                languageName={this.props.languageName} />
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default RuleSets;