import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbars from '../../../../core/error/Snackbars';
import Error500 from './../../../../core/error/error';
import Auth from '../../../../core/service/auth';
import preferenceService from '../../../../core/service/preference';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { ListItemSecondaryAction, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const GET_USER_ROLES = gql`
query data($isSuperAdmin: Boolean) {
    roles(isSuperAdmin: $isSuperAdmin) {
            id
            name
    }
    organizations {
        id
        organizationName
        organizationDescription,
        developersGroupId,
        reviewersGroupId,
        repositoryInfo {
            repository,
            host,
            port,
            scheme
        }
    }
}
`;

const ADD_USER_ORG = gql`
mutation assgineOrganization($userIds: [Long], $roles: [Long], $organizationId: Long) {
    assgineOrganization(
        userIds: $userIds,
        roles: $roles,
        organizationId: $organizationId
    )
}
`;

const REMOVE_USER_ORG = gql`
mutation removeOrganization($userId: Long, $organizationId: Long) {
    removeOrganization(
        userId: $userId,
        organizationId: $organizationId
    )
}
`;


var allRoles;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

var user = preferenceService.getValue('users', 'local');
let currentOrg;

class ChangeRole extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId: this.props.userData.id,
            removed: [],
            roles: [],
            role: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
        this.getRole = this.getRole.bind(this);

    }

    toggleSnackbars() {
        this.setState({
            showSnackbar: false
        })
    }

    componentDidMount() {

        this.props.userData && this.props.userData.organizations && this.props.userData.organizations.forEach(data => {
            if (data.organizationId === user.currentOrganizationId)
                currentOrg = data
        })
        this.setState({
            orgId: user.currentOrganizationId,
            roles: !Auth.hasRole(user, ['ROLE_SUPER_ADMIN']) ? currentOrg.roles.map(e => e.name) : []
        })
    }

    getRole(data) {
        allRoles = data;
        return allRoles;
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    handleDialog() {
        this.setState({
            name: [],
        });
        this.props.refresh();
        this.props.onClose();
    }

    handleChange = event => {
        var RoleName = event.target.value;
        this.setState({ userId: this.props.userData.id, roles: RoleName });
    }

    render() {
        return (
            <div className="w-100">
                <Mutation mutation={ADD_USER_ORG} onCompleted={this.handleDialog}>
                    {(assgineOrganization, { loading, error, data }) => (
                        <form id="userId" name="form" onSubmit={e => {
                            e.preventDefault();
                            assgineOrganization({
                                variables: {
                                    userIds: [ this.state.userId ], roles: allRoles.filter(role => {
                                        return this.state.roles.includes(role.name);
                                    }).map(element => element.id), organizationId: this.state.orgId
                                }
                            });
                        }}>{loading && <p>Loading...</p>}
                            {(data && !data.assgineOrganization && <Snackbars status="error" message="Could not Update Roles. Please contact Administration" showSnackbar={true} closeSnacker={true} />) || (error &&
                                <Snackbars status="error" message={error.message.replace('Exception while fetching data (/updateProject) :', '')} showSnackbar={true} closeSnacker={true} />)
                            }
                            {data && data.assgineOrganization &&
                                <Snackbars status="success" message='Roles Added Successfully' showSnackbar={true} closeSnacker={true} />
                            }
                            <div className="row">
                                <div className="col-md-7">
                                    <Query query={GET_USER_ROLES} variables={{ isSuperAdmin: Auth.hasRole(user, ['ROLE_SUPER_ADMIN']) || !!this.state.orgId }}>
                                        {({ loading, error, data }) => {
                                            if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                                            if (error) return <div className="text-center">
                                                <Error500 />
                                            </div>
                                            return (
                                                <div>
                                                    {Auth.hasRole(user, ['ROLE_SUPER_ADMIN'])
                                                        ? <Autocomplete
                                                            options={data.organizations}
                                                            getOptionLabel={(option) => option.organizationName}
                                                            onChange={(event, newValue) => {
                                                                if (newValue && newValue.id) {
                                                                    let [defaultRole] = this.props.userData.organizations.filter(data => {
                                                                        return data.organizationId === newValue.id
                                                                    }).map(e => e.roles)
                                                                    this.setState({ 
                                                                        orgId: newValue.id,
                                                                        roles: defaultRole ? defaultRole.map(e => e.name) : [],
                                                                        role: !data.organizationName
                                                                    });

                                                                }
                                                            }}
                                                            id="organization"
                                                            autoComplete
                                                            includeInputInList
                                                            renderInput={(params) => <TextField {...params} label="Organization" margin="normal" />}
                                                        />
                                                        : <div className="mt-4">
                                                            <h6 className="mt-2">Organization</h6>
                                                            <div>
                                                                {
                                                                    user.organizations
                                                                        .filter(e => (e.organizationId === user.currentOrganizationId))
                                                                        .map(e => e.organizationName).toString()
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    <FormControl className="mt-5">
                                                        <InputLabel htmlFor="select-multiple-checkbox">Roles</InputLabel>
                                                        <Select
                                                            multiple
                                                            value={this.state.roles}
                                                            disabled = {!this.state.role}
                                                            onChange={this.handleChange}
                                                            input={<Input id="select-multiple-checkbox" />}
                                                            renderValue={selected => selected.map(data => data).join(', ')}
                                                            MenuProps={MenuProps}
                                                            required
                                                        >
                                                            {this.getRole(data.roles).filter(e => {
                                                                if (this.state.orgId === 1) {
                                                                    return e.id === 6;
                                                                }
                                                                return e.id !== 6
                                                            }).map(name => (
                                                                <MenuItem key={name.id} value={name.name}>
                                                                    <Checkbox checked={this.state.roles.indexOf(name.name) > -1} />
                                                                    <ListItemText primary={name.name} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <div className="float-right mt-3">
                                                        <Button color="primary" type="submit" variant="contained" disabled={loading}>
                                                            {loading ? <CircularProgress disableShrink size={24} /> : 'Submit'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    </Query>
                                </div>
                                {Auth.hasRole(user, ['ROLE_SUPER_ADMIN'])
                                    && <div className="col-md-5">
                                        <Mutation mutation={REMOVE_USER_ORG} onCompleted={this.handleDialog}>
                                            {(removeOrganization, { loading, error, data }) => (
                                                <div>
                                                    <h6>Existing Organization</h6>
                                                    <List>
                                                        {
                                                            this.props.userData && this.props.userData.organizations.map(e => {
                                                                return (
                                                                    <ListItem>
                                                                        <ListItemText primary={e.OrganizationName} secondary={e.roles.map(i => i.name).join(',')} />
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton onClick={() => {
                                                                                removeOrganization({
                                                                                    variables: {
                                                                                        userId: this.state.userId,
                                                                                        organizationId: e.organizationId
                                                                                    }
                                                                                });
                                                                            }} edge="end" aria-label="comments">
                                                                                <CloseIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }

                                                    </List>
                                                </div>
                                            )}
                                        </Mutation>
                                    </div>
                                }
                            </div>
                        </form>
                    )
                    }
                </Mutation>
            </div >
        );
    }
}

export default ChangeRole;