import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import '../RepoAnalysis-modals/modal.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import projectServices from '../../project/services/project-service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

var projectId = '';
var projectGroupObj = [];
var projectGroupList = [];
var repoNameList = [];
var scanId = '';
var isError = false;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

var user = JSON.parse(localStorage.getItem('users'));

const GET_PROJECT_LIST = gql`
query projects($pageSize: Int, $pageNumber: Int, $sortBy: String, $direction: Direction, $filterBy: ProjectInput) {
      projects (pageNumber: $pageNumber, pageSize: $pageSize, sortBy: $sortBy, direction: $direction, filterBy: $filterBy) {
        content {
          __typename
          ... on Project {
            id
            projectName
            projectKey
            repoGroupName
            repoName
            repoUserToken
            isRepoAccessValid
            lastRepoValidCheckTime
            createdOn
            repoLanguages
            projectGroupId
            projectGroupName
            }
        }
        totalPages
        totalElements
        last
        first
        numberOfElements
        size
        number
        empty		
    }
}
`;

const DOWNLOAD_REPO_ANALYSIS = gql`
mutation repoAnalysis($repoGroupName: String!,$repoName:String!,$branchName:String!,$id: String!,$username: String!,$name: String!,$emailId: String,$commitId: String!,
$repoScanCategory: [RepoScanCategoryInfoDTOInput],$excludePattern: [String])
{  
    repoAnalysis(repoGroupName: $repoGroupName,repoName:$repoName,branchName:$branchName,id: $id,
    username: $username,name: $name,emailId: $emailId,commitId: $commitId,repoScanCategory: $repoScanCategory,excludePattern: $excludePattern) 
    {
        id
        projectGroupId
        projectGroupName
        projectId
        projectName
        repoGroupName
        repoName
        branchName
        createdOn
        modifiedOn
        requestedUser{
            name
        }
        organizationId
        status
        version
        latestCommitId
        repoScanCategory{
            value
            flag
        }
        excludePattern
    }
}

`;

const GET_PROJECT_GROUP_LIST = gql`
query projectGroups($pageSize: Int, $pageNumber: Int, $filterBy: ProjectGroupInput) {
    projectGroups(pageNumber: $pageNumber, pageSize: $pageSize, filterBy: $filterBy) {
        content {
          __typename
          ... on ProjectGroup {
            id
            projectGroupName
            repoAccessToken
            }
        }
        totalPages
    }
}
`;

const GET_BRANCH_LIST = gql`
query repoScanBranches($repoGroupName : String, $repoName: String, $branchName: String, $size : Int, $limit : Int) {
   repoScanBranches(repoGroupName:$repoGroupName, repoName:$repoName,branchName:$branchName, size:$size,limit:$limit) {
    branchName
    commitId
     }
}
`;

const GET_CATEGORY_LIST = gql`
query repoScanCategory {
	repoScanCategory {
   	value
    flag
  	}
}
`;
class AddRepository extends Component {

    constructor(props, context) {
        super(props, context);
        this.projectService = new projectServices();
        this.state = {
            step: 0,
            hasError: false,
            projectGroupId: '',
            projectName: '',
            projectKey: '',
            repoGroupName: '',
            repoName: '',
            repoUserToken: '',
            excludePattern: '',
            repoAccessToken: '',
            repoUrl: '',
            versionControl: '',
            id: null,
            disabled: false,
            submitted: false,
            isValidateToken: null,
            isLoading1: false,
            isLoading2: false,
            isLoading3: false,
            isLoading4: true,
            branchExclutionsPattern: '',
            projectGroupName: '',
            branchName: '',
            featuresList: [],
            commitId: '',
            repoScanCategory: [],

        };
        this.handleDialog = this.handleDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleProjectGroupList = this.handleProjectGroupList.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleProjectName = this.handleProjectName.bind(this);
        this.handleCategoryList = this.handleCategoryList.bind(this);
        this.flag = true;
    }

    handleCategoryList(event) {
        if (event.target.value.length < 1) {
            this.state.repoScanCategory.map((value) => {
                const list = this.state.repoScanCategory.findIndex((obj => obj.value == value.value));
                this.state.repoScanCategory[list].flag = true
            });
        } else {
            this.state.repoScanCategory.map((value) => {
                const list = this.state.repoScanCategory.findIndex((obj => obj.value == value.value));
                if (!event.target.value.includes(value.value)) {
                    this.state.repoScanCategory[list].flag = false
                } else {
                    this.state.repoScanCategory[list].flag = true
                }
            });
        }
        this.setState({
            featuresList: event.target.value
        });
    }

    handleClose() {
        this.setState({ formSubmit: false })
        this.props.onUpdate();
        this.ClearForm();
    }

    submit = async () => {
        await this.form.submit();

        setTimeout(() => {
            if (!isError) {
                this.props.filterEvent(scanId.repoAnalysis.id);
                this.props.onUpdate();
                this.ClearForm();
            } else {
                return
            }
        }, 500);

    }

    ClearForm = () => {
        this.setState({ reviewUrl: "", clearErrors: true });
    }

    handleDialog() {
        this.setState({
            step: 0,
        });
        for (var key in this.state.lastData) {
            this.setState({
                [key]: this.state.lastData[key]
            });
        }
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isKeyLength', (value) => {
            if (value.length !== 4) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isNameLength', (value) => {
            if (value.length < 4 || value.length > 100) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isOrganizationLength', (value) => {
            if (value.length < 3 || value.length > 100) {
                return false;
            }
            return true;
        });
    }

    handleProjectGroup(event) {
        this.setState({
            projectGroupName: event.target.value
        });
        this.setState({ repoName: '' });
        this.setState({ branchName: '' });
        this.setState({ featuresList: [] });
        this.flag = true
        this.setState({ excludePattern: '' });
        document.querySelector('#errorMessage').innerText = "";
    }

    handleProjectGroupList(event, projectGroupObj) {
        repoNameList = [];
        let count = 1;
        for (let i = 0; i < projectGroupObj.length; i++) {
            if (projectGroupObj[i].projectGroupName === event.target.value && projectGroupObj[i].isRepoAccessValid === true) {
                repoNameList.push(projectGroupObj[i].repoName)
                if (count == 1) {
                    this.state.projectName = projectGroupObj[i].projectName;
                    count--;
                }
            }
        }
    }

    handleProjectName(event, projectGroupObj) {
        for (let i = 0; i < projectGroupObj.length; i++) {
            if (projectGroupObj[i].repoName === event.target.value) {
                this.state.projectName = projectGroupObj[i].projectName;
            }
            if (projectGroupObj[i].repoName === event.target.value) {
                this.state.repoGroupName = projectGroupObj[i].repoGroupName;
            }
        }
    }

    handleChange(event) {
        this.setState({ repoName: event.target.value });
        this.setState({ branchName: '' });
        this.setState({ featuresList: [] });
        this.flag = true
        this.setState({ excludePattern: '' });
        document.querySelector('#errorMessage').innerText = "";
    }

    handleBranchInputChange = async (e, data, repoScanBranches) => {
        if (data) {
            this.setState({ branchName: data })
        } else {
            this.setState({ branchName: '' })
        }
        repoScanBranches.map((branch) => {
            if (data === branch.branchName) {
                this.setState({ commitId: branch.commitId })
            }
        });
        this.setState({ featuresList: [] });
        this.flag = true
        this.setState({ excludePattern: '' });
        document.querySelector('#errorMessage').innerText = "";
    }

    handleInputChange = (event) => {
        this.setState({ excludePattern: event.target.value });
    }

    renderStep = () => {
        const { step, hasError, isLoading1, isLoading2, isLoading3, isLoading4 } = this.state;
        let content = null;
        switch (step) {
            case 0:
                content = [
                    <div key={step}>
                        <div className="row mt-4">
                            <div className="col-md-6 mb3">
                                <Query query={GET_PROJECT_GROUP_LIST} fetchPolicy="no-cache" variables={{ pageSize: 1000, pageNumber: 0 }}>
                                    {({ loading, error, data }) => {
                                        if (data && data.projectGroups && data.projectGroups.content) {
                                            !isLoading1 && this.setState({ isLoading1: true })
                                            projectGroupList = data.projectGroups.content;
                                        }
                                        if (loading) {
                                            isLoading1 && this.setState({ isLoading1: false })
                                        }
                                        if (error) {
                                            var projectErrorMessage = ''
                                            if (error?.message) {
                                                projectErrorMessage = <span className="text-danger" >{error.message.split(":")[2]}</span>
                                            }
                                        }
                                        return (
                                            <FormControl className="project mb3" error={hasError}>
                                                <InputLabel>Projects</InputLabel>
                                                <Select
                                                    name="projectGroupName"
                                                    value={this.state.projectGroupName || ''}
                                                    onChange={event => { this.handleProjectGroup(event); this.handleProjectGroupList(event, projectGroupObj); }}
                                                >
                                                    {data?.projectGroups?.content?.map((name) => {
                                                        if (user.currentProjectGroupId !== 0 && name.id === user.currentProjectGroupId) {
                                                            return <MenuItem key={user.id} value={user.currentProjectGroupName}>{user.currentProjectGroupName}</MenuItem>
                                                        } else if (user.currentProjectGroupId === 0 && name.id !== user.currentProjectGroupId) {
                                                            return <MenuItem key={name.id} value={name.projectGroupName}>{name.projectGroupName}</MenuItem>
                                                        }
                                                    }
                                                    )}
                                                </Select>
                                                {hasError && <FormHelperText>This is required!</FormHelperText>}
                                                {data?.projectGroups?.content?.length === 0 && <FormHelperText className="Mui-error">No Record Found!</FormHelperText>}
                                                {error && projectErrorMessage ? <span className="text-danger" >{projectErrorMessage}</span> : null}
                                            </FormControl>
                                        );
                                    }}
                                </Query>
                            </div>
                            <div className="col-md-6 mb3">
                                <Query query={GET_PROJECT_LIST} fetchPolicy="no-cache"
                                    variables={{
                                        pageSize: 1000, pageNumber: 0,
                                        sortBy: "projectName",
                                        direction: "ASC"
                                    }}>
                                    {({ loading, error, data }) => {
                                        if (data && data.projects && data.projects.content) {
                                            !isLoading2 && this.setState({ isLoading2: true })
                                            projectGroupObj = data.projects.content;
                                        }
                                        if (loading) {
                                            isLoading2 && this.setState({ isLoading2: false })
                                        }
                                        if (error) {
                                            var repositoryErrorMessage = ''
                                            if (error?.message) {
                                                repositoryErrorMessage = <span className="text-danger" >{error.message.split(":")[2]}</span>
                                            }
                                        }
                                        return (
                                            <FormControl className="project mb3" error={hasError}>
                                                <InputLabel>Repository</InputLabel>
                                                <Select
                                                    name="repoName"
                                                    value={this.state.repoName || ''}
                                                    disabled={!this.state.projectGroupName}
                                                    onChange={event => { this.handleChange(event); this.handleProjectName(event, projectGroupObj) }}
                                                >
                                                    {repoNameList.map((name, index) => (
                                                        <MenuItem key={index} value={name}>{name}</MenuItem>
                                                    ))}
                                                </Select>
                                                {hasError && <FormHelperText>This is required!</FormHelperText>}
                                                {this.state.projectGroupName ? repoNameList.length === 0 && <FormHelperText className="Mui-error">No Record Found!</FormHelperText> : ""}
                                                {error && repositoryErrorMessage ? <span className="text-danger" >{repositoryErrorMessage}</span> : null}
                                            </FormControl>
                                        );
                                    }}
                                </Query>
                            </div>
                            <div className="col-md-6 mb3 mt-4">
                                <Query query={GET_BRANCH_LIST} fetchPolicy="no-cache" variables={{
                                    repoGroupName: this.state.repoGroupName,
                                    repoName: this.state.repoName,
                                    branchName: "",
                                    size: 0,
                                    limit: 20
                                }}>
                                    {({ loading, error, data, refetch }) => {
                                        if (!loading) {
                                            !isLoading3 && this.setState({ isLoading3: true })
                                        }
                                        if (loading) {
                                            isLoading3 && this.setState({ isLoading3: false })
                                        }
                                        if (error) {
                                            var branchErrorMessage = ''
                                            if (error?.message) {
                                                branchErrorMessage = <span className="text-danger" >{error.message.split(":")[2]}</span>
                                            }
                                        }
                                        return (
                                            <>
                                                <Autocomplete
                                                    options={data?.repoScanBranches}
                                                    getOptionLabel={(option) => (option ? option.branchName : "")}
                                                    inputValue={this.state.branchName}
                                                    onInputChange={async (event, newInputValue) => { await this.handleBranchInputChange(event, newInputValue, data.repoScanBranches); await refetch({ branchName: this.state.branchName }) }}
                                                    id="branchName"
                                                    name="branchName"
                                                    disabled={!this.state.repoName || isLoading3 === false}
                                                    renderInput={params =>
                                                        <TextField {...params}
                                                            name="branchName"
                                                            label="Branches"
                                                            margin="normal"
                                                        />
                                                    }
                                                />
                                                {error && branchErrorMessage ? <span className="text-danger" >{branchErrorMessage}</span> : null}
                                            </>
                                        );
                                    }}
                                </Query>
                            </div>
                        </div>
                        {isLoading1 === false && <div className="circular-loading"><CircularProgress size={30} /></div>}
                        {isLoading2 === false && isLoading1 === true && isLoading3 === true && isLoading4 === true ? <div className="circular-loading"><CircularProgress size={30} /></div> : null}
                        {isLoading3 === false && isLoading1 === true && isLoading2 === true && isLoading4 === true && <div className="circular-loading"><CircularProgress size={30} /></div>}
                        {isLoading4 === false && <div className="circular-loading"><CircularProgress size={30} /></div>}
                        {this.state.branchName && <div>
                            <div className="row mt-5">
                                <div className="col-md-6 mb3">
                                    <h5>Options</h5>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6 mb3">
                                    {this.state.branchName && <Query query={GET_CATEGORY_LIST}>
                                        {({ loading, error, data, refetch }) => {
                                            if (loading) {
                                                isLoading4 && this.setState({ isLoading4: false })
                                            }
                                            if (loading) return null;
                                            if (error) {
                                                var errorMessage = ''
                                                if (error?.message) {
                                                    errorMessage = <span className="text-danger" >{error.message.split(":")[2]}</span>
                                                }
                                            }
                                            if (data.repoScanCategory && this.flag) {
                                                !isLoading4 && this.setState({ isLoading4: true })
                                                this.state.repoScanCategory = [];
                                                data.repoScanCategory.map((data) => {
                                                    data.flag = true
                                                    this.state.repoScanCategory.push(data)
                                                })
                                                this.flag = false;
                                            }
                                            return (
                                                <FormControl error={hasError}>
                                                    <InputLabel htmlFor="select-multiple-checkbox">Features</InputLabel>
                                                    <Select
                                                        multiple
                                                        value={this.state.featuresList.length ? this.state.featuresList : data?.repoScanCategory?.map(e => e.value)}
                                                        // onChange={this.handleCategoryList}
                                                        input={<Input id="select-multiple-checkbox" />}
                                                        renderValue={selected => selected.join(', ')}
                                                    >
                                                        {data.repoScanCategory
                                                            .map(category => {
                                                                return (
                                                                    <MenuItem key={category.value} value={category.value} disabled={true}>
                                                                        <ListItemText primary={category.value} />
                                                                        {/* <Checkbox checked={this.state.featuresList.length ? this.state.featuresList.includes(category.value) : category.flag} /> */}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Select>
                                                    {error ? <span className="text-danger" >{errorMessage}</span> : null}
                                                </FormControl>
                                            )
                                        }}
                                    </Query>}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <TextValidator type="text"
                                        name="excludePattern"
                                        label="Excluding File Path"
                                        helperText={"Example: '.js,*.min.js,projectName/src/main/webapp/locale/' -- please use comma ' , '"}
                                        value={this.state.excludePattern}
                                        onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>}
                    </div>
                ];
                break;
            default: return content = [];
        }
        return content;
    }

    render() {
        return (
            <Mutation mutation={DOWNLOAD_REPO_ANALYSIS} onCompleted={() => this.handleDialog()}>
                {(downloadRepoAnalysis, { loading, error, data }) => {
                    if (error) {
                        isError = true;
                        var errorMessage = "";
                        if (error.message && error.message.includes('The branch has been scanned already. Looks like there are no new changes in the review')) {
                            errorMessage = <span className="text-danger" >{error.message.split(":")[2]}:<a className="text-primary" href={"/fullscans-dashboard/" + error.message.split(":")[3].trim()} title='Click here to view your review'> {error.message.split(":")[3]} </a></span>
                        } else if (error.message && error.message.includes('RepoScan request is InProgress with id')) {
                            errorMessage = <span className="text-danger" >{error.message.split(":")[2]}:<a className="text-primary" href={"/fullscans-dashboard/" + error.message.split(":")[3].trim()} title='Click here to view your review'> {error.message.split(":")[3]} </a></span>
                        } else if (error.message && error.message.includes('RepoScan requested has been deleted/Not available')) {
                            errorMessage = <span className="text-danger" >{error.message.split(":")[2]}</span>
                        } else if (error.message && error.message.includes('RepoScan request is already created with id')) {
                            errorMessage = <span className="text-danger" >{error.message.split(":")[2]}:{error.message.split(":")[3]}</span>
                        } else if (error.message) {
                            errorMessage = <span className="text-danger" >{error.message.split(":")[2]}</span>
                        } else {
                            errorMessage = "Something went wrong"
                        }
                    }
                    if (data) {
                        isError = false;
                        scanId = data
                    }
                    return (
                        <ValidatorForm id="projectAdd"
                            ref={(r) => { this.form = r; }}
                            onSubmit={e => {
                                if (!this.state.id) {
                                    delete this.state.id;
                                }
                                this.setState({ submitted: true })
                                const { repoGroupName, repoName, branchName, commitId, repoScanCategory } = this.state;
                                const { id, username, name, emailId } = user
                                const excludePattern = this.state.excludePattern ? this.state.excludePattern.split(',') : [];
                                downloadRepoAnalysis({
                                    variables:
                                        { repoGroupName, repoName, branchName, commitId, id, username, name, emailId, repoScanCategory, excludePattern }
                                });
                            }}
                            instantValidate
                        >
                            <Dialog maxWidth={"md"} fullWidth={true}
                                fullScreen={window.innerWidth < 600}
                                open={this.props.showModal}>
                                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>Full Scan Analysis</DialogTitle >
                                {loading ? <LinearProgress color="primary" /> : <Divider />}
                                <DialogContent className="project-edit-container">
                                    {this.renderStep()}
                                    {!loading && <div id="errorMessage" className='alertMessage' style={{ marginTop: '6%' }}>
                                        {error ? <span className="text-danger" >{errorMessage}</span> : null}
                                    </div>}
                                </DialogContent>
                                <Divider />
                                <DialogActions>
                                    <Button onClick={this.handleClose} color="primary" autoFocus>
                                        close
                                    </Button>
                                    <Button color="primary" type="submit" variant="contained" onClick={this.submit} disabled={!this.state.branchName}>
                                        Submit
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </ValidatorForm>
                    )
                }}
            </Mutation>
        )
    }
}

export default AddRepository;
