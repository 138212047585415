import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class Copy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            copySucess: false
        }
    }

    render() {
        return (
            <Tooltip title={this.state.copySucess ? "Copied" : "Copy Commit Id"} onClose={() => { this.setState({ copySucess: false }) }}>
                <span
                    className="icon-font-size"
                    onClick={() => navigator.clipboard.writeText((this.props.data)) && this.setState({ copySucess: true })}
                >
                    <i className="material-icons align-middle">file_copy</i>
                </span>
            </Tooltip>
        )
    }
}

export default Copy