import React, { Component } from 'react';
import LanguageBarRepo from './../RepoAnalysis/languagebarModel/languagebarmodel-repo'
function RepoAnalysisLanguageBar(props){
    return(
        <div className="w-80">
            <LanguageBarRepo readings={props.data.languagePercentage} />
        </div>
    )
}

export default RepoAnalysisLanguageBar;