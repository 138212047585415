import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BugReportIcon from '@material-ui/icons/BugReport';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "darkslategrey",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        marginBottom: 20,
        fontSize: "0.875rem"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 600
    },
    depTable: {
        marginBottom: 15,
        marginTop: 15,
        border: "1px solid lightgray"
    },
    headCell: {
        backgroundColor: "darkslategrey",
        color: "#fff"
    },
    depTitle: {
        fontWeight: 600,
        paddingBottom: 4
    },
    depSubTitle: {
        paddingTop: 4
    },
    severityHighlights: {
        backgroundColor: "yellow",
        padding: "3px 8px",
        fontWeight: 600
    }
}));

function PublishedVulnerability(props) {
    const vulnerabilities = props.data;
    const classes = useStyles();
    return (
        <div>
            {
                vulnerabilities?.map((data, index) => (
                    <div>
                        { 
                            data?.name 
                            &&
                            <Typography variant="h6" className={classes.depTitle}><BugReportIcon/>{ data.name } </Typography>
                        }
                        {
                            data?.description && data?.name
                            &&
                            <Typography variant="body1">{ data.description } </Typography>
                        }
                        {
                            data?.cwes && data?.name
                            &&
                            <Typography variant="body1" className={classes.depSubTitle}>{ data.cwes } </Typography>
                        }
                        
                        {
                            data?.name
                            &&
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TableContainer>
                                        <Table className={classes.depTable} aria-label="customized table">
                                            <TableBody>
                                                <StyledTableRow>
                                                    <StyledTableCell className={classes.headCell} colSpan={2} >CVSSV2</StyledTableCell>
                                                </StyledTableRow>
                                                {
                                                    data?.source
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>NIST</StyledTableCell>
                                                        <StyledTableCell>{ data.source }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv2?.score
                                                    && 
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Base Score</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv2.score } - <span className={classes.severityHighlights}>{ data.cvssv2.severity }</span></StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv2?.accessVector
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Access Vector</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv2.accessVector }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv2?.accessComplexity
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Access Complexity</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv2.accessComplexity }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv2?.authenticationr
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Authentication</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv2.authenticationr }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv2?.confidentialImpact
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Confidential Impact</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv2.confidentialImpact }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv2?.integrityImpact
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Integrity Impact</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv2.integrityImpact }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv2?.availabilityImpact
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Availability Impact</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv2.availabilityImpact }</StyledTableCell>
                                                    </StyledTableRow>
                                                }                                         
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={6}>
                                    <TableContainer>
                                        <Table className={classes.depTable} aria-label="customized table">
                                            <TableBody>
                                                <StyledTableRow>
                                                    <StyledTableCell className={classes.headCell} colSpan={2} >CVSSV3</StyledTableCell>
                                                </StyledTableRow>
                                                {
                                                    data?.source
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>NIST</StyledTableCell>
                                                        <StyledTableCell>{ data.source }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv3?.baseScore
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Base Score</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv3.baseScore } - <span className={classes.severityHighlights}>{ data.cvssv3.baseSeverity }</span></StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv3?.attackVector
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Attack Vector</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv3.attackVector }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv3?.attackComplexity
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Attack Complexity</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv3.attackComplexity }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv3?.privilegesRequired
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Privileges Required</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv3.privilegesRequired }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv3?.userInteraction
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>User Interaction</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv3.userInteraction }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv3?.scope
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Scope</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv3.scope }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv3?.confidentialityImpact
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Confidentiality Impact</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv3.confidentialityImpact }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv3?.integrityImpact
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Integrity Impact</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv3.integrityImpact }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                                {
                                                    data?.cvssv3?.availabilityImpact
                                                    &&
                                                    <StyledTableRow>
                                                        <StyledTableCell className={classes.headCell}>Availability Impact</StyledTableCell>
                                                        <StyledTableCell>{ data.cvssv3.availabilityImpact }</StyledTableCell>
                                                    </StyledTableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        }
                    </div>    
                ))
            }
            <Typography></Typography>
        </div>
    )
}

export default PublishedVulnerability