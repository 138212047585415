import React, { Component } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import ProjectAdd from './add-project';
import ProjectEdit from './edit-project';
import ProjectFileView from './view-file';
import DeleteProject from './delete-project';
import RuleSetModal from './modals/ruleset-modal';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable from 'material-table';
import projectServices from './services/project-service';
import Auth from '../../core/service/auth';
import { UTCtoLocalTime } from '../../core/service/timezone';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';


var style = {
    border: 'none',
}

var user = JSON.parse(localStorage.getItem('users'));
class ProjectList extends Component {
    projectId;
    constructor(props) {
        super(props)
        this.projectServices = new projectServices();
        this.tableRef = React.createRef();
        this.state = {
            showModal: false,
            showEditModal: false,
            showViewModal: false,
            showRuleSetModal: false,
            showDeleteModal:false,
            isLoading: false,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                isDesktop: window.innerWidth > 600,
            },
            columnDefs: [{
                title: "Project Group Name", field: "projectGroupName", filtering: true, filterCellStyle: style, headerStyle: style, filterPlaceholder: 'search', sort: "asc", render: function (params) {
                    return (
                        <div>
                            <Tooltip title={"Last Repo Connectivity Check On " + UTCtoLocalTime(params.lastRepoValidCheckTime, 'YYYY-MMM-DD HH:mm A')} placement="top" >
                                <IconButton className="success" type="submit" aria-label="Token True">
                                    {params.isRepoAccessValid ? <CheckCircleOutlineIcon /> : <InfoIcon color="secondary" />}
                                </IconButton>
                            </Tooltip>
                            {params.projectGroupName}
                        </div>
                    )
                }
            }, {
                title: "Repo Group Name", field: "repoGroupName", filtering: true, filterCellStyle: style, headerStyle: style, filterPlaceholder: 'search'
            }, {
                title: "Repo Name", field: "repoName", filtering: true, filterCellStyle: style, headerStyle: style, filterPlaceholder: 'search'
            }, {
                title: "Created On", field: "createdOn", filtering: false, filterCellStyle: style, headerStyle: style, render: function (params) {
                    return UTCtoLocalTime(params.createdOn, 'YYYY-MMM-DD HH:mm A');
                }
            }]
        }
        this.addProjectDialog = this.addProjectDialog.bind(this);
        this.handleEditDialog = this.handleEditDialog.bind(this);
        this.handleFileDialog = this.handleFileDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.getData = this.getData.bind(this);
        this.handleDeleteDialog = this.handleDeleteDialog.bind(this);
        this.handleCloseDelete = this.handleCloseDelete.bind(this);
    }



    onGridReady(params) {
        if ((window.innerWidth > 600)) {
            params.api.sizeColumnsToFit();
        }
    }

    getData(query) {
        const arrayToObject = (filter) =>
            Object.assign({}, ...filter.map(item => ({ [item.column.field]: item.column.tableData.filterValue })))
        const filter = arrayToObject(query.filters);
        return this.projectServices.getProjectList(query, filter);
    }

    addProjectDialog() {
        //this.projectAddPage.refetch();
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    handleEditDialog(grid, isRule) {
        if (grid) {
            this.project = grid;
        }
        if (!grid) {
            this.setState({ showEditModal: false });
            this.setState({ showRuleSetModal: false });
        }
        if (grid && !isRule) {
            //this.projectAddPage.refetch();
            this.setState({ showEditModal: true });
        } else if (grid && isRule) {
            this.setState({ showRuleSetModal: true });
        }
    }

    handleFileDialog(grid) {
        if (grid) {
            this.project = grid;
            this.setState({ showViewModal: true });
        }
    }

    handleDeleteDialog(grid) {
        if (grid) {
            this.project = grid;
            this.setState({ showDeleteModal: true });
        }
    }

    handleClose() {
        this.setState({ showViewModal: false });
    }

    handleCloseDelete() {
        this.setState({ showDeleteModal: false });
    }

    render() {
        return (
            <div className="project-list post-list">
                <div
                    className="ag-theme-material pt-1 pl-0 pr-0 pb-30"
                >
                    <div className="row">
                        <div className="project-list-container">
                            <Button className="addbutton btn-link text-primary bg-white border-0 shadow-none" onClick={() => this.tableRef.current && this.tableRef.current.onQueryChange()}>
                                <Tooltip title="Refresh" placement="top">
                                    <i className="material-icons text-primary">refresh</i>
                                </Tooltip>
                            </Button>
                             
                            <Button className="addbutton btn-link text-primary bg-white border-0" onClick={this.addProjectDialog}>
                                <Tooltip title="Add Repository" placement="top">
                                    <i className="material-icons align-middle">add_circle</i>
                                </Tooltip>
                            </Button>
                            
                        </div>
                    </div>
                    <MaterialTable
                        title="Reviews"
                        tableRef={this.tableRef}
                        columns={this.state.columnDefs}
                        data={query => this.getData(query)}
                        options={{
                            filtering: true,
                            toolbar: false,
                            headerStyle: {
                                padding: '5px',
                                fontSize: '12px',
                                fontWeight: "bold",
                                color: 'black',
                                border: 'none',
                            },
                            pageSize: 7,
                            actionsColumnIndex: -1,
                            pageSizeOptions: [7, 10, 20, 30],
                        }}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit Project',
                                onClick: (event, rowData) => { this.handleEditDialog(rowData) },
                            },
                            {
                                icon: 'vertical_split',
                                tooltip: 'Rule Configuration',
                                onClick: (event, rowData) => this.handleEditDialog(rowData, true)
                            },
                            (Auth.hasRole(user, ['ROLE_ADMIN'])) && {
                                icon: 'insert_drive_file',
                                tooltip: 'View Xml File',
                                onClick: (event, rowData) => this.handleFileDialog(rowData)
                            },
                            (Auth.hasRole(user, ['ROLE_ADMIN'])) && {
                                icon: 'delete',
                                tooltip: 'Delete Repository',
                                onClick: (event, rowData) => this.handleDeleteDialog(rowData)
                            },
                        ]}
                        components={{
                            Action: props => {
                                return (
                                    <button className={'btn btn-link p-0 ' + (props.action.icon !== 'edit' ? props.data.repoLanguages && props.data.repoLanguages.length ? "" : "text-danger" : "")}
                                        onClick={(event) => props.action.onClick(event, props.data)}>
                                        <Tooltip title={props.action.tooltip}>
                                            <i className="material-icons align-middle pr-4">{props.action.icon}</i>
                                        </Tooltip>
                                    </button>
                                )
                            },
                        }} />
                    {this.state.showModal && <ProjectAdd showModal={this.state.showModal} onUpdate={this.addProjectDialog.bind(this)} refresh={() => this.tableRef.current && this.tableRef.current.onQueryChange()} history={this.props.history}></ProjectAdd>}
                    {this.state.showEditModal && <ProjectEdit projectId={this.project ? this.project.id : null} showModal={this.state.showEditModal} onUpdate={this.handleEditDialog} refresh={() => this.tableRef.current && this.tableRef.current.onQueryChange()} history={this.props.history}></ProjectEdit>}
                    {this.state.showRuleSetModal && <RuleSetModal subtitle={this.project.repoGroupName + ' / ' + this.project.repoName} showModal={this.state.showRuleSetModal} onUpdate={this.handleEditDialog} project={this.project} refresh={() => this.tableRef.current && this.tableRef.current.onQueryChange()} />}
                    {this.state.showViewModal && <ProjectFileView projectId={this.project ? this.project.id : null} showViewModal={this.state.showViewModal} onClose={this.handleClose} onUpdate={this.handleFileDialog} refresh={() => this.tableRef.current && this.tableRef.current.onQueryChange()}></ProjectFileView>}
                    {this.state.showDeleteModal && <DeleteProject projectId={this.project ? this.project.id : null} repoName={this.project ? this.project.repoName : null} showDeleteModal={this.state.showDeleteModal} onClose={this.handleCloseDelete} refresh={() => this.tableRef.current && this.tableRef.current.onQueryChange()}></DeleteProject>} 
                </div>
            </div>

        );
    }
}

export default ProjectList;