import React, { Component } from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { avatarImage } from '../../components/service/avatar-image';
import './modal.css';
import MaterialTable from 'material-table';
import Copy from './Copy';
import Grid from '@material-ui/core/Grid';


class ReviewCommits extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            columnDefs: [
                {
                    title: "Committer", field: "committer", render: function (params) {
                        const avatarImg = avatarImage.getImageLocation(params.committer && params.committer.name.length);
                        var name = params.committer && params.committer.name;
                        var Img = <span><img src={avatarImg} alt="Avatar" height="40px" className="pr-3" />{name}</span>
                        return <span title={params.committer.name}>{Img}</span>
                    }
                }, {
                    title: "Commit Id", field: "commitId", render: function (params) {
                        return <>
                            <Grid container>
                                <Grid item xs={10} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                    <span title={params.id}>{params.id}</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Copy data={params?.id} />
                                </Grid>
                            </Grid>
                        </>
                    }
                }, {
                    title: "Message", field: "message", render: function (params) {
                        return <span title={params.message}>{params.message}</span>
                    }
                }, {
                    title: "Commit Date", field: "commitDate", render: function (params) {
                        return <span title={moment(params.committerTimestamp).format('YYYY-MMM-DD HH:mm A')}>{moment(params.committerTimestamp).format('YYYY-MMM-DD HH:mm A')}</span>;
                    }, sort: 'desc'
                }, {
                    title: "Issues", field: "issues", render: function (params) {
                        var jiraKey = params.properties && params.properties.jiraKey ? params.properties && params.properties.jiraKey.map(data => data).join(', ') : '';
                        return <span title= {jiraKey}>{jiraKey}</span>
                    }
                }
            ]
        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.onUpdate();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.showModal}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth={"lg"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle>{'Commits'}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <MaterialTable
                            key={"review Commits"}
                            title="Review Commits"
                            tableRef={this.tableRef}
                            columns={this.state.columnDefs}
                            data={this.props.commits || []}
                            options={{
                                filtering: false,
                                toolbar: false,
                                sorting: true,
                                headerStyle: {
                                    padding: '5px',
                                    fontSize: '12px',
                                    fontWeight: "bold",
                                    color: 'black',
                                },
                            }}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary" autoFocus>
                            close
                        </Button>
                    </DialogActions>

                </Dialog>

            </div>
        );
    }
}

export default ReviewCommits;
