import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import SendIcon from '@material-ui/icons/Send';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StatusWatcher from './reviewStatus/StatusWatcher';
import '../modals/modal.css';

var reviewId = '';

const ADD_REVIEW_URL = gql`
    mutation review(
        $reviewUrl: String!,
        $userId: String!,
        $fullName: String!,
        $userName: String!,
        $emailId: String) {
            review(
                reviewUrl: $reviewUrl,
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId,
            ) {
                id
                reviewRefId
                reviewUrl
                projectName
                repoGroupName
                sourceBranchName
                destinationBranchName
                pullRequestId
                commitId
                status
        }
    }
`;

class AddReviewUrl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openSn: false,
			showRequest: null,
			formSubmit: false,
		}
		this.handleClose = this.handleClose.bind(this);
		this.handleRequestClose = this.handleRequestClose.bind(this);
		this.handleRequestClick = this.handleRequestClick.bind(this);
		this.copyText = this.copyText.bind(this);
		this.closeSnackbar = this.closeSnackbar.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}



	handleClose() {
		this.setState({ formSubmit: false })
		this.props.onUpdate();
		this.ClearForm();
		this.props.handleReviewId(reviewId)
	}

	handleRequestClose() {
		this.setState({ showRequest: null });
	}

	handleRequestClick(event) {
		this.setState({ showRequest: event.currentTarget });
	}

	ClearForm = () => {
		this.setState({ reviewUrl: "", clearErrors: true });
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	closeSnackbar() {
		this.setState({
			openSn: false
		})
	}

	copyText(event) {
		var copyText = event.target.innerHTML;
		var textField = document.createElement('textarea');
		textField.innerText = copyText;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
		this.setState({ openSn: true })
	}

	render() {
		return (
			<div>
				<Mutation fetchPolicy="no-cache" mutation={ADD_REVIEW_URL} onCompleted={() => { this.setState({ formSubmit: true }); }}>
					{(review, { loading, error, data }) => {
						if (error && !this.state.clearErrors) {
							var errorMessage = '';
							if (error && error.message && error.message.includes('401')) {
								errorMessage = "The user configured for the project repository doesn't have sufficient privilege to read the code. Please contact the administrator."
							} else if (error && error.message && error.message.includes('UnknownHostException')) {
								errorMessage = "VPN is not configured in the server. Please contact the administrator."
							} else {
								if (error.message && error.message.includes("Review ID") && error.message.split(":").length >= 4) {
									errorMessage = <spna>{error.message.split(":")[2]} <a className="text-primary" href={'/review-details/' + error.message.split(":")[3].trim()} title="click to view your Review">{ error.message.split(":")[3]}</a></spna> 
								} else {
									errorMessage = error.message && error.message.split(":")[2];
								}
							}
						}
						if (data) {
							reviewId = data.review.id
						}
						return (
							<Dialog
								fullScreen={!useMediaQuery('(min-width:600px)')}
								open={this.props.showModal}
								onClose={this.handleClose}
								fullWidth={true}
								maxWidth={"md"}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description">
								<DialogTitle>Review Request</DialogTitle>
								{loading ? <LinearProgress color="primary" /> : <Divider />}
								<DialogContent>
									<div className="container">
										<div>
											<div className="review-url">
												<ValidatorForm onSubmit={(e) => {
													if (!loading) {
														e.preventDefault();
														this.setState({ clearErrors: false });
														var users = JSON.parse(localStorage.getItem('users'));
														review({
															variables: {
																reviewUrl: this.state.reviewUrl.replace(/ /g, ''),
																userId: users.id,
																fullName: users.name,
																userName: users.username,
																emailId: users.emailId,
															}
														});
													}
												}}>
													<TextValidator
														id="standard-name"
														label="Request URL"
														name="reviewUrl"
														autoComplete="off"
														value={this.state.reviewUrl || ''}
														onChange={this.handleChange}
														margin="normal"
														helperText="Paste Diff URL or Pull Request URL or Commit URL"
														validators={['required']}
														errorMessages={['This field is Required']}
														InputProps={{
															endAdornment: <InputAdornment position="end">
																{this.state.reviewUrl ? <IconButton type="submit" aria-label="review Url">
																	<SendIcon />
																</IconButton> :
																	<IconButton color="primary" type="button" onClick={this.handleRequestClick} aria-label="review Url">
																		<InfoIcon />
																	</IconButton>}
															</InputAdornment>,
														}}
													/>
													{error ? <span className="text-danger" >{errorMessage}</span> : null}
													{error ? <span className="text-danger">{(error.message.length === 85) && error.message.split(":")[1]}</span> : null}
												</ValidatorForm>
											</div>
											{!this.state.formSubmit
												?
												<div className="text-center">
													<img src="/images/waiting.png" alt="waiting" className="waiting-img" width="350px" />
													<h6>Code is like humor. When you have to explain it, it’s bad.</h6>
												</div>
												:
												<StatusWatcher id={data && data.review && data.review.id} onConnect={(client) => { client.onMessageSend() }} disconnected={() => { }} data={data} />
												// <SuccessReviewStatus data={data} />
											}
										</div>
									</div>

								</DialogContent>
								<Divider />
								<DialogActions>
									<Button onClick={this.handleClose} color="primary" autoFocus>
										close
                                    </Button>
								</DialogActions>
							</Dialog>
						)
					}}
				</Mutation>
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open={this.state.openSn}
					autoHideDuration={1000}
					onClose={this.closeSnackbar}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">Copied!</span>}
					action={[
						<Button key="undo" color="secondary" size="small" onClick={this.closeSnackbar}>
							Ok
                    </Button>,
					]}
				/>
				<Popover
					open={Boolean(this.state.showRequest)}
					anchorEl={this.state.showRequest}
					className="review-url-popup"
					onClose={this.handleRequestClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}>
					<h5 className="ml-3 mt-3 mb-3">Review URL Formats</h5>
					<Divider />
					<div className="ml-3 mt-3 mr-3 text-bold">Compare / Diff URL</div>
					<div className="ml-3 mt-3 mr-3">{"https://stash.ghx.com:7893/projects/<projectName>/repos/<repoName>/"}</div>
					<div className="ml-3 mr-3">{"compare/diff?sourceBranch=<sourceBranchName>&targetBranch=<targetBranchName>"}</div>
					<div className="ml-3 mt-3 mr-3 text-bold">Pull Request URL</div>
					<div className="ml-3 mt-3 mr-3">{"https://stash.ghx.com:7893/projects/<projectName>/repos/<repoName>/"}</div>
					<div className="ml-3 mr-3">{"pull-requests/<pullRequestId>/overview"}</div>
					<div className="ml-3 mt-3 mr-3 text-bold">Commit URL</div>
					<div className="ml-3 mt-3 mr-3">{"https://stash.ghx.com:7893/projects/<projectName>/repos/<repoName>/"}</div>
					<div className="ml-3 mb-3 mr-3">{"commits/<commitId></commitId>"}</div>
				</Popover>
			</div>
		);
	}
}

export default AddReviewUrl;