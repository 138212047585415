import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import { colorByPercentageDuplication, numberToK, qualityGateColor } from '../helper/helper';
import RepoAnalysisStashModal from '../RepoAnalysisTreeView/RepoAnalysisStashModal';
import HelpIcon from '@material-ui/icons/Help';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0),
        borderRadius: 10
    },
    card: {
        maxWidth: 400,
        minHeight: 200,
        margin: `${theme.spacing(0)}px auto`,
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    title: {
        fontWeight: 'bold',
        marginTop: 10,
        fontSize: 23,
    },
    totalcount: {
        fontSize: 23,
        fontWeight: 'bold',

    },
    comment: {
        color: '#0076FE',
        fontSize: '13.5px'
    },
    description: {
        minHeight: 80,
        minWidth: 80,
        fontSize: '12px'
    },
    tableData : {
        borderCollapse: 'separate',
        width:'100% !important'
    },
    headTableData: {
        borderCollapse: 'separate',
        width: '100% !important',
        margin: '0px'
    }
}));

const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            h5: {
                fontSize: [22, "!important"]
            }
        }
    }
});

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: '#797979',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const category = {
    qualityGate: 'Warning'
}

function RepoAnalysisReportDuplications(props) {
    const classes = useStyles();
    const [treeModal, showTreeModal] = React.useState(false);
    const [type, setType] = React.useState('');

    const handleTreeViewClose = () => {
        showTreeModal(false);
    };

    const handleDuplicationOpen = () => {
        props.hideDashboardChildComponent(true)
    }

    return (
        <Card elevation={0} className={classes.root}>
            <CardActionArea
                style={{ borderBottom: props.data.status === 'Completed' && '2px solid ' + qualityGateColor(props.data.duplicationQualityGate)}}
                className={classes.card}
                disabled={props.data.status === "Completed" ? false : true}
                onClick={() => {
                    { props.data.duplications !== 0 && handleDuplicationOpen(); }
                }}>
                <table className={classes.headTableData}>
                    <tr>
                        <td style={{ width: '5%' }}>
                            <i className="material-icons reportIcon" style={{ color: '#3677FE' }}>control_point_duplicate</i>
                        </td>
                        <td style={{ width: '90%', paddingBottom: '7px' }}>
                            <ThemeProvider theme={theme}>
                                <Typography className={classes.title} variant="h5">
                                    {props.data.category}
                                </Typography>
                            </ThemeProvider>
                        </td>
                        <td style={{ width: '5%' }} className='mt-2 pt-2'>
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">Duplication</Typography>
                                        {
                                            props.data.qualityGateStatusRange.map((statusRange, index) => (
                                                <>
                                                    {
                                                        <Typography>
                                                            <i className="material-icons align-middle" style={{ fontSize: "bold", color: qualityGateColor(statusRange.qualityGateStatus) }}>horizontal_rule</i>
                                                            <span> : {statusRange.qualityGateRange}</span>
                                                        </Typography>
                                                    }
                                                </>
                                            ))
                                        }
                                    </React.Fragment>
                                }
                            >
                                <HelpIcon style={{ color: '#0076fe', marginBottom: '25px' }} />
                            </HtmlTooltip>
                        </td>
                    </tr>
                </table>
                <Grid container spacing={2} className={classes.description}>
                    <Grid item>
                        <Typography color="textSecondary" component="p">
                            {props.data.description}
                        </Typography>
                    </Grid>
                </Grid>
                {
                    props.data.status != "Failed"
                    ?
                    <table className={classes.tableData}>
                        <tr>
                            <td>
                                <Typography className={classes.totalcount}>
                                    <b><Tooltip placement="top" id="placement" title={props.data.duplications}>
                                        <span>{ numberToK(props.data.duplications) }</span>
                                    </Tooltip>
                                    </b>
                                </Typography>
                                <Typography className={classes.comment}>
                                    Duplications
                                </Typography>
                            </td>
                            <td>
                                <Typography className={classes.totalcount}>
                                    { props.data.duplicationPercentage + '%' }
                                </Typography>
                                <Typography className={classes.comment} style={{ color: qualityGateColor(props.data.duplicationQualityGate) }}>
                                    Percentage
                                </Typography>
                            </td>
                        </tr>
                    </table> :
                    props.data.status === "Failed" 
                    ?
                    <Grid container spacing={4} className={classes.description} style={{ height: '10px' }}>
                        <Grid item>
                            <Typography color="textSecondary" style={{ color: 'red' }} component="p">
                                At the moment we couldn't process this report, Please try again later.
                            </Typography>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={4} className={classes.description}>
                        <Grid item className='pl-5 ml-4 pr-5 mt-2'>
                            <Typography color="textSecondary" component="p">
                                Category is not chosen
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </CardActionArea>
            {(props.data.status === "New" || props.data.status === "In Progress") && <LinearProgress />}
            <RepoAnalysisStashModal showModal={treeModal} type={type} scanId={props.scanId} onClose={handleTreeViewClose} />
        </Card>
    )
}

export default RepoAnalysisReportDuplications;