import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorType from '../../../core/error/simpleError';
import RepoAnalysisDiffView from './RepoAnalysisDiffView';

const GET_DIFF_BY_FILE = gql`
query sourceSegmentByReposcanRequestId($repoScanRequestId: String!, $filePath: String!, $type: String!, $commentsCount: Long!, $severityName: String) {
    sourceSegmentByReposcanRequestId(repoScanRequestId: $repoScanRequestId, filePath: $filePath, commentsCount: $commentsCount) {
     fromHash
     toHash
     status
     contextLines
     whitespace
     truncated
     diffs {
       source {
         toString
         components
         parent
         name
         extension
       }
       destination {
         toString
         components
         parent
         name
         extension
       }
       hunks {
         context
         sourceLine
         sourceSpan
         destinationLine
         destinationSpan
         start
         size
         isLastPage
         limit
         nextPageStart
         segments {
           type
           lines {
             source
             destination
             line
             truncated
           }
           truncated
         }
         truncated
       }
       truncated
     }
 
     }
     repoScanCommentsByFilePath(repoScanRequestId: $repoScanRequestId, type: $type, filePath: $filePath, commentsCount: $commentsCount, severityName: $severityName) {
        description
        filePath        
        comments{
            description
            commentId
            status
            location{
                path
                lines{
                    begin
                    end
                }
            }
            severity
            remediationPoints
            manualComment
            remoteCommentId
            repoManualComment
            createdOn
            reviewFailed
            checkName
        }
        severity
    }
 }
`;

class RepoAnalysisFileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSn: false,
            sev: null
        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.onUpdate();
        this.props.onRefresh();
    }

    componentWillReceiveProps() {
        this.setState({ sev: null})
    }

    severityFilterValue = (severityFilter) => {
        this.setState({ sev: severityFilter})
    }

    render() {
        return (
            <div>
                <Query query={GET_DIFF_BY_FILE} fetchPolicy="no-cache"
                    variables={{
                        repoScanRequestId: this.props.scanId,
                        filePath: this.props.filePath,
                        commentsCount : this.props.commentsCount,
                        type: this.props.type ? "Bugs" : this.props.type ? "Suggestion" : "",
                        severityName: this.state.sev
                    }}
                >
                    {({ loading, error, data, refetch }) => {
                        if (loading) return <div className="loading"><CircularProgress size={50} /></div>;
                        if (error) return (
                            <div className="text-center p-4">
                                <ErrorType type="serverError" size={450} head={"Internal Server Error"} content={error.message.split(':') && error.message.split(':').length && error.message.split(':')[2]
                                    ? error.message.split(':')[2].includes('404') ? 'The Branch were deleted so you cannot access the Diff' : error.message.split(':')[2]
                                    : 'Something Went Wrong...!'} />
                            </div>);
                        return (
                            <DialogContent className="p-0">
                                {!loading || !error ? <RepoAnalysisDiffView currentSev ={ this.state.sev } onSeverityFilterValue = { this.severityFilterValue } refresh={() => refetch()} data={data.sourceSegmentByReposcanRequestId} scanId={this.props.scanId} comments={data.repoScanCommentsByFilePath} /> : ''}
                            </DialogContent>
                        )
                    }}
                </Query>
            </div>
        );
    }
}

export default RepoAnalysisFileView;