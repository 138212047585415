import React from 'react';
import QualityGateStatusDoughnutChart from './QualityGateStatusDoughnutChart';
import { Typography } from '@material-ui/core';
import { qualityGateColor } from '../helper/helper'
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

var qualityGateCount = [];
var uniqueStatus = [];

const GET_REPO_LIST = gql`
query reviewRepos($pageNumber: Int, $pageSize: Int) {
	reviewRepos(pageNumber: $pageNumber, pageSize: $pageSize) {
    	content {
      	__typename
      	... on RepoScanRequest{
      	        id
   				projectName
      	        repoGroupName
      	        branchName
   				repoName
   				status
   				projectName
   				createdOn
   				modifiedOn
                version
                message
                requestedUser {
                    id
                    username
                    name
                }
                qualityGate
        	}
    	}
    	totalPages
    	totalElements
    	last
    	first
    	numberOfElements
    	size
    	number   	 
	}
}
`;

const MainReport = React.forwardRef((props, ref) => {
    const { error, data, loading } = useQuery(GET_REPO_LIST, {
        variables: {
            pageSize: 500,
            pageNumber: 0
        }
    });
    if (data && data.reviewRepos !== undefined) {
        qualityGateCount = [];
        uniqueStatus = [];
        data.reviewRepos.content.map((status) => {
            if (status?.qualityGate) {
                qualityGateCount.push(status?.qualityGate);
                var duplicateCount = {};
                qualityGateCount.forEach(e => duplicateCount[e] = duplicateCount[e] ? duplicateCount[e] + 1 : 1);
                uniqueStatus = Object.keys(duplicateCount).map(e => { return { key: e, count: duplicateCount[e] } });
            }
        })
    }

    return (
        <>
            <div ref={ref} className="container p-5" style={{ margin: '0 0 0 -30px' }}>
                <div className="head text-center">
                    <h1 className="m-0">Quality Gate Report</h1>
                </div>
                <div className="row m-0 mt-5">
                    <div className="col-6 px-0 text-center">
                        <h2 >Quality Status</h2>
                        <div className="col-12 py-0 m-auto" style={{ width: '300px' }}>
                            <QualityGateStatusDoughnutChart
                                data={uniqueStatus}
                            />
                        </div>
                    </div>
                    <div className="col-6 border-left px-5 mt-4">
                        {uniqueStatus.map((statusRange, index) => (
                            <Typography variant='h6' className="mt-3">
                                <i className="material-icons align-middle" style={{ fontSize: "bold", fontWeight: 800, color: qualityGateColor(statusRange.key) }}>horizontal_rule</i>
                                <span> : {statusRange.key} : <b>{statusRange.count}</b></span>
                            </Typography>
                        ))}
                    </div>
                </div>
            </div>


        </>

    );
});

export default MainReport;