import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GetAllRepoList from './RepoAnalysis-list';

var user = JSON.parse(localStorage.getItem('users'));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            className="w-100 p-0"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={0}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class RepoAnalysisReviews extends Component {
    userId;
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.switchTab = this.switchTab.bind(this);
        this.handleFromCloseChange = this.handleFromCloseChange.bind(this);
        this.handleApproverGrid = this.handleApproverGrid.bind(this);
        this.handleManualGrid = this.handleManualGrid.bind(this);
        this.handleReviewGrid = this.handleReviewGrid.bind(this);
        this.state = {
            showEditModal: false,
            showModal: false,
            activeKey: this.switchTab(this.props.match.path),
            open: false,
            updated: false,

        }
        this.props.history.listen((location, action) => {
            this.setState({
                activeKey: this.switchTab(location.pathname)
            })
        });
    }

    handleFromCloseChange() {
        this.setState({
            updated: true
        });
    }

    handleReviewGrid = () => {
         this.setState({
            updated: true
        });
    }

    handleApproverGrid = () => {
        this.setState({
            updated: true
        });
    }

    handleManualGrid = () => {
        this.setState({
            updated: true
        });
    }

    switchTab(location) {
        switch (true) {
            case (location.includes('fullscans')):
                return 0;
        }
    };

    handleChange = (event, newValue) => {
        this.setState({
            activeKey: newValue
        })
    };

    handleDialog() {
        this.props.onClose();
        this.props.refresh();
    }


    render() {
        return (

            <div className="pl-3 pr-3 review-root">
                {
                    !!user.organizations.length
                        ? <div>
                            <Tabs
                                value={this.state.activeKey}
                                onChange={this.handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="primary"
                                textColor="primary"

                                aria-label="scrollable force tabs example"
                            >
                                <Tab label="Full Scan Summary" onClick={this.handleReviewGrid}/>
                            </Tabs>
                            <TabPanel value={this.state.activeKey} index={0}>
                                <GetAllRepoList fromClose={this.props.location && this.props.location.state && this.props.location.state.fromClose && !this.state.updated ? true : false} onFromCloseChange={this.handleFromCloseChange} />
                            </TabPanel>
                        </div>
                        : <div className="text-center">
                            <img alt="No organization Found" src="images/noorg.png" />
                            <h2>No organization Found</h2>
                            <p>Please contact your administrator or manager to assign an organization</p>
                        </div>
                }

            </div>
        );
    }
}

export default RepoAnalysisReviews;
