import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from 'react-bootstrap/Button';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { colorByPercentage } from './helper/helper'
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    containerStyle: {
        color: "#797979",
        fontSize: '9px',
        marginTop: '1px'
    },
    itemStyle: {
        marginTop: '1px',
        marginLeft: '2px'
    },
    commitContainerStyle: {
        color: "#797979",
        fontSize: '9px',
        marginTop: '2px',
    },
    commitId: {
        cursor: 'pointer'
    }
}))

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        textTransform: 'none',
        height: '3px'
    },
    indicator: {
        backgroundColor: '#0076FE',
        height: '3px'
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
    }
}))((props) => <Tab disableRipple {...props} />);;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const versionsData = {
    status: 'Completed'
}

export default function RepoAnalysisVersions(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [checkedValues, setcheckedValues] = React.useState([]);
    const [compareDisable, setCompareDisable] = React.useState(true);
    const [checkDisable, setCheckDisable] = React.useState(false);
    const [copySucess, setCopySucess] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAddCheckedValues = (event, id) => {
        event.target.checked ? checkedValues.push(id) : checkedValues.splice(checkedValues.indexOf(id), 1); 
        setcheckedValues(checkedValues);
        if (checkedValues.length <= 1 || checkedValues.length > 3) {
            setCompareDisable(true);
        }
        else if (checkedValues.length === 3) {
            setCheckDisable(true)
        }
        else {
            setCompareDisable(false);
            setCheckDisable(false);
        }
    }

    const handleVersionCompareOpen = () => {
        props.hideDashboardChildComponent(true);
        props.getVersions(checkedValues);
    }

    const versionDateFormat = (dateAndTime) => {
        const versionDateFull = Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(dateAndTime).replace(/,/g, '').split(" ");
        const hrsMinsSecs = versionDateFull[5].split(":")[0] + ':' + versionDateFull[5].split(":")[1];
        const versionDateAndTime = versionDateFull[2] + '  ' + versionDateFull[1] + '  ' + versionDateFull[3] + '  ' + hrsMinsSecs + '  ' + versionDateFull[6];
        return versionDateAndTime;
    }

    const handleVersionBackGroundColor = (status, qualityGate) => {
        if(status === "Completed"){
            if(qualityGate === "Passed"){
                return '#28a745'
            }else if(qualityGate === "Warning"){
                return 'rgb(255, 191, 0)'
            }else if(qualityGate === "Failed"){
                return '#D30B34'
            }            
        }else{
            return "rgba(0, 0, 0, 0.54)";
        }
    }

    const versions = props.versionsData

    return (
        <div className="repo-versions p-0 m-0" style={{ position: 'relative' }}>
            <AntTabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
            >
                <AntTab label="Version History" style={{ color: '#0076FE', fontSize: 15, fontWeight: 800 }} />
            </AntTabs>
            <TabPanel value={value} index={0}>
                <div className="col-12 p-0 " style={{ maxHeight: "400px", overflowX: 'hidden', overflowY: 'auto' }}>
                    {versions?.versionHistory?.map((version) => (
                        <div className="version col-12 p-0 pl-4 pb-3 mt-2" key={version.version}>
                            <div className="row right-container">
                                <div className="col-9">
                                    <div className="row mt-2">
                                        <Grid container>
                                            <Tooltip 
                                                title={
                                                    checkDisable && !checkedValues.includes(version.version) 
                                                    ? 
                                                    "You can choose maximum of only 3 versions" 
                                                    : 
                                                    version.status != 'Completed' && "Incomplete version! So we dont support version comparision" || ''
                                                }
                                            >
                                                <Grid item xs={2}>
                                                    <Checkbox 
                                                        id={version.version} 
                                                        disabled={version.status != 'Completed' || version.version && checkDisable && !checkedValues.includes(version.version)} 
                                                        onChange={(event) => { handleAddCheckedValues(event, version.version) }} 
                                                    />
                                                </Grid>
                                            </Tooltip>
                                            <Grid item xs={10} style={{ marginTop: '11px' }}>
                                                <b style={{ fontSize: '13px' }}>{versions.repoName}</b>
                                                <Grid container className={classes.containerStyle} spacing={0}>
                                                    <Grid item>
                                                        <i className="material-icons align-middle" style={{ fontSize: "small" }}>access_time</i>
                                                    </Grid>
                                                    <Grid item xs={11} className={classes.itemStyle}>
                                                        <span>{versionDateFormat(version.dateTime)}</span>
                                                    </Grid>
                                                </Grid>
                                                <Tooltip title={copySucess ? "Copied" : ""} onClose={() => { setCopySucess(false) }}>
                                                    <Grid container className={classes.commitContainerStyle} spacing={0}>
                                                        <Grid item>
                                                            <i className="material-icons align-middle" style={{ fontSize: "small" }}>commit</i>
                                                        </Grid>
                                                        <Grid item xs={11} className={classes.itemStyle}>
                                                            <span
                                                                className={classes.commitId}
                                                                onClick={() => navigator.clipboard.writeText((version.commitId)) && setCopySucess(true)}
                                                            >
                                                                {(version.commitId).slice(version.commitId.length - 8)}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="mt-2">
                                        <span
                                            className={"ml-3 pt-1 pb-1 badge mt-2"}
                                            style={
                                                {
                                                    borderRadius: '23px',
                                                    backgroundColor: handleVersionBackGroundColor(version.status, version.qualityGate),
                                                    color: 'white',
                                                    paddingLeft: '13px',
                                                    paddingRight: '13px'
                                                }
                                            }>
                                            {version.version}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='col-12' style={{ bottom: '10px', position: 'absolute', maxWidth: '92%' }}>
                    <div className="row">
                        <Button className='compare-btn' variant='outlined' disabled={compareDisable} onClick={handleVersionCompareOpen} ><b>Compare</b></Button>
                    </div>
                </div>
            </TabPanel>
            <div style={{ clear: "both" }}></div>
        </div>
    );
}
