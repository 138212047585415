import React, { Component, useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { LocalToUTCTime } from '../../../../core/service/timezone';
import '../../../../core/common.css';
import preferenceService from '../../../../core/service/preference';

class FullScanDatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateLabel: 'Search',
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            label: '',
            fieldData: "",
        };
    };

    componentWillReceiveProps() {
        let date = {}
        let label = 'search';
        if (this.props.columnDef.field && preferenceService.getValue('FullScanGridValues')[this.props.columnDef.field])
            if (preferenceService.getValue('FullScanGridValues')[this.props.columnDef.field] !== "") {
                date.startDate = moment(preferenceService.getValue('FullScanGridValues')[this.props.columnDef.field].split("-")[0], 'x');
                date.endDate = moment(preferenceService.getValue('FullScanGridValues')[this.props.columnDef.field].split("-")[1], 'x');
                label = this.getDateOptions(date).label;
            }
        if (preferenceService.getValue('FullScanGridValues')[this.props.columnDef.field] === "0-0") {
            label = 'search';
        }
        this.setState({
            fieldData: label
        })
    }

    ranges = {
        'Reset': [0, 0],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    getDateOptions(picker) {
        let seletedDate = '';
        if (picker.startDate.format('YYYY-MM-DD') === picker.endDate.format('YYYY-MM-DD')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }
        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    reviewByDate(event, picker) {
        let data = this.getDateOptions(picker);
        if (picker.chosenLabel !== 'Reset' && data && data.dateObj) {
            this.setState({
                dateLabel: data.label,
                startDate: data.dateObj.startDate.format('YYYY-MM-DD'),
                endDate: data.dateObj.endDate.format('YYYY-MM-DD')
            });
        }
        else {
            this.setState({
                dateLabel: 'Search',
                startDate: 0,
                endDate: 0
            });
        }
    };

    render() {
        return (
            <DateRangePicker containerStyles={{ display: "block", width: '140px' }}
                ranges={this.ranges}
                onApply={(event, picker) => {
                    this.reviewByDate(event, picker)
                    let dateRange = LocalToUTCTime(this.state.startDate, 'x') + '-' + LocalToUTCTime(this.state.endDate, 'x');
                    this.props.onFilterChanged(this.props.columnDef.tableData.id, dateRange)
                    this.setState({
                        fieldData: this.getDateOptions(picker).label
                    })
                }}

            >
                <div className="custom-select customDateRangePicker dateRange">
                    {this.state.fieldData}
                </div>
            </DateRangePicker>

        );
    }
}

export default FullScanDatePicker;