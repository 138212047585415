import Grid from '@material-ui/core/Grid';
import React, { Component, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    textName: {
        color: '#0076FE',
        fontWeight: 400,
        fontSize: '13.5px',
        lineHeight: '15px'
    },
    countNumber: {
        fontSize: '27px'
    }
}));

function RepoAnalysisFilesDetails(props) {
    const classes = useStyles();
    return (
        <Grid container className='ml-3 mt-3'>
            <Grid item className='ml-3 mr-3' xs={3}>
                < Grid container >
                    <Grid item xs={12}>
                        <h4 className={classes.countNumber}>
                            <b><Tooltip placement="top" id="placement" title={props.data.totalComments}><span>{props.data.totalComments.toString().length >= 4 ? Math.trunc(props.data.totalComments / 1000) + 'K' : props.data.totalComments}</span></Tooltip></b>
                        </h4>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.textName}>Comments</div>
                    </Grid>
                </ Grid>
            </Grid >
            <Grid item className='ml-4' xs={4}>
                <Grid container>
                    <Grid item xs={12}>
                        <h4 className={classes.countNumber}>
                            <b><Tooltip placement="top" id="placement" title={props.data.totalNoOfLines}><span>{props.data.totalNoOfLines.toString().length >= 4 ? Math.trunc(props.data.totalNoOfLines / 1000) + 'K' : props.data.totalNoOfLines}</span></Tooltip></b>
                        </h4>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.textName}>Lines of Code</div>                        
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className='ml-2' xs={3}>
                <Grid container>
                    <Grid item xs={12}>
                        <h4 className={classes.countNumber}>
                            <b><Tooltip placement="top" id="placement" title={props.data.totalNoOfFiles}><span>{props.data.totalNoOfFiles.toString().length >= 4 ? Math.trunc(props.data.totalNoOfFiles / 1000) + 'K' : props.data.totalNoOfFiles}</span></Tooltip></b>
                        </h4>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.textName}>Files</div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >)
}

export default RepoAnalysisFilesDetails;