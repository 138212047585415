import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Grid, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Snackbars from '../../core/error/Snackbars';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


const RESUMBIT_VALUE = gql`
mutation resubmitScanningById($repoScanRequestId: String!, $userId: String!, $repoScanCategory: [RepoScanCategoryInfoDTOInput],$message:String)
{ 
    resubmitScanningById(repoScanRequestId: $repoScanRequestId, userId: $userId,repoScanCategory: $repoScanCategory,message:$message)
    {	
        status,
        message
    }  
}`;

const data = {
    status: "error",
    message: "Scanning is currently resubmitting"
}

var resubmitData = {};

var global = 1;

const user = JSON.parse(localStorage.getItem('users'));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    paddingTop: {
        paddingTop: '10px'
    },
    close: {
        padding: theme.spacing(0.5),
    },
    checkBoxLabel: {
        color: "#000 !important",
        fontSize: 14
    },
    checkboxStyle: {
        color: "#3f51b5 !important"
    },
    noteMsg: {
        padding: "0px 12px",
        fontSize: 12,
        color: "slategrey"
    },
    categoryItem: {
        paddingTop: 2,
        paddingBottom: 2
    }
}))

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function RepoAnalysisActions(props) {
    const classes = useStyles();
    const [message, setMessage] = useState('');
    const [comments, setComments] = useState('');
    const [checkedValues, setCheckedValues] = useState([]);
    const [checkdefault, setCheckDefault] = useState(true);
    const form = React.useRef(null);
    const [disableSubmitArray, setdisableSubmitArray] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [loadingMsg, setLoadingMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    
    //const categories = props.category;
    const categories = [
        {value: 'Bugs', flag: true},
        {value: 'Duplications', flag: false},
        {value: 'Security', flag: true},
        {value: 'Suggestion', flag: true}
    ];
    //console.log(categories)

    const handleClose = () => {
        props.onClose();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        form.current.submit()
    }

    const handleAddCheckedValues = (event, id) => {
        
        if (event.target.checked) {
            id.flag = true;
        }
        else if (event.target.checked === false) {
            id.flag = false;
        }
        // checkedValues.map((checkedVal) => {
        //     if (checkedVal.flag === true && disableSubmitArray?.indexOf(checkedVal.value) === -1) {
        //         disableSubmitArray.push(checkedVal.value)
        //     }
        //     else if (checkedVal.flag === false && disableSubmitArray?.indexOf(checkedVal.value) !== -1) {
        //         disableSubmitArray.splice(disableSubmitArray.indexOf(checkedVal.value), 1)
        //     }
        // })
        // disableSubmitArray?.length > 0 ? setDisableSubmit(false) : setDisableSubmit(true)
    }

    const initializeCheckedValues = (category, flag) => {
        checkedValues.push(category)
        setCheckDefault(false);
    }

    const handleMutDialog = () => {
        console.log("first")
    }

    return (
        
            <Mutation mutation={RESUMBIT_VALUE} onCompleted={() => handleMutDialog }>
                {(resubmitScanningById, { loading, error, data }) => {
                    if (loading) {
                        setLoadingMsg(true)
                    }
                    if(error) {
                        setLoadingMsg(false)
                        setErrorMsg(true) 
                        var errorMessage = "";
                        if (error.message && error.message.includes('The branch has been scanned already. Looks like there are no new changes in the review')) {
                            errorMessage = <span className="text-danger" >{error.message.split(":")[2]}<a className="text-primary" href={"/fullscans-dashboard/" + error.message.split(":")[3].trim()} title='Click here to view your review'> {error.message.split(":")[3]} </a></span>
                        } else if (error.message && error.message.includes('Repo scanning in progress with the scanning id')) {
                            errorMessage = <span className="text-danger" >{error.message.split(":")[2]}<a className="text-primary" href={"/fullscans-dashboard/" + error.message.split(":")[3].trim()} title='Click here to view your review'> {error.message.split(":")[3]} </a></span>
                        } else if (error.message && error.message.includes('RepoScan requested has been deleted/Not available')) {
                            errorMessage = <span className="text-danger" >{error.message.split(":")[2]}</span>
                        } else if (error.message) {
                            errorMessage = <span className="text-danger" >{error.message.split(":")[2]}</span>
                        } else {
                            errorMessage = "Something went wrong"
                        }                       
                    }
                    if (data?.resubmitScanningById?.status === "Success") {
                        props.onClose(false, data?.resubmitScanningById.message);
                        props.snackbarsOpen(true)
                        props.refresh()
                    }
                    return (
                        <ValidatorForm id="stop"
                            ref={form}
                            onSubmit={e => {
                                const repoScanRequestId = props.scanId;
                                const userId = user.id;
                                const message = '';
                                const repoScanCategory = checkedValues;
                                resubmitScanningById({
                                    variables:
                                        { repoScanRequestId, userId, repoScanCategory, message }
                                });
                            }}
                            instantValidate
                        >
                            <Dialog aria-labelledby="customized-dialog-title" maxWidth="sm" fullWidth={true} open={props.showModal}>
                                <DialogTitle onClose={handleClose}>
                                    {props.actionName.charAt(0).toUpperCase() + props.actionName.slice(1)}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        Are you sure want to resubmit?
                                    </DialogContentText>
                                    {/* <Grid container>
                                        {
                                            props.category.map((category) => (
                                                <Grid item xs={3} key={category.value}>
                                                    {checkdefault ? initializeCheckedValues(category, category.flag) : ""}
                                                    <Checkbox
                                                        disabled = {category.flag ? true : false}    
                                                        defaultChecked={category.flag}
                                                        onChange={(event) => { handleAddCheckedValues(event, category) }}
                                                        name="checkedB"
                                                        color="primary"
                                                        className={classes.checkboxStyle}
                                                    />
                                                    <span className={classes.checkBoxLabel}>{category.value}</span>
                                                </Grid>
                                            ))}
                                    </Grid> */}
                                    {/* <Grid container>
                                        <List>
                                            {
                                                props.category.map((category) => (
                                                    <ListItem className={classes.categoryItem}>
                                                        <ListItemText primary={ category.value } />
                                                    </ListItem>                                
                                                ))
                                            }
                                        </List>
                                    </Grid> */}
                                    {/* <Typography className={classes.noteMsg}>Note: Checked categories not able to change</Typography> */}
                                    {/* <br></br> */}
                                    {/* <DialogContentText id="alert-dialog-slide-description">
                                        Are you sure want to {props.actionName} the full scan process?
                                    </DialogContentText> */}
                                    {
                                        errorMsg && error &&
                                        <Typography variant='body1' className={classes.paddingTop}>
                                            <span className="text-danger" >{errorMessage}</span>
                                        </Typography>
                                    }
                                    {loadingMsg && <div style={{ textAlign: "center"}}><CircularProgress size={25}/></div>}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={(e) => { handleSubmit(e) }} color="primary" type="submit">
                                        {props.actionName}
                                    </Button>
                                    <Button onClick={handleClose} color="secondary">
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </ValidatorForm>
                    )
                }}
            </Mutation>
        
    );
}

export default RepoAnalysisActions;