import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  FailedMsgTableCell: {
    borderBottom: "none"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AnalysisReportCategoryFailedMessage(props) {
  const classes = useStyles1();
  const [open, setOpen] = React.useState(true);
  const [openChild, setOpenChild] = React.useState(false);
  const messages = props.messageDetail

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.popupClose();
  };

  const handleChildClickOpen = () => {
    setOpenChild(true);
  };

  const handleChildClose = () => {
    setOpenChild(false);
  };

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        onClose={handleClose} 
        aria-labelledby="customized-dialog-title" 
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Scanning Failure Message
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer>
            <Table className={classes.table} aria-label="custom pagination table">
              <TableBody>
                {
                  messages?.map((message) => (
                    <TableRow>
                      <TableCell className={classes.FailedMsgTableCell} component="th" scope="row">
                        <Typography>{message.key}</Typography>
                      </TableCell>
                      <TableCell className={classes.FailedMsgTableCell}>
                        {message.value}
                      </TableCell>
                      <TableCell className={classes.FailedMsgTableCell}>
                        <Tooltip
                          title="More Info"
                          placement="top"
                        >
                          <i
                            className="material-icons text-danger"
                            onClick={() => { handleChildClickOpen() }}
                          >
                            error
                          </i>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openChild}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleChildClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">{"More Information"}</DialogTitle>
        <DialogContent style={{ backgroundColor:'black'}}>
          <DialogContentText id="alert-dialog-slide-description" style={{color:'white'}}>
            ERROR [http-nio-8080-exec-8] org.apache.juli.logging.DirectJDKLog: Exception while processing an asynchronous request
            java.lang.IllegalStateException: Calling [asyncError()] is not valid for a request with Async state [MUST_DISPATCH]
            at org.apache.coyote.AsyncStateMachine.asyncError(AsyncStateMachine.java:440)
            at org.apache.coyote.AbstractProcessor.action(AbstractProcessor.java:513)
            at org.apache.coyote.Request.action(Request.java:430)
            at org.apache.catalina.core.AsyncContextImpl.setErrorState(AsyncContextImpl.java:401)
            at org.apache.catalina.connector.CoyoteAdapter.asyncDispatch(CoyoteAdapter.java:239)
            at org.apache.coyote.AbstractProcessor.dispatch(AbstractProcessor.java:242)
            at org.apache.coyote.AbstractProcessorLight.process(AbstractProcessorLight.java:53)
            at org.apache.coyote.AbstractProtocol$ConnectionHandler.process(AbstractProtocol.java:861)
            at org.apache.tomcat.util.net.NioEndpoint$SocketProcessor.doRun(NioEndpoint.java:1579)
            at org.apache.tomcat.util.net.SocketProcessorBase.run(SocketProcessorBase.java:49)
            at java.base/java.util.concurrent.ThreadPoolExecutor.runWorker(ThreadPoolExecutor.java:1128)
            at java.base/java.util.concurrent.ThreadPoolExecutor$Worker.run(ThreadPoolExecutor.java:628)
            at org.apache.tomcat.util.threads.TaskThread$WrappingRunnable.run(TaskThread.java:61)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChildClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}