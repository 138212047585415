import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { LocalToUTCTime, UTCtoLocalTime, LocalDate } from './../../../core/service/timezone';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import InputLabel from '@material-ui/core/InputLabel';
import ReactApexChart from 'react-apexcharts';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';

const chartPageStyle = theme => ({
    snackbar: {
        "& .MuiSnackbarContent-root": {
            backgroundColor: '#dc3545',
            fontWeight: 800
        }
    },
    iconButton: {
        size: "small",
        ariaLabel: "close",
        color: "inherit"
    },
    closeIcon: {
        fontSize: "small"
    }
});

const REVIEW_AND_COMMENTS_BY_DURATION = gql`
query reviewsAndCommentsByDuration($projectGroupName: String, $startDate: Long, $endDate: Long, $timeZoneOffset: Long) {
    reviewsAndCommentsByDuration(projectGroupName: $projectGroupName, startDate: $startDate, endDate: $endDate, timeZoneOffset : $timeZoneOffset) {
        _id
		numOfPrs
		numOfComments
		createdOn
    }
    projectGroupsWithoutPagination {
        id
        projectGroupName
    }
}`;

class ReviewCommentsByDate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectGroup: 0,
            dateLabel: 'Today',
            drag: true,
            timeZoneOffset: new Date().getTimezoneOffset(),
            startDate: "",
            endDate: "",
            commentsCount: [],
            prCount: [],
            labelValue: [],
            snackbarOpen: false
        };
    }

    ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    resetZoom() {
        this.setState({
            drag: false
        })
    }

    getDateOptions = (picker) => {
        var seletedDate = '';

        if (picker.startDate.format('MMMM D, YYYY') === picker.endDate.format('MMMM D, YYYY')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }

        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    reviewByDate = (event, picker, refetch) => {
        var data = this.getDateOptions(picker);

        if (picker.chosenLabel !== 'Date Range' && data && data.dateObj) {
            var start = moment(data.dateObj.startDate);
            var end = moment(data.dateObj.endDate);

            //Difference in number of days
            var days = end.diff(start, 'days') + 1;
            if (days <= 100) {
                this.setState({
                    dateLabel: data.label,
                    groupByDateFilter: null,
                    startDate: data.dateObj.startDate.format('YYYY-MM-DD'),
                    endDate: data.dateObj.endDate.format('YYYY-MM-DD'),
                }, () => {
                    refetch();
                });
            } else {
                this.setState({
                    snackbarOpen: true
                });
            }
        } else {
            this.setState({
                dateLabel: 'Date Range',
                groupByDateFilter: null,
                startDate: null,
                endDate: null,
            }, () => {
                refetch();
            });
        }
    }

    closeSnackbar = (data) => {
        if (data) {
            setTimeout(() => {
                this.setState({
                    snackbarOpen: false
                });
            }, 4000)
        } else if (!data) {
            this.setState({
                snackbarOpen: false
            });
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <Query fetchPolicy="no-cache" query={REVIEW_AND_COMMENTS_BY_DURATION} variables={{
                startDate: this.state.startDate ? LocalToUTCTime(this.state.startDate, 'x') : LocalDate(),
                endDate: this.state.endDate ? LocalToUTCTime(this.state.endDate, 'x') : LocalDate(),
                timeZoneOffset: this.state.timeZoneOffset
            }}>
                {({ loading, error, data, refetch }) => {

                    if (loading) return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-4 bg-white my-0">
                                <div className="row mb-4">
                                    <div className="col-lg-10">
                                        <Skeleton variant="text" width={"150px"} />
                                        <Skeleton variant="text" width={"100px"} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                </div>
                                <Skeleton variant="rect" width={"100%"} height={"235px"} />
                            </div>
                        </div>
                    );

                    if (data) {
                        if (this.state.labelValue.length !== 0 && this.state.prCount.length !== 0 && this.state.commentsCount.length !== 0) {
                            this.setState({
                                commentsCount: [],
                                prCount: [],
                                labelValue: []
                            })
                        }
                        data.reviewsAndCommentsByDuration.map((d) => {
                            this.state.commentsCount.push(d.numOfComments);
                            this.state.prCount.push(d.numOfPrs);
                            this.state.labelValue.push(d.createdOn
                                ?
                                UTCtoLocalTime(d.createdOn, 'D MMM')
                                :
                                d.month.split(" ")[0].slice(0, 3) + '-' + d.month.split(" ")[1].slice(2, 4));
                        });
                    }

                    if (error) return (
                        <div className="text-center"></div>
                    );

                    return (
                        <div className="row px-2">
                            <div className="col-md-12 chart-container p-4 mb-3 ml-0 bg-white">
                                <div className="row mb-4">
                                    <div className="col-lg-9">
                                        <h4>Reviews Vs Comments By Date Range</h4>
                                    </div>
                                    <div className="col-lg-3 p-0">
                                        {data.reviewsAndCommentsByDuration && <Tooltip title={'Reset Zoom'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.resetZoom()}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 mb-4 align-middle">
                                        <InputLabel>Date Range</InputLabel>
                                        <DateRangePicker containerStyles={{ display: "block" }}
                                            ranges={this.ranges}
                                            maxDate={moment().format('MM/DD/YYYY')}
                                            onApply={(event, picker) => this.reviewByDate(event, picker, refetch)}>
                                            <div className="custom-select dateRange">
                                                {this.state.dateLabel}
                                            </div>
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <ReactApexChart
                                    series={
                                        [{
                                            name: 'Comments',
                                            type: 'column',
                                            data: this.state.commentsCount
                                        }, {
                                            name: 'PR-Count',
                                            type: 'line',
                                            data: this.state.prCount
                                        }]
                                    }
                                    options={
                                        {
                                            chart: {
                                                toolbar: {
                                                    show: false,
                                                },
                                                height: 450,
                                                type: 'line',
                                            },
                                            stroke: {
                                                width: [0, 4]
                                            },
                                            dataLabels: {
                                                enabled: true,
                                                enabledOnSeries: [1]
                                            },
                                            labels: this.state.labelValue,
                                            xaxis: {
                                                type: 'date',
                                                min: this.state.startDate ? LocalToUTCTime(this.state.startDate, 'x') : LocalDate(),
                                                tickAmount: 5,
                                            },
                                            tooltip: {
                                                x: {
                                                    format: 'dd MMM yyyy'
                                                }
                                            },
                                            yaxis: [{
                                                labels: {
                                                    formatter: function (val) {
                                                        return val.toFixed(0);
                                                    }
                                                },
                                                title: {
                                                    text: 'Comments',
                                                },

                                            }, {
                                                opposite: true,
                                                labels: {
                                                    formatter: function (val) {
                                                        return val.toFixed(0);
                                                    }
                                                },
                                                title: {
                                                    text: 'PR-Count'
                                                }
                                            }]
                                        }
                                    }
                                    type="line"
                                    height={350}
                                />
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    className={classes.snackbar}
                                    open={this.state.snackbarOpen}
                                    autoHideDuration={1000}
                                    ContentProps={{
                                        'aria-describedby': 'message-id',
                                    }}
                                    onClose={() => { this.closeSnackbar(true) }}
                                    message={
                                        <span id="message-id">
                                            <ErrorOutlineIcon className='mr-2' />
                                            Choose between 100 days.
                                        </span>
                                    }
                                    action={[
                                        <IconButton className={classes.iconButton} onClick={() => { this.closeSnackbar(false) }}>
                                            <CloseIcon className={classes.closeIcon} />
                                        </IconButton>
                                    ]}
                                />
                            </div>
                        </div>
                    )
                }}

            </Query>
        )
    }
}

export default withStyles(chartPageStyle)(ReviewCommentsByDate);