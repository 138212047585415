import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import MuiAccordion from '@material-ui/core/ExpansionPanel';
import MuiAccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiAccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import DependencyEvidence from './security/DependencyEvidence';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Link from '@material-ui/core/Link';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Paper } from '@material-ui/core';
import { Block } from '@material-ui/icons';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from "@material-ui/core/TableCell";
import RelatedDependencies from './security/RelatedDependencies';
import PublishedVulnerability from './security/PublishedVulnerability';


const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
            '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
        borderRadius: 4
    },
    expanded: {},
})(MuiAccordion);
  
const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#eaf1fb',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 30,
        '&$expanded': {
            minHeight: 30,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 600
    },
    styleText: {
        fontStyle: "italic",
        color: "gray"
    },
    vulnerabilitiesDetails: {
        display: "block !important"
    }
}));

const TableCell = withStyles({
    root: {
      borderBottom: "none",
      '&.MuiTableCell-root':{
        fontSize: 13,
        padding: "3px 40px 3px 10px !important"
      },
      '&.MuiTableCell-head':{
        lineHeight: "none"
      }
    }
  })(MuiTableCell);

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function RepoAnalysisSecurityDetail(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    
    const handleClose = () => {
        props.onClose()
    };

    return (
        <>
            <Dialog 
                onClose={handleClose} 
                fullWidth={true}
                maxWidth="lg"
                open={props.showModal}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>Dependencies: { props.data.fileName}</DialogTitle>
                <DialogContent dividers>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                {
                                    props.data?.description &&
                                    <TableRow>
                                        <TableCell><span className={classes.title}>Description:</span></TableCell>
                                        <TableCell>{ props.data.description }</TableCell>
                                    </TableRow>
                                }
                                {
                                    props.data?.license &&
                                    <TableRow>
                                        <TableCell><span className={classes.title}>License:</span></TableCell>
                                        <TableCell>{ props.data.license }</TableCell>
                                    </TableRow>
                                }
                                {
                                    props.data?.filePath &&
                                    <TableRow>
                                        <TableCell><span className={classes.title}>File Path:</span></TableCell>
                                        <TableCell>{ props.data.filePath }</TableCell>
                                    </TableRow>
                                }
                                {
                                    props.data?.md5 &&
                                    <TableRow>
                                        <TableCell><span className={classes.title}>MD5:</span></TableCell>
                                        <TableCell>{ props.data.md5 }</TableCell>
                                    </TableRow>
                                }
                                {
                                    props.data?.sha1 &&
                                    <TableRow>
                                        <TableCell><span className={classes.title}>SHA1:</span></TableCell>
                                        <TableCell>{ props.data.sha1 }</TableCell>
                                    </TableRow>
                                }
                                {
                                    props.data?.sha256 &&
                                    <TableRow>
                                        <TableCell><span className={classes.title}>SHA256:</span></TableCell>
                                        <TableCell>{ props.data.sha256 }</TableCell>
                                    </TableRow>
                                }
                            </TableHead>
                        </Table>
                    </TableContainer>
                    <br></br>
                    <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                            <Typography>Evidence</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DependencyEvidence data={props?.data?.evidenceCollected}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon />}>
                            <Typography>Related Dependencies</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            { props?.data?.relatedDependencies && <RelatedDependencies data={ props.data.relatedDependencies } /> }
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon />}>
                            <Typography>Identifiers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                {
                                    props.data?.packages && props.data.packages.map((packs, identifiersKey) => (
                                        <ListItem key={identifiersKey}>
                                            <ListItemIcon>
                                                <ArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Link target="_blank" href={packs.url}>
                                                    { packs.id } 
                                                </Link>
                                                <span className={classes.styleText}>(Confidence: {packs.confidence})</span>
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                                {
                                    props.data?.vulnerabilityIds && props.data.vulnerabilityIds.map((vulids, vulidKey) => (
                                        <ListItem key={vulidKey}>
                                            <ListItemIcon>
                                                <ArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Link target="_blank" href={vulids.url}>
                                                    { vulids.id } 
                                                </Link>
                                                <span className={classes.styleText}>(Confidence: {vulids.confidence})</span>
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" expandIcon={<ExpandMoreIcon />}>
                            <Typography>Published Vulnerabilities</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.vulnerabilitiesDetails}>
                            { props?.data?.vulnerabilities && <PublishedVulnerability data={props?.data?.vulnerabilities}/> }
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
