import React from 'react';
import ReactApexChart from 'react-apexcharts';

function ReviewByRepoTreeMap(props) {

    return (
        <div>
            {
                props.data?.length !== 0
                    ?
                    <ReactApexChart
                        series={[
                            {
                                data: props.dataSet
                            }
                        ]}
                        options={
                            {
                                tooltip: {
                                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                                        return '<ul class="treeMap-tooltip">' +
                                            '<li><b>Repo Name</b>: ' + data.x + '</li>' +
                                            '<li><b>Line Changes</b>: ' + data.y + '</li>' +
                                            '<li><b>PR Count</b>: ' + data.z + '</li>' +
                                            '</ul>';
                                    }
                                },
                                chart: {
                                    toolbar: {
                                        show: false,
                                    }
                                },
                                colors: ["#008FFB"],
                                plotOptions: {
                                    treemap: {
                                        distributed: false,
                                        enableShades: false
                                    }
                                }
                            }
                        }
                        type="treemap"
                        height={350}
                    />
                    :
                    <div className="text-center pt-5 graph-height" >
                        <img
                            src="/images/statistic.jpg"
                            width="90"
                            alt="noData"
                        />
                        <br />
                        Not enough data to display the chart
                    </div>
            }
        </div>
    );
}

export default ReviewByRepoTreeMap;