import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import '../common.css';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import preferenceService from '../../core/service/preference';

class CustomSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fieldData: ""
    }
    this.updateValue = this.updateValue.bind(this);
  };

  componentWillReceiveProps() {
    this.setState({
      fieldData: preferenceService.getValue('reviewGridValues')[this.props.columnDef.field]
    })
  }

  handleChange = (event) => {
    if (event.key === 'Enter' || event.target.value === '') {
      this.props.onFilterChanged(this.props.columnDef.tableData.id, event.target.value.trim())
    } else if (event.key === 'Delete') {
      event.target.value = null;
      this.props.onFilterChanged(this.props.columnDef.tableData.id, '');
    }
  };
  
  updateValue(event) {
    this.setState({
      fieldData: event.target.value
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <TextField type="text" onKeyUp={this.handleChange} onChange={this.updateValue} placeholder="search"
            value={this.state.fieldData}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Filter" placement="down" id="placement">
                    <FilterListIcon />
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
    );
  }
}

export default CustomSearch