import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';

function ViewSslCertificate(props) {
    const handleClose = () => {
        props.onClose(false);
    }

    return (
        <div>
            <Dialog maxWidth='sm' open={props.dialogOpen} >
                <DialogTitle id="customized-dialog-title">
                    SSL Certificate
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant='body2'>{props.data}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ViewSslCertificate;