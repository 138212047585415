import React, { Component } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Checkbox } from '@material-ui/core';


function RepoAnalysisSkeleton() {
    return (
        <div className='skeletonRepo' style={{ backgroundColor: "#eaf1fb", height: "100%", width: "100%" }}>
            <div className="w-100 pt-4">
                <div className='ml-3 mb-1' style={{ fontSize: '15px', color: '#7D7D7E' }}>
                    <Skeleton variant="rect" width={200} height={20} animation="wave" />
                </div>
                <div className="col-md-9 review-button">
                    <Skeleton variant="rect" width={500} height={30} animation="wave" />
                </div>
                <div className="row ml-2 mt-4">
                    <div className="col-md-8 pl-0 pr-0">
                        <div className='col-12 pl-0 pr-0'>
                            <div className="repoid-detail p-3 shadow" style={{ fontSize: 15 }}>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className='row no-gutters'>
                                            <div className='col-1 ml-3 '>
                                                <Skeleton variant="circle" width={55} height={55} animation="wave" />
                                            </div>
                                            <div className='col-4 ml-3 mt-2'>
                                                <Skeleton variant="rect" width={50} height={10} animation="wave" />
                                                <div className='email mt-2' ><Skeleton variant="rect" width={80} height={10} animation="wave" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className='mt-5'>
                                            <div className='row no-gutters ml-3'>
                                                <div className='col-md-4'>
                                                    <h4 className="m-0" style={{ fontSize: 30 }}><Skeleton variant="rect" width={50} height={50} animation="wave" /></h4>
                                                    <div className='mt-1' style={{ color: '#d30b34' }}><Skeleton variant="rect" width={100} height={15} animation="wave" /></div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <h4 className="m-0" style={{ fontSize: 30 }}><Skeleton variant="rect" width={50} height={50} animation="wave" /></h4>
                                                    <div className='mt-1' style={{ color: '#d30b34' }}><Skeleton variant="rect" width={100} height={15} animation="wave" /></div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <h4 className="m-0" style={{ fontSize: 30 }}><Skeleton variant="rect" width={50} height={50} animation="wave" /></h4>
                                                    <div className='mt-1' style={{ color: '#d30b34' }}><Skeleton variant="rect" width={100} height={15} animation="wave" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 ml-3">
                                        <Button className='mt-md-4' disabled={true} variant="contained" style={{ width: '150px', backgroundColor: '#3677fe', color: 'white' }}>
                                            <b>Source Code</b>
                                        </Button>
                                        <div className='mt-2' style={{ color: '#536383' }}>
                                            <Skeleton variant="rect" width={400} height={10} animation="wave" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 pl-0 pr-0 mt-4'>
                            <Skeleton variant="rect" width={840} height={10} animation="wave" />
                        </div>
                        <div className='col-12 pl-0 pr-0 mt-4'>
                            <div className='row mt-4'>
                                <div className='areport ml-3'>
                                    <div className='w-100'>
                                        <h5><b>Analysis Report</b></h5>
                                    </div>
                                    <div className='col-12 p-2'>
                                        <div className='w-100'>
                                            <div className='row no-gutters'>
                                                <div className='col-4 p-2'>
                                                    <div className="col-12 mt-1  shadow" style={{ fontSize: 15, borderRadius: 10, backgroundColor: 'white', height: "185px", width: '280px' }}>
                                                        <div className='row no-gutters'>
                                                            <div className='col-1 mr-3 mt-2'>
                                                                <Skeleton variant="circle" width={30} height={30} animation="wave" />
                                                            </div>
                                                            <div className='col-4 mt-2 ml-2'>
                                                                <div style={{ fontSize: 23 }}><Skeleton variant="rect" width={100} height={30} animation="wave" /></div>
                                                            </div>
                                                            <div className='mr-5 mt-3'>
                                                                <Skeleton variant="text" width={200} height={17} animation="wave" />
                                                                <Skeleton variant="text" width={200} height={17} animation="wave" />
                                                                <Skeleton variant="text" width={200} height={17} animation="wave" />
                                                            </div>
                                                            <div className='row no-gutters mt-4'>
                                                                <div className='col-6 p-1'>
                                                                    <span style={{ fontSize: 30 }}><b><Skeleton variant="rect" width={40} height={35} animation="wave" /></b></span>
                                                                    <div style={{ fontSize: 13, color: 'red', fontWeight: 'lighter' }}><Skeleton variant="text" width={90} height={17} animation="wave" /></div>
                                                                </div>
                                                                <div className='col-6 p-1'>
                                                                    <span style={{ fontSize: 30 }}><b><Skeleton variant="rect" width={40} height={35} animation="wave" /></b></span>
                                                                    <div style={{ fontSize: 13, color: 'red', fontWeight: 'lighter' }}><Skeleton variant="text" width={90} height={17} animation="wave" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-4 p-2'>
                                                    <div className="col-12 mt-1  shadow" style={{ fontSize: 15, borderRadius: 10, backgroundColor: 'white', height: "185px", width: '280px' }}>
                                                        <div className='row no-gutters'>
                                                            <div className='col-1 mr-3 mt-2'>
                                                                <Skeleton variant="circle" width={30} height={30} animation="wave" />
                                                            </div>
                                                            <div className='col-4 mt-2 ml-2'>
                                                                <div style={{ fontSize: 23 }}><Skeleton variant="rect" width={100} height={30} animation="wave" /></div>
                                                            </div>
                                                            <div className='mr-5 mt-3'>
                                                                <Skeleton variant="text" width={200} height={17} animation="wave" />
                                                                <Skeleton variant="text" width={200} height={17} animation="wave" />
                                                                <Skeleton variant="text" width={200} height={17} animation="wave" />
                                                            </div>
                                                            <div className='row no-gutters mt-4'>
                                                                <div className='col-6 p-1'>
                                                                    <span style={{ fontSize: 30 }}><b><Skeleton variant="rect" width={40} height={35} animation="wave" /></b></span>
                                                                    <div style={{ fontSize: 13, color: 'red', fontWeight: 'lighter' }}><Skeleton variant="text" width={90} height={17} animation="wave" /></div>
                                                                </div>
                                                                <div className='col-6 p-1'>
                                                                    <span style={{ fontSize: 30 }}><b><Skeleton variant="rect" width={40} height={35} animation="wave" /></b></span>
                                                                    <div style={{ fontSize: 13, color: 'red', fontWeight: 'lighter' }}><Skeleton variant="text" width={90} height={17} animation="wave" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-4 p-2'>
                                                    <div className="col-12 mt-1  shadow" style={{ fontSize: 15, borderRadius: 10, backgroundColor: 'white', height: "185px", width: '280px' }}>
                                                        <div className='row no-gutters'>
                                                            <div className='col-1 mr-3 mt-2'>
                                                                <Skeleton variant="circle" width={30} height={30} animation="wave" />
                                                            </div>
                                                            <div className='col-4 mt-2 ml-2'>
                                                                <div style={{ fontSize: 23 }}><Skeleton variant="rect" width={100} height={30} animation="wave" /></div>
                                                            </div>
                                                            <div className='mr-5 mt-3'>
                                                                <Skeleton variant="text" width={200} height={17} animation="wave" />
                                                                <Skeleton variant="text" width={200} height={17} animation="wave" />
                                                                <Skeleton variant="text" width={200} height={17} animation="wave" />
                                                            </div>
                                                            <div className='row no-gutters mt-4'>
                                                                <div className='col-6 p-1'>
                                                                    <span style={{ fontSize: 30 }}><b><Skeleton variant="rect" width={40} height={35} animation="wave" /></b></span>
                                                                    <div style={{ fontSize: 13, color: 'red', fontWeight: 'lighter' }}><Skeleton variant="text" width={90} height={17} animation="wave" /></div>
                                                                </div>
                                                                <div className='col-6 p-1'>
                                                                    <span style={{ fontSize: 30 }}><b><Skeleton variant="rect" width={40} height={35} animation="wave" /></b></span>
                                                                    <div style={{ fontSize: 13, color: 'red', fontWeight: 'lighter' }}><Skeleton variant="text" width={90} height={17} animation="wave" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <Tabs style={{ display: 'block', fontSize: 15, }}>
                            <Tab eventKey="versions" title="Version History" >
                                <div className="repo-versions shadow">
                                    <div className="col-md-12 p-3">
                                        <div className="version col-12 p-2">
                                            <div className="row right-container">
                                                <div className="col-10">
                                                    <div className="col-10">
                                                        <div className="row mt-2">
                                                            <div className="col-0 mr-0">
                                                                <Checkbox disabled={true} />
                                                            </div>
                                                            <div className="col-8 mt-2">
                                                                <Skeleton variant="text" width={200} height={10} animation="wave" />
                                                                <br />
                                                                <div className='row no-gutters'>
                                                                    <div className='col p-0'>
                                                                        <i className="material-icons align-middle" style={{ fontSize: "small" }}>access_time</i>
                                                                    </div>
                                                                    <div className='col pl-5'>
                                                                        <Skeleton variant="text" width={70} height={10} animation="wave" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="version col-12 p-2 mt-2">
                                            <div className="row right-container">
                                                <div className="col-10">
                                                    <div className="col-10">
                                                        <div className="row mt-2">
                                                            <div className="col-0 mr-0">
                                                                <Checkbox disabled={true} />
                                                            </div>
                                                            <div className="col-8 mt-2">
                                                                <Skeleton variant="text" width={200} height={10} animation="wave" />
                                                                <br />
                                                                <div className='row no-gutters'>
                                                                    <div className='col p-0'>
                                                                        <i className="material-icons align-middle" style={{ fontSize: "small" }}>access_time</i>
                                                                    </div>
                                                                    <div className='col pl-5'>
                                                                        <Skeleton variant="text" width={70} height={10} animation="wave" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="version col-12 p-2 mt-2">
                                            <div className="row right-container">
                                                <div className="col-10">
                                                    <div className="col-10">
                                                        <div className="row mt-2">
                                                            <div className="col-0 mr-0">
                                                                <Checkbox disabled={true} />
                                                            </div>
                                                            <div className="col-8 mt-2">
                                                                <Skeleton variant="text" width={200} height={10} animation="wave" />
                                                                <br />
                                                                <div className='row no-gutters'>
                                                                    <div className='col p-0'>
                                                                        <i className="material-icons align-middle" style={{ fontSize: "small" }}>access_time</i>
                                                                    </div>
                                                                    <div className='col pl-5'>
                                                                        <Skeleton variant="text" width={70} height={10} animation="wave" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 compare">
                                            <div className='col-md-12 p-2 mt-2'>
                                                <div className="row pr-3">
                                                    <Button disabled={true} className='compare-btn' variant='outlined'>Compare</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RepoAnalysisSkeleton;