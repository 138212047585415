import React, { Component, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { colorByPercentage } from './helper/helper';

const useStyles = makeStyles((theme) => ({
    donutStyles : {
        marginTop: '7%', 
        marginLeft: '7%', 
        width: '110%', 
        height: '110%'
    }
}));

function RepoAnalysisCodecodeCoverageDonut(props) {
    const classes = useStyles();
    const uncovered = 100 - props.data.codeCoverage.percentage;
    const donutData = {

        labels: [
            'Code Covered',
            'Uncovered'
        ],

        datasets: [{
            data: [props.data.codeCoverage.percentage, uncovered],
            backgroundColor: [
                colorByPercentage(props.data.codeCoverage.percentage)
            ],
        }],

    };

    const options = {
        cutoutPercentage: 85,
        legend: {
            display: false,
        },
    };

    const donutStyles = { marginTop: '7%', marginLeft: '7%', width: '110%', height: '110%' }

    const plugins = [{
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 100).toFixed(2);
            ctx.font = "bold " + fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            var percentage = props.data.codeCoverage.percentage + "%",
                percentageX = Math.round((width - ctx.measureText(percentage).width) / 2),
                percentageY = height / 2.4;
            ctx.fillStyle = 'black';
            ctx.fillText(percentage, percentageX, percentageY);
            fontSize = (height / 250).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.color = 'gray'
            ctx.textBaseline = "top";
            var percText = "Code Coverage",
                percTextX = Math.round((width - ctx.measureText(percText).width) / 2),
                percTextY = height / 1.7
            ctx.fillStyle = 'gray';
            ctx.fillText(percText, percTextX, percTextY);
            ctx.save();
        }
    }]

    return (
        <div className={classes.donutStyles}>
            <Doughnut data={donutData} options={options} plugins={plugins} />
        </div>
    )
}

export default RepoAnalysisCodecodeCoverageDonut;