import React from 'react';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import { qualityGateColor } from '../helper/helper';

function QualityGateStatusDoughnutChart(props) {

    let data = [];
    props.data.map((obj) => {
        var randomColor = qualityGateColor(obj.key)
        let insert = {
            color: randomColor,
            title: obj.key,
            value: obj.count,
        };
        data.push(insert);
    });

    return (
        <div >
            <ReactMinimalPieChart
                animate={false}
                style={{ height: '350px' }}
                animationDuration={500}
                animationEasing="ease-out"
                background="#bfbfbf"
                data={data}
                labelPosition={100}
                lengthAngle={360}
                lineWidth={100}
                paddingAngle={0}
                radius={50}
                startAngle={0}
                viewBoxSize={[100, 100]}
            />
        </div>
    );
}

export default QualityGateStatusDoughnutChart;