import React, { Component } from 'react';
import './../RepoAnalysis-modals/modal.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RepoAnalysisListLoader from '../RepoAnalysisListLoader';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';

const useStyles = theme => ({
    iconList: {
        marginBottom: 5
    },
	statusIcons: {
		marginRight: 3
	},
	closeIcons: {
		color: "#fff",
		 backgroundColor: "#D30B34", 
		 borderRadius: 12, 
	     marginRight: 6,
		padding: 2
	},
	warningIcons:{
		marginRight: 6
	},
	statusHeader: {
		color : "black",
		fontWeight : 800,
		paddingBottom : "10px",
		paddingTop : "20px"
	},
	quatlityStatusHeader: {
		position: "relative",
		top:10
	}
})

class StatusDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
		this.handleClose = this.handleClose.bind(this);
	}

	handleClose = () => {
		this.props.handleClose();
	}
	render() {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.editModal}
				aria-labelledby="simple-dialog-title">
				<DialogTitle id="customized-dialog-title" className={classes.quatlityStatusHeader}>
					<b>Quality Gate Status Details :</b>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
					<List component="nav" aria-label="main mailbox folders">
						<li className={ classes.iconList }><i className={`${classes.statusIcons} material-icons align-middle text-success`} style={{fontSize: 24}}>stars</i><b>&ensp;Passed:</b>&ensp;All the analysis reports(Bugs, Duplication and Security) are passed</li>
						<li className={ classes.iconList }><i className={`${classes.warningIcons} material-icons align-middle text-warning`}>warning</i><b> Warning:</b>&ensp;At least one analysis report is Warning</li>
						<li className={ classes.iconList }><CloseIcon  className={classes.closeIcons}/><b>&ensp;Failed:</b>&ensp;At least one analysis report is Failed</li>
					</List>
                    
					<Typography className={classes.statusHeader} variant='h6'>Status Details :</Typography>
					<List component="nav" aria-label="main mailbox folders">
						<li className={ classes.iconList }><AddCircleIcon className={classes.statusIcons} style={{ color: "#007bff" }}/><b>New:</b>&ensp;When the repo scan is added for the review and ready for analyzing.</li>
						<li className={ classes.iconList }><div style={{ marginRight: 3, display: "inline" }}><RepoAnalysisListLoader/></div><b>In Progress:</b>&ensp;After requesting the repo scan when analyzer started to analyze the repository.</li>
						{/* <li className={ classes.iconList }><i className={`${classes.statusIcons} material-icons align-middle text-success`}>done_all</i><b>Completed:</b>&ensp;Full scan review has been completed with statistics reports.</li> */}
						<li className={ classes.iconList }><i className={`${classes.statusIcons} material-icons align-middle text-danger`}>error</i><b>Failed:</b>&ensp;Full scan review has not been completed, Due to technical fault.</li>
					</List>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color="secondary" >
						close
                    </Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(useStyles)(StatusDetails);