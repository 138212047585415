import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import RepoAnalysisSecurityDetail from '../RepoAnalysis/RepoAnalysisSecurityDetail';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: '15px'
    },
    header: {
        fontWeight: 'bold',
        padding: '10px 10px 10px 8px ',
    },
    paper: {
        width: '98%',
        marginTop: 5,
        marginLeft: 10,
        borderRadius: 5,
        "& .MuiTableCell-head": {
            fontSize: 13,
            fontWeight: 600
        }
    },
    container: {
        maxHeight: 500,
    },
    valList: {
        padding: "0px !important"
    },
    summaryHeader: {
        fontWeight: 600
    },
    cursorPointer: {
        color: "blue"
    },
    summaryRoot: {
        width: "97%",
        marginRight: 18,
        marginLeft: 18
    },
    highestSeverityCriticalStyle: {
        padding: "4px 10px",
        backgroundColor: "black",
        color: "#fff",
        fontWeight: "bold"
    },
    highestSeverityHighStyle: {
        padding: "4px 10px",
        backgroundColor: "#d16965",
        fontWeight: "bold"
    },
    highestSeverityMediumStyle: {
        padding: "4px 10px",
        backgroundColor: "#ec971f",
        fontWeight: "bold"
    },
    highestSeverityLowStyle: {
        padding: "4px 10px",
        backgroundColor: "#f2cc0c",
        fontWeight: "bold"
    },
    greyText: {
        color: "grey"
    },
    stickyHeader: {
        position: 'sticky'
    }

}));

const columns = [
    // {
    //     id: 'vflag',
    //     label: 'S.No',
    // },
    {
        id: 'dependency',
        label: 'Dependency',
    },
    {
        id: 'vulnerabilityIds',
        label: 'Vulnerability Ids',
    },
    {
        id: 'rootPackage',
        label: 'rootPackage',
    },
    {
        id: 'highestSeverity',
        label: 'Highest Severity',
    },
    {
        id: 'CVECount',
        label: 'CVE count',
    },
    {
        id: 'confidence',
        label: 'Confidence',
    },
    {
        id: 'evidenceCount',
        label: 'Evidence count',
    },
];

const GET_SECURITY = gql`
    query fullScanSecurityById($repoScanResponseId: Int!)
    {   fullScanSecurityById(repoScanResponseId: $repoScanResponseId)
        { 
            repoScanResponseId
            summary {
                dependency
                vulnerabilityIds
                rootPackage
                highestSeverity
                CVECount
                confidence
                evidenceCount
            }
            fullSecurityReport {
                repoScanRequestId
                report {
                    reportSchema
                    scanInfo {
                        engineVersion
                        datasource {
                                name
                                timestamp
                            }
                    }
                    projectInfo {
                        name
                        reportDate
                        credits {
                            NVD
                            NPM
                            RETIREJS
                            OSSINDEX
                        }
                    }
                    dependencies {
                        isVirtual
                        fileName
                        filePath
                        md5
                        sha1
                        sha256
                        description
                        license
                        evidenceCollected {
                            vendorEvidence {
                                type
                                confidence
                                source
                                name
                                value
                            }
                            productEvidence {
                                type
                                confidence
                                source
                                name
                                value
                            }
                            versionEvidence {
                                type
                                confidence
                                source
                                name
                                value 
                            }
                        }
                        packages {
                            id
                            confidence
                            url
                        }
                        vulnerabilityIds {
                            id
                            confidence
                            url
                        }
                        vulnerabilities {
                            source
                            name
                            severity
                            cwes
                            description
                            notes
                        }
                    }
                }
            }
        }  
    }
`;

function RepoAnalysisSecurityTreeView(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [detailPage, setDetailPage] = React.useState(false);
    const [dependencyData, setdependencyData] = React.useState("");
    const [vdata, setVdata] = React.useState("");
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleOpenDetailPage = (e, clumn, value) => {
        e.preventDefault();
        setDetailPage(true);
        if (clumn === 'dependency') {
            const filtered = vdata?.fullScanSecurityById?.fullSecurityReport?.report?.dependencies?.find(dep => {
                return dep.fileName === value;
            });
            setdependencyData(filtered)
        }
    };

    const handleCloseDetailPage = () => {
        setDetailPage(false);
    };

    const getSeverityStyle = (value) => {
        switch (value) {
            case 'CRITICAL':
                return classes.highestSeverityCriticalStyle;
            case 'HIGH':
                return classes.highestSeverityHighStyle;
            case 'MEDIUM':
                return classes.highestSeverityMediumStyle;
            case 'LOW':
                return classes.highestSeverityLowStyle;
            default:
                return ""
        }

    }

    return (
        <Paper className={classes.root} elevation={0}>
            <Query query={GET_SECURITY} fetchPolicy="no-cache" variables={{ repoScanResponseId: props.repoScanResponseId }}>
                {({ loading, error, data, refetch }) => {
                    if (data) {
                        setVdata(data)
                    }
                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>;
                    return (
                        <>
                            <Paper className={classes.summaryRoot} elevation={0}>
                                <Typography variant='h5' className={classes.header}>Summary</Typography>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader className={classes.stickyHeader}>
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        style={{ minWidth: '170', textAlign: column.id === "highestSeverity" ? "center" : "" }}
                                                    >
                                                        <Typography className={classes.summaryHeader}>{column.label}</Typography>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.fullScanSecurityById?.summary
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((summary, index) => {
                                                    return (
                                                        <TableRow key={index} hover tabIndex={-1}>
                                                            {
                                                                columns.map((column, indKey) => {
                                                                    const value = summary[column.id];
                                                                    return (
                                                                        <TableCell key={indKey} style={{ textAlign: column.id === "highestSeverity" ? "center" : "" }}>
                                                                            <Typography
                                                                                style={{ cursor: column.id === "dependency" ? "pointer" : "" }}
                                                                                variant='p'
                                                                                className={column.id === "dependency" ? classes.cursorPointer : ''}
                                                                                onClick={column.id === "dependency" ? (e) => handleOpenDetailPage(e, column.id, value) : ""}
                                                                            >
                                                                                {
                                                                                    column.id === "vulnerabilityIds"
                                                                                        ?
                                                                                        <List>
                                                                                            {
                                                                                                value?.map((val, lindex) => (
                                                                                                    <ListItem key={lindex} className={classes.valList}>
                                                                                                        {val}
                                                                                                    </ListItem>
                                                                                                ))
                                                                                            }
                                                                                        </List>
                                                                                        :
                                                                                        column.id === "dependency"
                                                                                            ?
                                                                                            <span>
                                                                                                {
                                                                                                    value.split(":").map((ind, bkey) => (
                                                                                                        <span className={bkey === 0 ? classes.greyText : classes.blueText}>{ind} {bkey === 0 ? "-" : ""}</span>
                                                                                                    ))
                                                                                                }
                                                                                            </span>
                                                                                            :
                                                                                            <span className={column.id === "highestSeverity" ? getSeverityStyle(value) : ""}>{value}</span>
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                    );
                                                                }
                                                                )
                                                            }
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {
                                    data?.fullScanSecurityById?.summary?.length
                                        ?
                                        <TablePagination
                                            rowsPerPageOptions={[10, 20, 30]}
                                            component="div"
                                            count={data?.fullScanSecurityById?.summary?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                        :
                                        ""
                                }

                                {detailPage && <RepoAnalysisSecurityDetail showModal={detailPage} data={dependencyData} onClose={handleCloseDetailPage} />}
                            </Paper>
                        </>
                    )
                }}
            </Query>
        </Paper>
    );
}
export default RepoAnalysisSecurityTreeView