import React, { Component, useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import RepoAnalysisUserDetails from './RepoAnalysisUserDetails';
import RepoAnalysisSourceCodeButton from './RepoAnalysisSourceCodeButton';
import RepoAnalysisCodeCoverageDonut from './RepoAnalysisCodeCoverageDonut';
import RepoAnalysisFilesDetails from './RepoAnalysisFilesDetails';

function RepoAnalysisDetail(props) {
    return (
        <Paper elevation={0} style={{ fontSize: 15, borderRadius: 15 }}>
            <Grid container>
                <Grid item xs={7}>
                    <Grid container className='mr-3 ml-3'>
                        <Grid item className='m-2 mt-4'>
                            <RepoAnalysisUserDetails user={props.reqData.requestedUser} />
                        </Grid>
                    </Grid>
                    <RepoAnalysisFilesDetails data={props.resData} />
                    <Grid container className='mr-3 ml-3' >
                        <Grid item className='ml-3'>
                            <RepoAnalysisSourceCodeButton
                                scanId={props.scanId}
                                reqData={props.reqData}
                                resData={props.resData}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    {props.resData?.codeCoverage ? <RepoAnalysisCodeCoverageDonut data={props.resData} /> : ""}
                </Grid>
            </Grid>
        </Paper>

    )
}

export default RepoAnalysisDetail;