import React from 'react';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ReviewByRepoTable from './ReviewByRepoTable';
import ReviewByRepoTreeMap from './ReviewByRepoTreeMap';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { LocalToUTCTime, LocalDate } from './../../../../core/service/timezone';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from "@material-ui/core/styles";
import TableChartIcon from '@material-ui/icons/TableChart';
import InputLabel from '@material-ui/core/InputLabel';
import PdfExportIcon from '../../PdfExport/PdfExportIcon';

var user = JSON.parse(localStorage.getItem('users'));
var snackbar = false;

const useStyles = makeStyles(theme => ({
    snackbar: {
        "& .MuiSnackbarContent-root": {
            backgroundColor: '#dc3545',
            fontWeight: 800
        }
    },
    iconButton: {
        size: "small",
        ariaLabel: "close",
        color: "inherit"
    },
    closeIcon: {
        fontSize: "small"
    }
}));

const REVIEWS_BY_REPO = gql`
query reviewStats($startDate: Long!, $endDate: Long!, $projectGroupId: Long!) {
    reviewStats(startDate: $startDate, endDate: $endDate, projectGroupId: $projectGroupId) {
      repoName
      noOfReviews
      numOfLinesAdded
    }
  }
`;

function ReviewsByRepository(props) {

    const classes = useStyles();
    const [viewFlag, setViewFlag] = React.useState(false);
    const [dataSet, setDataSet] = React.useState([]);
    const [groupByDateFilter, setGroupByDateFilter] = React.useState(null);
    const [dateLabel, setDateLabel] = React.useState('Today');
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const { error, data, loading, refetch } = useQuery(REVIEWS_BY_REPO, {
        variables: {
            startDate: startDate ? LocalToUTCTime(startDate, 'x') : LocalDate(),
            endDate: endDate ? LocalToUTCTime(endDate, 'x') : LocalDate(),
            projectGroupId: user.currentProjectGroupId
        }
    });

    if (loading) return (
        <div className="row p-2">
            <div className="col-sm-12 chart-container p-4 bg-white my-0">
                <div className="row mb-4">
                    <div className="col-lg-10">
                        <Skeleton variant="text" width={"150px"} />
                        <Skeleton variant="text" width={"100px"} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                </div>
                <Skeleton variant="rect" width={"100%"} height={"320px"} />
            </div>
        </div>
    );

    if (data && data?.reviewStats?.length !== 0) {
        if (dataSet.length !== 0) {
            setDataSet([]);
        }
        data.reviewStats.map((d) => {
            dataSet.push({ x: d.repoName, y: d.numOfLinesAdded, z: d.noOfReviews })
        });
    }

    const getDateOptions = (picker) => {
        var seletedDate = '';
        if (picker.startDate.format('YYYY-MM-DD') === picker.endDate.format('YYYY-MM-DD')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }
        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    const ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    const reviewByDate = (event, picker) => {
        var data = getDateOptions(picker);
        if (picker.chosenLabel !== 'Date Range' && data && data.dateObj) {
            var start = moment(data.dateObj.startDate);
            var end = moment(data.dateObj.endDate);

            //Difference in number of days
            var days = end.diff(start, 'days') + 1;
            if (days <= 100) {
                setGroupByDateFilter(null);
                setDateLabel(data.label);
                setStartDate(data.dateObj.startDate.format('YYYY-MM-DD'));
                setEndDate(data.dateObj.endDate.format('YYYY-MM-DD'));
            } else {
                setSnackbarOpen(true)
            }
        } else {
            setGroupByDateFilter(null);
            setDateLabel('Date Range');
            setStartDate(null);
            setEndDate(null);
        }
    }

    const closeSnackbar = (data) => {
        if (data === true) {
            setTimeout(() => {
                setSnackbarOpen(false)
            }, 4000)
        } else if (data === false) {
            setSnackbarOpen(false)
        }

    }

    const viewType = () => {
        setViewFlag(!viewFlag)
    }

    return (
        <div className="row px-2">
            <div className="col-md-12 chart-container p-4 mb-3 ml-0 bg-white">
                <div className="row">
                    <div className="col-lg-9">
                        <h4>Reviews By Repository</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 ml-3 mt-3 pl-0 custom-select-style">
                        <InputLabel>Date Range</InputLabel>
                        <DateRangePicker containerStyles={{ display: "block" }}
                            ranges={ranges}
                            maxDate={moment().format('MM/DD/YYYY')}
                            onApply={(event, picker) => reviewByDate(event, picker)}>
                            <div className="custom-select dateRange">
                                {dateLabel}
                            </div>
                        </DateRangePicker>
                    </div>
                    <div className="col-lg-4 div-icon">
                        {
                            data?.reviewStats?.length !== 0
                            &&
                            <>
                                <IconButton className="button-style active mr-2" onClick={() => viewType()}>
                                    {viewFlag ? <Tooltip title="Grid View" placement="top">
                                        <AppsOutlinedIcon />
                                    </Tooltip> :
                                        <Tooltip title="Chart View" placement="top">
                                            <TableChartIcon />
                                        </Tooltip>}
                                </IconButton>
                                <PdfExportIcon
                                    pdfExport={true}
                                    data={data?.reviewStats}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </>
                        }
                    </div>
                </div>
                {
                    !viewFlag
                        ?
                        <ReviewByRepoTreeMap dataSet={dataSet} data={data?.reviewStats} />
                        :
                        <ReviewByRepoTable data={data?.reviewStats} />
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={snackbarOpen}
                    onClose={() => closeSnackbar(!snackbar)}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <span id="message-id">
                            <ErrorOutlineIcon className='mr-2' />
                            Choose between 100 days.
                        </span>
                    }
                    action={[
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => closeSnackbar(snackbar)}
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    ]}
                />
            </div>
        </div>
    );
}

export default ReviewsByRepository;