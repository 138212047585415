import React from 'react';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SortIcon from '@material-ui/icons/Sort';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import Auth from '../service/auth';
import MyPullRequest from '../../core/header/my-pull-request';
import { loginService } from './../login/services/login-service';
import './header.css';
import { navBar } from './headerService';

const drawerWidth = 200;
const drawerFullWidth = 0;

var user = JSON.parse(localStorage.getItem('users'));
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerFullWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 42,
    },
    appBarShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

export default function PersistentDrawerRight() {
    const matches = useMediaQuery('(max-width:600px)');
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    loginService.getCurrentUser();
    const isActive = (value) => (window.location.pathname.includes(value) ? 'active' : '')
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={"qbot-main-navigation " + classes.root}>
            <CssBaseline />
            <AppBar
                color="inherit"
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    {!open && <div className="p-0 mr-3">
                        <IconButton onClick={handleDrawerOpen} className="float-left">
                            {<SortIcon />}
                        </IconButton>
                    </div>}
                    {open && <div className="p-0 mr-3"><IconButton onClick={handleDrawerClose} className="float-left">
                        {<ChevronLeftIcon />}
                    </IconButton></div>}
                    <Typography variant="h6" noWrap className={classes.title}>
                        <a href={Auth.hasRole(user, ['ROLE_SUPER_ADMIN']) ? "/admin" : Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_ADMIN']) ? "/home" : "/reviews"} style={{ textAnchor: 'none', textDecoration: 'none', color: 'black' }}>
                            <img src="/images/Q.jpg" alt="Logo" width="40" /><span className="nav-header align-middle">Bot</span>
                        </a>
                    </Typography>
                    <MyPullRequest />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="persistent"
                anchor="left"
                open={!matches}
            >
                <List>
                    {
                        (Auth.hasRole(user, ['ROLE_SUPER_ADMIN']))
                            ? navBar.map(data => {
                                if (data.isSuperAdmin) {
                                    return (
                                        <ListItem className={isActive(data.path)} button component={Link} to={data.path} onClick={handleDrawerClose}>
                                            <ListItemIcon>
                                                <Tooltip placement="top" id="placement" title={data.text}>
                                                    <i className="material-icons align-middle">{data.icon}</i>
                                                </Tooltip>
                                            </ListItemIcon>
                                        </ListItem>
                                    )
                                }
                                return null;
                            })
                            : <>
                                {
                                    Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_ADMIN']) &&
                                    <ListItem className={isActive('/home')} button component={Link} to={'/home'} onClick={handleDrawerClose}>
                                        <ListItemIcon>
                                            <Tooltip placement="top" id="placement" title="Home">
                                                <i className="material-icons align-middle">home</i>
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItem>
                                }
                                {
                                    Auth.hasRole(user, ['ROLE_USER', 'ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_APPROVER']) &&
                                    <ListItem className={isActive('/reviews')} button component={Link} to={'/reviews'} onClick={handleDrawerClose}>
                                        <ListItemIcon>
                                            <Tooltip placement="top" id="placement" title="Reviews">
                                                <i className="material-icons align-middle">rate_review</i>
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItem>
                                }
                                {
                                    user.repoScanFlag &&
                                    <ListItem className={isActive('/fullscans')} button component={Link} to={'/fullscans'} onClick={handleDrawerClose}>
                                        <ListItemIcon>
                                            <Tooltip placement="top" id="placement" title="Repository Analysis">
                                                <i className="material-icons align-middle">qr_code_scanner</i>
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItem>
                                }
                                {
                                    Auth.hasRole(user, ['ROLE_USER', 'ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']) &&
                                    <ListItem className={isActive('/users')} button component={Link} to={'/users'} onClick={handleDrawerClose}>
                                        <ListItemIcon>
                                            <Tooltip placement="top" id="placement" title="Users">
                                                <i className="material-icons align-middle">record_voice_over</i>
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItem>
                                }
                                {
                                    Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']) &&
                                    <ListItem className={isActive('/projects')} button component={Link} to={'/projects'} onClick={handleDrawerClose}>
                                        <ListItemIcon>
                                            <Tooltip placement="top" id="placement" title="Projects">
                                                <i className="material-icons align-middle">pie_chart</i>
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItem>
                                }
                                {
                                    Auth.hasRole(user, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) &&
                                    <ListItem className={isActive('/admin')} button component={Link} to={'/admin'} onClick={handleDrawerClose}>
                                        <ListItemIcon>
                                            <Tooltip placement="top" id="placement" title="Admin">
                                                <i className="material-icons align-middle">dashboard</i>
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItem>
                                }
                            </>
                    }
                    <div className="wiki-nav mini">
                        <ListItem className={isActive('/wiki')} button component={Link} to="/wiki" onClick={handleDrawerClose}>
                            <ListItemIcon><Tooltip placement="top" id="placement" title={'Wiki'}><i className="material-icons align-middle">info</i></Tooltip></ListItemIcon>
                        </ListItem>
                    </div>
                </List>
            </Drawer>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <List>
                    {
                        (Auth.hasRole(user, ['ROLE_SUPER_ADMIN']))
                            ? navBar.map(data => {
                                if (data.isSuperAdmin) {
                                    return (
                                        <ListItem className={isActive(data.path)} button component={Link} to={data.path} onClick={handleDrawerClose}>
                                            <ListItemIcon><i className="material-icons align-middle">{data.icon}</i></ListItemIcon>
                                            <ListItemText primary={data.text} />
                                        </ListItem>
                                    )
                                }
                                return null;
                            })
                            : <>
                                {
                                    Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_ADMIN']) &&
                                    <ListItem className={isActive('/home')} button component={Link} to={'/home'} onClick={handleDrawerClose}>
                                        <ListItemIcon><i className="material-icons align-middle">home</i></ListItemIcon>
                                        <ListItemText primary="Home" />
                                    </ListItem>
                                }
                                {
                                    Auth.hasRole(user, ['ROLE_USER', 'ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']) &&
                                    <ListItem className={isActive('/reviews')} button component={Link} to={'/reviews'} onClick={handleDrawerClose}>
                                        <ListItemIcon><i className="material-icons align-middle">rate_review</i></ListItemIcon>
                                        <ListItemText primary="Reviews" />
                                    </ListItem>
                                }
                                {
                                    user.repoScanFlag &&
                                    <ListItem className={isActive('/fullscans')} button component={Link} to={'/fullscans'} onClick={handleDrawerClose}>
                                        <ListItemIcon><i className="material-icons align-middle">qr_code_scanner</i></ListItemIcon>
                                        <ListItemText primary="Repository Analysis" />
                                    </ListItem>
                                }
                                {
                                    Auth.hasRole(user, ['ROLE_USER', 'ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']) &&
                                    <ListItem className={isActive('/users')} button component={Link} to={'/users'} onClick={handleDrawerClose}>
                                        <ListItemIcon><i className="material-icons align-middle">record_voice_over</i></ListItemIcon>
                                        <ListItemText primary="Users" />
                                    </ListItem>
                                }
                                {
                                    Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']) &&
                                    <ListItem className={isActive('/projects')} button component={Link} to={'/projects'} onClick={handleDrawerClose}>
                                        <ListItemIcon><i className="material-icons align-middle">pie_chart</i></ListItemIcon>
                                        <ListItemText primary="Projects" />
                                    </ListItem>
                                }
                                {
                                    Auth.hasRole(user, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) &&
                                    <ListItem className={isActive('/admin')} button component={Link} to={'/admin'} onClick={handleDrawerClose}>
                                        <ListItemIcon><i className="material-icons align-middle">dashboard</i></ListItemIcon>
                                        <ListItemText primary="Admin" />
                                    </ListItem>
                                }
                            </>
                    }
                    <div className="wiki-nav large">
                        <ListItem className={isActive('/wiki')} button component={Link} to="/wiki" onClick={handleDrawerClose}>
                            <ListItemIcon><i className="material-icons align-middle">info</i></ListItemIcon>
                            <ListItemText primary={"Wiki Page"} />
                        </ListItem>
                    </div>
                </List>
            </Drawer>
        </div>
    );
}