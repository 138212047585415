import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import RepoAnalysisStashModal from './RepoAnalysisTreeView/RepoAnalysisStashModal';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { Tooltip, Typography } from '@material-ui/core';
import ExcludeFlieList from './ExcludeFlieList';

const useStyles = makeStyles({
    button: {
        textTransform: "none",
        width: '144px',
        fontSize: '15px',
        backgroundColor: '#3677fe',
        '&:hover': {
            background: "#3677fe",
        },
        color: 'white',
        borderRadius: '7px',
        height: '35px'
    },
    excludeIcon: {
        paddingLeft: '10px',
        fontSize: '30px',
        color: '#fc8003',
        cursor: 'pointer'
    }
})

function RepoAnalysisSourceCodeButton(props) {
    const classes = useStyles();
    const [treeModal, showTreeModal] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleTreeView = (filePath) => {
        showTreeModal(!false);
    }

    const handleTreeViewClose = () => {
        showTreeModal(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    return (
        <div>
            <Grid container>
                <Grid item className='mt-md-4' xs={12}>
                    <Button
                        className={classes.button}
                        disabled={props?.reqData?.status === "Completed" ? false : true}
                        variant="contained"
                        onClick={handleTreeView}
                    >
                        <b>Source Code</b>
                    </Button>
                </Grid>
                <Grid item className='mt-2 mb-3' xs={12} style={{ color: '#536383' }}>
                    <Typography variant='body-1'> Click to check the source code and comments.</Typography>
                    {
                        props.resData?.excludeFilePath?.length !== 0
                        &&
                        props.resData?.excludeFilePath !== null
                        &&
                        <Tooltip title='Click to view exclude file path' placement="top">
                            <AnnouncementIcon
                                className={classes.excludeIcon}
                                onClick={handleDialogOpen}
                            />
                        </Tooltip>
                    }
                </Grid>
            </Grid>
            <RepoAnalysisStashModal showModal={treeModal} scanId={props.scanId} onClose={handleTreeViewClose} />
            {dialogOpen && <ExcludeFlieList dialogOpen={dialogOpen} handleDialogClose={handleDialogClose} resData={props.resData?.excludeFilePath} />}
        </div>

    )
}

export default RepoAnalysisSourceCodeButton;