import React, { Component } from "react";
import { Button } from "react-bootstrap";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import ReviewServices from "./services/RepoAnalysis-services";
import AddRepository from "./RepoAnalysisGridView/AddRepository";
import { UTCtoLocalTime } from "../../core/service/timezone";
import "./RepoAnalysis.css";
import StatusDetails from "./RepoAnalysis-modals/RepoAnalysis-StatusDetails-modal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { resetFilterService } from "../../core/login/services/reset-filter";
import preferenceService from "../../core/service/preference";
import Auth from "../../core/service/auth";
import RepoAnalysisStatusPicker from "../RepoAnalysis/RepoAnalysis-modals/RepoAnalysis.StatusPicker";
import RepoAnalysisStatusMessage from "./RepoAnalysis-modals/RepoAnalysisStatusMessage";
import Grid from "@material-ui/core/Grid";
import RepoAnalysisListLoader from "./RepoAnalysisListLoader";
import Snackbars from "../../core/error/Snackbars";
import FullScanSearch from "./RepoAnalysisGridView/FullScanFilters/FullScanSearch";
import FullScanDatePicker from "./RepoAnalysisGridView/FullScanFilters/FullScanDatePicker";
import QualityGateStatusPicker from "./QualityGateStatistics/QualityGateStatusPicker"
import PdfExport from "./QualityGateStatistics/PdfExport";
const style = {
  border: "none",
};

class GetAllRepoList extends Component {
  projectId;
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.reviewServices = new ReviewServices();
    this.state = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showModal: false,
      showEditModal: false,
      editModal: false,
      showRuleSetModal: false,
      showStatusDetail: false,
      filterRefresh: false,
      isSourceReviewList: true,
      tempClose: false,
      dynamicPageSize: 7,
      reload: false,
      snackBarMsgId: "",
      qualityGate: "",
      columnDefs: [
        {
          title: "Scan ID",
          field: "id",
          filtering: true,
          filterCellStyle: style,
          headerStyle: style,
          filterPlaceholder: "search",
          defaultFilter: preferenceService.getValue("FullScanGridValues").id,
          filterComponent: (props) => <FullScanSearch {...props} />,
          render: (params) => {
            var url = "/fullscans-dashboard/" + params.id;
            var iconToShow;
            if (params.status) {
              if (params.status === "New") {
                iconToShow = (
                  <i
                    className="material-icons align-middle"
                    style={{ float: "left", fontSize: 24, color: "#007bff" }}
                    title="New"
                  >
                    add_circle
                  </i>
                );
              } else if (params.status === "In Progress") {
                iconToShow = <RepoAnalysisListLoader />;
              } else if (params.status === "Completed" && params?.qualityGate !== null) {
                if (params?.qualityGate === "Passed") {
                  iconToShow = (
                    <i
                      className="material-icons align-middle text-success"
                      style={{ fontSize: 24 }}
                      title={"Scan " + params.status + ' (' + "Quality Gate Passed" + ')'}
                    >
                      stars
                    </i>
                  );
                } else if (params?.qualityGate === "Warning") {
                  iconToShow = (
                    <i
                      className="material-icons align-middle text-warning"
                      title={"Scan " + params.status + ' (' + "Quality Gate Warning" + ')'}
                    >
                      warning
                    </i>
                  );
                } else {
                  iconToShow = (
                    <i
                      className="material-icons align-middle"
                      title={"Scan " + params.status + ' (' + "Quality Gate Failed" + ')'}
                      style={{
                        backgroundColor: "#D30B34",
                        color: "#fff",
                        borderRadius: 25,
                        marginRight: 4,
                        fontSize: "20px",
                      }}
                    >
                      close
                    </i>
                  );
                }
              } else if (params.status === "Failed") {
                iconToShow = (
                  <i
                    className="material-icons align-middle text-danger"
                    title={params.status}
                  >
                    error
                  </i>
                );
              }
            }
            return (
              <div>
                {iconToShow}

                {params.status === "New" ? (
                  <>
                    <div className="mt-1 ml-1" style={{ float: "left" }}>
                      {params.id}
                    </div>
                  </>
                ) : (
                  <Link
                    to={{ pathname: url, state: { fromFullScanGrid: true } }}
                  >
                    {params.id}
                  </Link>
                )}
              </div>
            );
          },
        },
        {
          title: "Repo Name",
          field: "repoName",
          filterCellStyle: style,
          headerStyle: style,
          filterPlaceholder: "search",
          filtering: true,
          defaultFilter:
            preferenceService.getValue("FullScanGridValues").repoName,
          filterComponent: (props) => <FullScanSearch {...props} />,
          render: (params) => {
            if (params.isRepoDeleted) {
              return (
                <Tooltip
                  placement="top"
                  title={params.isRepoDeleted && "Repo Deleted"}
                >
                  <div className="crossed-line">{params.repoName}</div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={params.repoName} key={params.id}>
                  <div>{params.repoName}</div>
                </Tooltip>
              );
            }
          },
        },
        {
          title: "Branch Name",
          field: "branchName",
          filterCellStyle: style,
          headerStyle: style,
          filterPlaceholder: "search",
          filtering: true,
          defaultFilter:
            preferenceService.getValue("FullScanGridValues").branchName,
          filterComponent: (props) => <FullScanSearch {...props} />,
          render: function (params) {
            return (
              <Tooltip title={params.branchName} key={params.id}>
                <div>{params.branchName}</div>
              </Tooltip>
            );
          },
        },
        {
          title: "Quality Gate",
          field: "qualityGate",
          filterCellStyle: style,
          headerStyle: style,
          filterPlaceholder: "qualityGate",
          filtering: true,
          defaultFilter: preferenceService?.getValue("FullScanGridValues")?.qualityGate,
          filterComponent: (props) =>  <QualityGateStatusPicker {...props}/>,
          render: function (params) {
            var qualityGateStatus = params?.qualityGate;
            if (params.status !== "Completed") {
              return qualityGateStatus = null
            } else {
              return qualityGateStatus
            }
          },
        },
        {
          title: "Status",
          field: "status",
          filterCellStyle: style,
          headerStyle: style,
          filterPlaceholder: "search",
          filtering: true,
          defaultFilter:
            preferenceService.getValue("FullScanGridValues").status,
          filterComponent: (props) => <RepoAnalysisStatusPicker {...props} />,
          render: function (params) {
            return (
              <>
                <Grid container>
                  <Grid item>{params.status}</Grid>
                  {params.message && (
                    <Grid item style={{ marginTop: "-2px" }}>
                      &nbsp;
                      <RepoAnalysisStatusMessage data={params.message} />
                    </Grid>
                  )}
                </Grid>
              </>
            );
          },
        },
        {
          title: "Version",
          field: "version",
          filterCellStyle: style,
          headerStyle: style,
          filterPlaceholder: "search",
          filtering: true,
          defaultFilter:
            preferenceService.getValue("FullScanGridValues").version,
          filterComponent: (props) => <FullScanSearch {...props} />,
          render: function (params) {
            return params.version;
          },
        },
        {
          title: "Created On",
          field: "createdOn",
          filterCellStyle: style,
          headerStyle: style,
          filtering: true,
          type: "datetime",
          defaultFilter:
            preferenceService.getValue("FullScanGridValues").createdOn,
          filterComponent: (props) => <FullScanDatePicker {...props} />,
          render: function (params) {
            return UTCtoLocalTime(params.createdOn, "YYYY-MM-DD HH:mm A");
          },
        },
        {
          title: "Modified On",
          field: "modifiedOn",
          filterCellStyle: style,
          headerStyle: style,
          filtering: true,
          type: "datetime",
          defaultFilter:
            preferenceService.getValue("FullScanGridValues").modifiedOn,
          filterComponent: (props) => <FullScanDatePicker {...props} />,
          render: function (params) {
            return params.modifiedOn
              ? UTCtoLocalTime(params.modifiedOn, "YYYY-MM-DD HH:mm A")
              : "";
          },
        },
        {
          title: "Requested User",
          field: "name",
          filterCellStyle: style,
          headerStyle: style,
          filterPlaceholder: "name",
          filtering: true,
          defaultFilter: preferenceService.getValue("FullScanGridValues").name,
          filterComponent: (props) => <FullScanSearch {...props} />,
          render: function (params) {
            return params.requestedUser.name;
          },
        },
      ],
    };
    this.gridApi = null;
    this.onGridReady = this.onGridReady.bind(this);
    this.addNewUrl = this.addNewUrl.bind(this);
    this.showStatusDetail = this.showStatusDetail.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    this.setState({});
  }

  getData(query) {
    let fields = [
      "id",
      "repoName",
      "projectName",
      "status",
      "branchName",
      "version",
      "createdOn",
      "modifiedOn",
      "name",
      "qualityGate"
    ];
    const FullScanGridValues = {};
    if (query && query.filters && query.filters.length > 0) {
      query.filters.forEach((filter) => {
        if (
          filter &&
          filter.column &&
          filter.column.field &&
          filter.column.tableData &&
          filter.column.tableData.filterValue
        ) {
          FullScanGridValues[filter.column.field] =
            filter.column.tableData.filterValue;
          fields.splice(fields.indexOf(filter.column.field), 1);
        }
      });
    }
    fields.forEach((field) => {
      FullScanGridValues[field] = "";
    });
    preferenceService.setValue("FullScanGridValues", FullScanGridValues);
    if (this.props.fromClose && !this.state.snackBarMsgId) {
      query.page = parseInt(localStorage.getItem("fullScanGridQueryPage"));
      query.pageSize = parseInt(
        localStorage.getItem("fullScanGridQueryPageSize")
      );
      this.setState({
        dynamicPageSize: query.pageSize,
      });
      this.props.onFromCloseChange();
    }
    if (this.state.snackBarMsgId) {
      query.page = 0;
    }
    localStorage.setItem("fullScanGridQueryPage", JSON.stringify(query.page));
    localStorage.setItem(
      "fullScanGridQueryPageSize",
      JSON.stringify(query.pageSize)
    );
    if (query.filters.length > 0) {
      this.setState({ filterRefresh: true });
    }
    if (query.filters) {
      const arrayToObject = (filter) =>
        Object.assign(
          {},
          ...filter.map((item) => ({
            [item.column.field]: item.column.tableData.filterValue,
          }))
        );
      const filter = arrayToObject(query.filters);
      return this.reviewServices.getReviewsList(query, filter);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  addNewUrl() {
    this.setState(
      {
        showModal: !this.state.showModal,
      },
      () => {
        if (!this.state.showModal)
          this.tableRef.current && this.tableRef.current.onQueryChange();
      }
    );
  }

  showStatusDetail() {
    if (!this.state.showStatusDetail) {
      this.setState({ showStatusDetail: true });
    }
  }

  handleCancel() {
    this.setState({ showStatusDetail: false });
  }

  resetFilter() {
    resetFilterService.resetForFullScanGrid();
    this.setState({
      columnDefs: this.state.columnDefs,
    });
    if (
      this.tableRef.current &&
      this.tableRef.current.props &&
      this.tableRef.current.props.columns &&
      this.tableRef.current.props.columns.length > 0
    ) {
      this.tableRef.current.props.columns.forEach((column) => {
        if (column.tableData && column.tableData.filterValue) {
          column.tableData.filterValue = "";
        }
      });
    }
    if (this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }

  filterRefresh() {
    this.setState({ filterRefresh: false });
  }

  handleFilter(data) {
    this.setState({ snackBarMsgId: data });
    setTimeout(() => {
      this.setState({ snackBarMsgId: "" });
    }, 3000);
  }

  render() {
    var user = JSON.parse(localStorage.getItem("users"));
    const { classes } = this.props;
    return (
      <div className="post-list">
        <div>
          <div
            className="ag-theme-material"
            style={{
              width: "100%",
              paddingBottom: "30px",
            }}
          >
            <div className="row">
              <div className="review-list-container">
                <Button
                  className="addbutton p-0 pr-3 btn-link text-primary bg-white border-0 shadow-none"
                  onClick={() => {
                    this.tableRef.current &&
                      this.tableRef.current.onQueryChange();
                  }}
                >
                  <Tooltip title="Refresh" placement="top">
                    <i className="material-icons text-primary">refresh</i>
                  </Tooltip>
                </Button>
                <Button
                  className="addbutton p-0 pr-4 btn-link text-primary bg-white border-0 shadow-none"
                  onClick={() => {
                    this.setState({ showStatusDetail: true });
                  }}
                >
                  <Tooltip title="Status View" placement="top">
                    <VisibilityIcon className="material-icons align-middle">
                      status
                    </VisibilityIcon>
                  </Tooltip>
                </Button>
                {resetFilterService.checkFilterPresentInFullScanGrid() && (
                  <Button
                    className="addbutton p-0 pr-4 btn-link text-primary bg-white border-0 shadow-none"
                    onClick={() => this.resetFilter()}
                  >
                    <Tooltip title="Reset Filter" placement="top">
                      <i className="material-icons align-middle text-danger">
                        restore
                      </i>
                    </Tooltip>
                  </Button>
                )}
                {!resetFilterService.checkFilterPresentInFullScanGrid() && (
                  <Button
                    className="addbutton p-0 pr-4 btn-link text-primary bg-white border-0 shadow-none"
                    onClick={() => this.resetFilter()}
                  >
                    <Tooltip title="Reset Filter" placement="top">
                      <i className="material-icons align-middle text-primary">
                        restore
                      </i>
                    </Tooltip>
                  </Button>
                )}
                {Auth.hasRole(user, [
                  "ROLE_EXECUTIVE",
                  "ROLE_MANAGER",
                  "ROLE_ADMIN",
                ]) && (
                    <Button
                      className="addbutton p-0 pr-4 btn-link text-primary bg-white border-0 shadow-none"
                      onClick={() => {
                        this.addNewUrl();
                      }}
                    >
                      <Tooltip title="Add Repository" placement="top">
                        <i className="material-icons align-middle">add_circle</i>
                      </Tooltip>
                    </Button>
                  )}
              </div>
            </div>
            <MaterialTable
              key={"allReviews"}
              title="Reviews"
              tableRef={this.tableRef}
              columns={this.state.columnDefs}
              data={(query) => this.getData(query)}
              options={{
                filtering: true,
                toolbar: false,
                headerStyle: {
                  padding: "5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "black",
                  border: "none",
                },
                pageSize: this.state.dynamicPageSize,
                actionsColumnIndex: -1,
                pageSizeOptions: [7, 10, 20, 30],
              }}
            />
            <PdfExport pdfExport={true}/>
            {this.state.snackBarMsgId && (
              <Snackbars
                status="success"
                autoHideDuration={3000}
                message={
                  "RepoScan Request has been created Successfully. Scan Id " +
                  this.state.snackBarMsgId
                }
                showSnackbar={true}
                closeSnackBar={false}
              />
            )}
            {this.state.showModal && (
              <AddRepository
                showModal={this.state.showModal}
                onUpdate={this.addNewUrl}
                refresh={() =>
                  this.tableRef.current && this.tableRef.current.onQueryChange()
                }
                history={this.props.history}
                filterEvent={this.handleFilter}
              />
            )}
            {/* {
                            this.state.showModal && 
                            <FullScanAnalysis
                                showModal={this.state.showModal}
                                onUpdate={this.addNewUrl}
                                refresh={() => this.tableRef.current && this.tableRef.current.onQueryChange()}
                            />
                        } */}
            <StatusDetails
              editModal={this.state.showStatusDetail}
              handleClose={this.handleCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GetAllRepoList;
