import React, { Component } from 'react';

import TreeBase from './components/TreeBase';
import DiffView from './../Index';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

var nodeInfo = [];

class TreeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: null
        }
        this.getTreeObject = this.getTreeObject.bind(this);
        this.updateFile = this.updateFile.bind(this);
    }

    getTreeObject() {
        var paths = this.props.response ? this.props.response.commentsByFilePath.map(data => data) : [];
        var result = [];
        var level = { result };
        paths.forEach((path, index) => {
            path.filePath.split('/').reduce((r, name, i, a) => {
                if (!r[name]) {
                    nodeInfo.push(name);
                    r[name] = { result: [] };
                    path.type = this.props.data[index] ? this.props.data[index].type : '';
                    if (r.result !== undefined) {
                        r.result.push({ name, children: r[name].result, path: path });
                    }
                }
                return r[name];
            }, level)
        })

        if (this.props.filePath) {
            var currectFile = [];
            currectFile = this.props.response && this.props.response.commentsByFilePath && this.props.response.commentsByFilePath.filter(data => {
                return data.filePath === this.props.filePath
            });
            this.comments = {
                comments: currectFile.length && currectFile[0].comments,
                diffUrl: this.props.reviewUrl,
                filePath: this.props.filePath
            }
        } else {
            this.comments = {
                comments: result[0].path.comments,
                diffUrl: this.props.reviewUrl,
                filePath: result[0].path.filePath
            }
        }
        return result;
    }

    updateFile(data) {
        data.path.diffUrl = this.props.reviewUrl;
        this.setState({
            comments: data.path
        })
    }

    render() {
        return (
            <div className="row m-0 p-0 tree-view">
                <div className="file-list p-0 fit-hight overflow-auto" style={{ width: this.state.expand ? '10%' : '25%', transition: '0.3s all' }}>
                    <div className="head bg-white w-100 p-2 position-sticky sticky-top">
                        <h4>Files <span className="float-right align-middle" onClick={() => { this.setState({ expand: !this.state.expand }) }} style={{ transform: this.state.expand ? 'rotate(0deg)' : 'rotate(180deg)', transition: '0.3s all' }}><DoubleArrowIcon /></span></h4>
                    </div>
                    <div onClick={() => { this.setState({ expand: false }) }}>
                        <TreeBase data={this.getTreeObject()} node={nodeInfo} updateFile={this.updateFile} />
                    </div>
                </div>
                <div className="file-view p-0 fit-hight" style={{ width: this.state.expand ? '90%' : '75%' }}>
                    <DiffView reviewId={this.props.reviewId} state={this.props.state} stashComments={this.state.comments || this.comments} isTree={true} />
                </div>
            </div>
        );
    }
}

export default TreeView;