import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import { numberToK, qualityGateColor } from '../helper/helper';
import RepoAnalysisStashModal from '../RepoAnalysisTreeView/RepoAnalysisStashModal';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ClearIcon from '@material-ui/icons/Clear';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0),
        borderRadius: 10
    },
    card: {
        maxWidth: 400,
        minHeight: 200,
        margin: `${theme.spacing(0)}px auto`,
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    title: {
        fontWeight: 'bold',
        marginTop: 10,
        fontSize: 23
    },
    totalcount: {
        fontSize: 23,
        fontWeight: 'bold',

    },
    comment: {
        color: '#0076FE',
        fontSize: '13.5px'
    },
    description: {
        minHeight: 80,
        minWidth: 80,
        fontSize: '12px'
    },
    tableData: {
        borderCollapse: 'separate',
        width: '100% !important'
    },
    button: {
        margin: theme.spacing(1),
    },
    headTableData: {
        borderCollapse: 'separate',
        width: '100% !important',
        margin: '0px'
    },
    blocker: {
        color: '#fff !important',
        backgroundColor: '#D30B34 !important',
        borderRadius: 25,
        fontSize: 16,
        padding: 2,
        marginRight: 3
    },
    major: {
        color: '#fff !important',
        backgroundColor: '#D30B34 !important',
        borderRadius: 25,
        fontSize: 16,
        padding: 2,
        marginRight: 3
    },
    warning: {
        color: '#fff !important',
        backgroundColor: '#ffc107 !important',
        borderRadius: 25,
        fontSize: 16,
        padding: 2,
        marginRight: 3
    },
    minor: {
        color: '#fff !important',
        backgroundColor: '#28a745 !important',
        borderRadius: 25,
        fontSize: 16,
        padding: 2,
        marginRight: 3
    },
    error: {
        color: '#fff !important',
        backgroundColor: '#D30B34 !important',
        borderRadius: 25,
        fontSize: 16,
        padding: 2,
        marginRight: 3
    },
    infoIcon: {
        color: '#fff !important',
        backgroundColor: '#3677fe !important',
        borderRadius: 25,
        fontSize: 16,
        padding: 2,
        marginRight: 3
    }
}));

const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            h5: {
                fontSize: [22, "!important"]
            }
        }
    }
});

const category = {
    qualityGate: 'Passed'
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: '#797979',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

function RepoAnalysisReportBugs(props) {
    const classes = useStyles();
    const [treeModal, showTreeModal] = React.useState(false);
    const [type, setType] = React.useState('');

    const handleTreeViewClose = () => {
        showTreeModal(false);
    };

    const handleBugsOpen = () => {
        showTreeModal(!false);
        setType("Bugs")
    }

    return (
        <>
            <Card elevation={0} className={classes.root}>
                <CardActionArea
                    style={{ borderBottom: props.data.status === "Completed" && '2px solid ' + qualityGateColor(props.data.bugsQualityGate) }}
                    className={classes.card}
                    disabled={props.data.status === "Completed" ? false : true}
                    onClick={() => {
                        { props.data.totalComments !== 0 && handleBugsOpen() }
                    }}>
                    <table className={classes.headTableData}>
                        <tr>
                            <td style={{ width: '5%' }}>
                                <i className="material-icons reportIcon" style={{ color: '#D30B34' }}>bug_report</i>
                            </td>
                            <td style={{ width: '65%', paddingBottom: '7px' }}>
                                <ThemeProvider theme={theme}>
                                    <Typography className={classes.title} variant="h5">
                                        {props.data.category}
                                    </Typography>
                                </ThemeProvider>
                            </td>
                            <td style={{ width: props?.severityData.length !== 0 ? '25%' : '7%' }} className='mt-2 pt-2'>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography color="black">Bugs</Typography>
                                            {
                                                props.data.qualityGateStatusRange.map((statusRange, index) => (
                                                    <>
                                                        {
                                                            <Typography>
                                                                <i className="material-icons align-middle" style={{ fontSize: "bold", color: qualityGateColor(statusRange.qualityGateStatus) }}>horizontal_rule</i>
                                                                <span> : {statusRange.qualityGateRange}</span>
                                                            </Typography>
                                                        }
                                                    </>
                                                ))
                                            }
                                        </React.Fragment>
                                    }
                                >
                                    <HelpIcon style={{ color: '#0076fe', marginBottom: '25px' }} />
                                </HtmlTooltip>
                                {
                                    props?.severityData.length !== 0
                                    &&
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography color="black">Severity Percentage</Typography>
                                                {
                                                    props?.severityData?.map((severity, index) => (
                                                        <>
                                                            {
                                                                <Typography>
                                                                    <span>
                                                                        {
                                                                            severity.severityName === 'critical' ? <ArrowUpwardIcon className={classes.blocker} /> :
                                                                                severity.severityName === 'major' ? <ExpandLessIcon className={classes.major} /> :
                                                                                    severity.severityName === 'minor' ? <ArrowDownwardIcon className={classes.minor} /> :
                                                                                        severity.severityName === 'blocker' ? <PriorityHighIcon className={classes.blocker} /> :
                                                                                            severity.severityName === 'error' ? <ClearIcon className={classes.error} /> :
                                                                                                severity.severityName === 'info' ? <PriorityHighIcon className={classes.infoIcon} /> : <PriorityHighIcon className={classes.warning} />
                                                                        }
                                                                    </span>
                                                                    <span>{severity.severityName.charAt(0).toUpperCase() + severity.severityName.slice(1)}</span>
                                                                    <span> : {parseFloat(severity.percentage).toFixed(2)}%</span>
                                                                </Typography>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </React.Fragment>
                                        }
                                    >
                                        <AnnouncementIcon style={{ color: '#0076fe', marginBottom: '25px', marginLeft: '10px' }} />
                                    </HtmlTooltip>
                                }
                            </td>
                        </tr>
                    </table>
                    <Grid container spacing={2} className={classes.description}>
                        <Grid item>
                            <Typography color="textSecondary" component="p">
                                {props.data.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        props.data.status != 'Failed'
                            ?
                            <table className={classes.tableData}>
                                <tr>
                                    <td>
                                        <Typography className={classes.totalcount}>
                                            <b><Tooltip placement="top" id="placement" title={props.data.totalComments}>
                                                <span>{numberToK(props.data.totalComments)}</span>
                                            </Tooltip>
                                            </b>
                                        </Typography>
                                        <Typography className={classes.comment}>
                                            Comments
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.totalcount}>
                                            {props.data.coverage + '%'}
                                        </Typography>
                                        <Typography className={classes.comment} style={{ color: qualityGateColor(props.data.bugsQualityGate) }}>
                                            Quality
                                        </Typography>
                                    </td>
                                </tr>
                            </table>
                            :
                            props.data.status === 'Failed'
                                ?
                                <Grid container spacing={4} className={classes.description} style={{ height: '10px' }}>
                                    <Grid item>
                                        <Typography color="textSecondary" style={{ color: 'red' }} component="p">
                                            At the moment we couldn't process this report, Please try again later.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container spacing={4} className={classes.description}>
                                    <Grid item className='pl-5 ml-4 pr-5 mt-2'>
                                        <Typography color="textSecondary" component="p">
                                            Category not chosen
                                        </Typography>
                                    </Grid>
                                </Grid>
                    }
                </CardActionArea>
                {(props.data.status === "New" || props.data.status === "In Progress") && <LinearProgress />}
                <RepoAnalysisStashModal showModal={treeModal} type={type} scanId={props.scanId} onClose={handleTreeViewClose} />
            </Card>
        </>
    )
}

export default RepoAnalysisReportBugs;