import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorType from '../../../core/error/simpleError';
import ReviewComment from './../../reviews/stashView/components/ReviewComment';
import RepoAnalysisReviewComments from './Components/RepoAnalysisReviewComments';


var lastDestination = 0,
    tempMarker = [],
    marker = [];
class RepoAnalysisDiffView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            commentsList: this.props.comments,
            manualDesc: this.props.comments.description,
            coll: marker,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.hideComment = this.hideComment.bind(this);
        this.addedComments = this.addedComments.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleSeverityFilter = this.handleSeverityFilter.bind(this);
    }

    hideComment(cmt) {
        this.setState({
            [cmt.destination + '' + cmt.source]: false,
        });
    }

    setValue() {
        if (tempMarker.length !== marker.length) {
            marker = tempMarker;
            this.setState({
                coll: marker
            })
        }
        tempMarker = []
    }

    addedComments(data, cmt) {
        var manualCmt = this.props.comments;
        manualCmt[data.addComment.location.lines.begin] ? manualCmt[data.addComment.location.lines.begin].push(data.addComment) : manualCmt[data.addComment.location.lines.begin] = [data.addComment]

        this.setState({
            commentsList: manualCmt,
            [cmt.destination + '' + cmt.source]: false,
        });
    }

    handleClick(value) {
        var copyText = value;
        copyText = copyText.replace(/&amp;/g, '&');
        var textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.getElementById('title-header').appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({ open: true });
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    }

    handleSeverityFilter (severity) {
        this.props.onSeverityFilterValue(severity);
    }

    componentDidMount() {
        this.props.refresh();
    }
    
    
    
    render() {
        if (!this.props.data) return null;
        if (this.props.data.diffs && !this.props.data.diffs.length) {
            return (<div className="p-5">
                <ErrorType type="outDated" size={200}
                    head="No Diff Were Found"
                    content="They may Delete their branch or No changes Were done in our Latest Diff" />
            </div>);
        }
        return (
            <div>
                <div className="container-dl" id="title-header">
                    <div className="diff-overall-container row p-0 m-0">
                        <div className="p-2 pr-3 bg-light min-width-diff">
                            <Paper elevation={2} className="p-3 sticky-div w-100">
                                <Button>Filter: </Button>
                                <Button 
                                    onClick={(e) => this.handleSeverityFilter(null)} 
                                    variant="outlined" 
                                    size="small" 
                                    color="primary" 
                                    style={{ 
                                        marginRight: 5, 
                                        borderRadius: 15,
                                        backgroundColor: this.props.currentSev === null ? "#007bff" : "none",
                                        color: this.props.currentSev === null ? "#fff" : "none",
                                    }}
                                >
                                    All
                                </Button>
                                {
                                    this.props.comments?.severity?.map((sval) => (
                                        <Button 
                                            onClick={(e) => this.handleSeverityFilter(sval)} 
                                            variant="outlined" 
                                            size="small" 
                                            color="primary" 
                                            style={{ 
                                                marginRight: 5, 
                                                borderRadius: 15,
                                                backgroundColor: this.props.currentSev === sval ? "#007bff" : "none",
                                                color: this.props.currentSev === sval ? "#fff" : "none", 
                                            }}
                                        >
                                            { sval }
                                        </Button>
                                    ))
                                }
                            </Paper>
                        </div>
                        <div className="p-2 pr-3 bg-light min-width-diff">
                            <Paper elevation={2} className="p-3 sticky-div w-100">
                                <h4 className="pb-2">File Location</h4>
                                <div className="row">
                                    <div className="col-md-10 col-7">
                                        <Breadcrumbs aria-label="breadcrumb">
                                            {this.props.data.diffs[0] && this.props.data.diffs[0].destination.components.map((data, index) => {
                                                return <Typography key={index} color="textPrimary">{data}</Typography>
                                            })}
                                        </Breadcrumbs>
                                    </div>
                                    <div className="col-md-2 col-5 text-right">
                                        <Tooltip title="Copy Url" placement="top">
                                            <span className="ml-1 file-copy-icon" onClick={(e) => this.handleClick(this.props.data.diffs[0].destination.toString)}><i className="material-icons align-middle">file_copy</i></span>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Paper>
                            {
                                this.state?.manualDesc
                                &&
                                <Typography style={{ textAlign: "center", padding: "15px 0px 0px", color: "#D30B34" }}>Note: { this.state.manualDesc }</Typography>
                            }
                            <br />
                            {this.props.data.status || !this.props.data.diffs[0].hunks 
                                ? <div className="p-5">
                                    <ErrorType type="outDated" size={200}
                                        head={!this.props.data.diffs[0].hunks ? "Unable to view the file" : "Outdated"}
                                        content={!this.props.data.diffs[0].hunks ? "Downloadable file cannot be shown here. Please use the code repository to view the file" : "Please resubmit the request with the latest changes to see the diff"} />
                                </div>
                                :
                                <div className="w-100 overflow-auto">
                                    <div className="marker">
                                        {this.state.coll.map((data, index) => {
                                            var markerSize = 100 / this.state.coll.length;
                                        if (data.includes('i_')) {
                                                if (data.includes('true')) {
                                                    return (<Tooltip key={index} title="Manual Comment" placement="top">
                                                        <div key={index} onClick={() => {
                                                            this[data].scrollIntoView({
                                                                behavior: 'smooth',
                                                                block: "center", inline: "nearest"
                                                            });
                                                        }} className={"changed-line-path changed-line-manual bg-primary"} style={{
                                                            height: markerSize + "%"
                                                        }}></div>
                                                    </Tooltip>)
                                                }

                                                return (<Tooltip key={index} title="Comment" placement="top">
                                                    <div key={index} onClick={() => {
                                                        this[data].scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: "center", inline: "nearest"
                                                        });
                                                    }} className={"changed-line-path changed-line bg-danger"} style={{
                                                        height: markerSize + "%"
                                                    }}></div>
                                                </Tooltip>)

                                            } else {
                                                return <div key={index} style={{
                                                    height: markerSize + "%"
                                                }}></div>
                                            }

                                        })}
                                    </div>
                                    <div className="diffView-container">
                                        {this.props.data.diffs[0].hunks && this.props.data.diffs[0].hunks.map((contaxt, index) => {
                                            if (!index) {
                                                lastDestination = 0;
                                            }
                                            return (
                                                <div key={index} className="m-auto bg-white mb-4 p-0 shadow-sm data">
                                                    {
                                                        Object.keys(this.props.comments).map((key, index) => {
                                                            return (contaxt.destinationLine > key && (key > lastDestination))
                                                                ?
                                                                <div key={index} className="row p-0 m-0">
                                                                    <div className="ml-4 mr-4 mt-3 mb-3 shadow comment-container bg-white rounded">
                                                                        {this.props.comments[key].map((cmt, index) => {
                                                                            tempMarker.push("i_" + cmt.commentId)
                                                                            return (<div key={index} ref={ref => this["i_" + cmt.commentId] = ref} id={"i_" + cmt.commentId}><RepoAnalysisReviewComments key={index} data={cmt} scanId={this.props.scanId} state={this.props.state} /></div>)
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                : null;
                                                        })
                                                    }
                                                    {contaxt.segments.map((seg, index) => {
                                                        return (<div key={index} className={'MODIFIED'}>
                                                            {seg.lines.map((line, index) => {
                                                                tempMarker.push("s_" + index)
                                                                lastDestination = line.destination
                                                                return (<div key={index} className="row p-0 m-0 add-comment">
                                                                    <div className="col-md-12 d-flex">
                                                                        <span className="line line-src">{line.source || ''}</span>
                                                                        <span className="line line-dest"> {line.destination}</span>
                                                                        <span className="line-Changes pl-3" style={{ whiteSpace: 'pre-wrap' }}>
                                                                            {line.line}
                                                                        </span>
                                                                    </div>
                                                                    {this.state.commentsList.comments && seg.type !== "REMOVED" ?
                                                                        <div className="ml-4 mr-4 shadow comment-container bg-white rounded">
                                                                            {this.state.commentsList.comments.map((cmt, index) => {
                                                                                if(cmt.location.lines.begin === line.destination){
                                                                                tempMarker.push("i_" + cmt.commentId + cmt.manualComment)
                                                                                return (<div key={index} ref={ref => this["i_" + cmt.commentId + cmt.manualComment] = ref}
                                                                                    id={"i_" + cmt.commentId}>
                                                                                    {/* <ReviewComment key={index} data={cmt} scanId={this.props.scanId}/> */}
                                                                                    <RepoAnalysisReviewComments  key={index} data={cmt} scanId={this.props.scanId}/>
                                                                                </div>)}
                                                                            })}
                                                                        </div>
                                                                        : null}
                                                                </div>)
                                                            })}
                                                        </div>)
                                                    })}

                                                    <div className="p-4 text-center text-danger bg-light">
                                                        * * * * * * * * *
                                                </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {this.setValue()}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.open}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.message ? this.state.message : "Copied!"}</span>}
                    action={[
                        <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
                            Ok
                        </Button>,
                    ]}
                />
            </div>
        );
    }
}

export default RepoAnalysisDiffView;