import { authHeader, refreshHeader } from '../services/auth-header';
import coreService from './../../AppCore';
import preferenceService from '../../service/preference';
import { resetFilterService } from '../services/reset-filter';

export const loginService = {
    login,
    logout,
    getCurrentUser,
    refreshToken,
    signup,
};

function login(usernameOrEmail, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({ usernameOrEmail, password })
    };

    return fetch(coreService.rootContext + 'api/auth/signin', requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(usernameOrEmail + ':' + password);
                preferenceService.setValue('user', user, 'local');
                preferenceService.removeValue('refreshToken', 'local');
            }
            return user;
        });
}

function signup(email, name, password, username) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({ email, name, password, username })
    };

    return fetch(coreService.rootContext + 'api/auth/signup', requestOptions)
        .then(res => handleResponse(res, false, true))
        .then(user => {
            return user;
        });
}

function refreshToken(callBack) {
    const requestOptions = {
        method: 'POST',
        headers: refreshHeader().Authorization ? refreshHeader() : authHeader()
    };

    return fetch(coreService.rootContext + 'api/auth/refresh-token', requestOptions)
        .then(handleRefreshToken)
        .then(refreshToken => {
            if (refreshToken) {
                localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
                //callBack();
            }
            return refreshToken
        });
}

function logout() {
    // remove user from local storage to log user out   
    resetFilterService.resetFilters();
    preferenceService.removeValue('user', 'local');
    preferenceService.removeValue('users', 'local');
    window.location.href = '/login';
}

function getCurrentUser() {
    const requestOptions = {
        method: 'GET',
        headers: refreshHeader().Authorization ? refreshHeader() : authHeader()
    };

    return fetch(coreService.rootContext + 'api/user/me', requestOptions).then((res) => {
        return handleResponse(res, true);
    });
}

function handleResponse(response, isLogout, isSignUp) {
    return response.text().then(text => {
        try {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    if (isLogout) {
                        logout();
                    }
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            if (!isSignUp) {
                localStorage.setItem('users', JSON.stringify(data));
            }
            return data;
        } catch (error) {
            logout();
        }
    });
}

function handleRefreshToken(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
    });
}