import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import RepoAnalysisStashModal from './RepoAnalysisTreeView/RepoAnalysisStashModal';
import Tooltip from '@material-ui/core/Tooltip';
import { colorByPercentage, colorByPercentageDuplication } from './helper/helper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0),
        borderRadius: 10
    },
    card: {
        maxWidth: 400,
        minHeight: 200,
        margin: `${theme.spacing(0)}px auto`,
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    title: {
        fontWeight: 'bold',
        marginTop: 10,
        fontSize: 23
    },
    comment: {
        color: '#0076FE',
        fontSize: '13.5px'
    },
    security: {
        color: '#D30B34',
        fontSize: '18px'
    },
    coverage: {
        fontSize: '13.5px'
    },
    totelcount: {
        fontSize: 23,
        fontWeight: 'bold',

    },
    securityCount: {
        fontSize: '23px',
        fontWeight: 'bold',

    },
    description: {
        minHeight: 80,
        minWidth: 80,
        fontSize: '12px'
    }
}));

const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            h5: {
                fontSize: [22, "!important"]
            }
        }
    }
});

function RepoAnalysisReportDetails(props) {
    const classes = useStyles();
    const [type, setType] = React.useState('');
    const [treeModal, showTreeModal] = React.useState(false);

    const handleDuplicationOpen = () => {
        props.hideDashboardChildComponent(true)
    }

    const handleBugsOpen = () => {
        showTreeModal(!false);
        setType("Bugs")
    }

    const handleTreeViewClose = () => {
        showTreeModal(false);
    };

    const handleSecurityOpen = () => {
        props.hideSecurityTreeComponent(true)
    }

    const handleSuggestionsOpen = () => {
        showTreeModal(!false);
        setType("Suggestions")
    }

    const reportIconColors = (reportName) => {
        switch (reportName) {
            case 'Bugs':
                return '#D30B34'

            case 'Duplications':
                return '#3677FE'

            case 'Suggestions':
                return '#229254'

            case 'Security':
                return '#4A10C6'

            default:
                break;
        }
    }

    const reportIcons = (reportName) => {
        switch (reportName) {
            case 'Bugs':
                return 'bug_report'

            case 'Duplications':
                return 'control_point_duplicate'

            case 'Suggestions':
                return 'message'

            case 'Security':
                return 'lock'

            default:
                break;
        }
    }

    const reportFirstText = (reportName) => {
        switch (reportName) {
            case 'Bugs' || 'Suggestions':
                return 'Comments'

            case 'Duplications':
                return 'Duplications'

            case 'Security':
                return 'Vulnerabilities'

            default:
                break;
        }
    }

    const reportSecondText = (reportName) => {
        switch (reportName) {
            case 'Bugs':
                return 'Quality'

            case 'Duplications':
                return 'Percentage'

            case 'Suggestions':
                return 'Coverage'

            case 'Security':
                return 'Dependencies'

            default:
                break;
        }
    }

    const colorApplier = (reportName, percentage) => {
        switch (reportName) {
            case 'Bugs' || 'Suggestions':
                return colorByPercentage(percentage)

            case 'Duplications':
                return colorByPercentageDuplication(percentage)
        }
    }

    return (
        <Card elevation={0} className={classes.root} key={props.data.category} >
            <CardActionArea 
                className={classes.card} 
                disabled ={props?.reqData?.status === "Completed" ? false : true} 
                onClick={() => {
                { props.data.totalComments !== 0 && props.data.category === 'Bugs' && handleBugsOpen(); }
                { props.data.duplications !== 0 && props.data.category === 'Duplications' && handleDuplicationOpen(); }
                { props.data.totalComments !== 0 && props.data.category === 'Suggestions' && handleSuggestionsOpen(); }
                { props.data.vulnerableDependencies !== 0 && props.data.category === 'Security' && handleSecurityOpen(); }
            }}>
                <Grid container spacing={1}>
                    <Grid item>
                        <i className="material-icons reportIcon" style={{ color: reportIconColors(props.data.category) }}>{reportIcons(props.data.category)}</i>
                    </Grid>
                    <Grid item xs>
                        <ThemeProvider theme={theme}>
                            <Typography className={classes.title} variant="h5">
                                {props.data.category}
                            </Typography>
                        </ThemeProvider>
                    </Grid>
                    {/* <Grid>
                        <span className={"mr-2 mt-3 pl-3 pr-3 badge badge-" + (
                            props.analyzedStatus === 'New'
                                ? 'danger'
                                : ''
                        )} style={{ fontSize: "60%", fontWeight: "40px", borderRadius: "5px" }}>{props.analyzedStatus === 'New' ? props.analyzedStatus : ''}</span>
                    </Grid> */}
                </Grid>
                <Grid container spacing={2} className={classes.description}>
                    <Grid item>
                        <Typography color="textSecondary" component="p">
                            {props.data.description}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item>
                        <>
                            {/* <Typography className={classes.totelcount}>
                                {props.analyzedStatus === 'Completed' ? props.comments : '- -'}
                            </Typography> */}
                            <Typography className={classes.totelcount}>
                                {props.data.category === 'Bugs' || props.data.category === 'Suggestions' ? <b><Tooltip placement="top" id="placement" title={props.data.totalComments}><span>{props.data.totalComments.toString().length >= 4 ? Math.trunc(props.data.totalComments / 1000) + 'K' : props.data.totalComments}</span></Tooltip></b>
                                    : props.data.category === 'Duplications' ? <b><Tooltip placement="top" id="placement" title={props.data.duplications}><span>{props.data.duplications.toString().length >= 4 ? Math.trunc(props.data.duplications / 1000) + 'K' : props.data.duplications}</span></Tooltip></b>
                                        : props.data.category === 'Security' ? <b><Tooltip placement="top" id="placement" title={props.data.vulnerabilitiesFound}><span>{props.data.vulnerabilitiesFound.toString().length >= 4 ? Math.trunc(props.data.vulnerabilitiesFound / 1000) + 'K' : props.data.vulnerabilitiesFound}</span></Tooltip></b> : ''}
                            </Typography>
                            <Typography className={classes.comment}>
                                {reportFirstText(props.data.category)}
                            </Typography>
                        </>
                    </Grid>
                    <Grid item xs>
                        <>
                            {/* <Typography className={classes.totelcount}>
                                {props.analyzedStatus === 'Completed' ? props.coverage : '- -'}
                            </Typography> */}
                            <Typography className={classes.totelcount} component={'div'}>
                                {props.data.category === 'Bugs' || props.data.category === 'Suggestions' ? props.data.coverage + '%' : props.data.category === 'Duplications' ? props.data.duplicationPercentage + '%' : props.data.category === 'Security' ? props.data.vulnerableDependencies : ''}
                            </Typography>
                            <Typography className={props.data.category === 'Bugs' || props.data.category === 'Suggestions' ? classes.coverage : classes.comment} style={{ whiteSpace: 'pre-wrap', color: colorApplier(props.data.category, (props.data.coverage || props.data.duplicationPercentage)) }}>
                                {reportSecondText(props.data.category)}
                            </Typography>
                        </>
                    </Grid>
                    {
                        props.data.category === "Security"
                        ?
                        <Grid item xs>
                            <>
                                <Typography className={classes.totelcount} component={'div'}>
                                    {props.data.dependenciesScanned}
                                </Typography>
                                <Typography className={classes.comment} style={{ whiteSpace: 'pre-wrap', color: colorByPercentage(props.data.coverage) }}>
                                    Scanned
                                </Typography>
                            </>
                        </Grid>
                        : 
                        ""
                    }
                    
                </Grid>
            </CardActionArea>
            <RepoAnalysisStashModal showModal={treeModal} type={type} scanId={props.scanId} onClose={handleTreeViewClose} />
        </Card>
    )
}

export default RepoAnalysisReportDetails;