import React from 'react';
import MaterialTable from 'material-table';

const columnDefs = [
    {
        title: "RepoName", field: "repoName"
    }, {
        title: "# Reviews", field: "noOfReviews"
    }, {
        title: "# LinesAdded", field: "numOfLinesAdded"
    }
];

function ReviewByRepoTable(props) {
    return (
        <div className="container p-2">
            <div className="col-12">
                {
                    props.data?.length !== 0
                        ?
                        <MaterialTable
                            title="Repos"
                            columns={columnDefs}
                            data={props.data}
                            options={{
                                filtering: false,
                                toolbar: false,
                                headerStyle: {
                                    padding: '5px',
                                    fontSize: '15px',
                                    fontWeight: "bold",
                                    color: 'black',
                                },
                                pageSize: 7,
                                pageSizeOptions: [7, 10, 20, 30],
                            }}
                        />
                        :
                        <div className="text-center pt-5 graph-height" >
                            <img
                                src="/images/statistic.jpg"
                                width="90"
                                alt="noData"
                            />
                            <br />
                            Not enough data to display the chart
                        </div>
                }
            </div>
        </div>
    );
}

export default ReviewByRepoTable;