import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Error404 from '../../core/error/404';
import RepoAnalysisDetail from '../RepoAnalysis/RepoAnalysis-detail'
import RepoAnalysisVersions from './RepoAnalysis-versions';
import RepoAnalysisLanguageBar from '../RepoAnalysis/RepoAnalysis-languagebar';
import RepoAnalysisReport from '../RepoAnalysis/RepoAnalysis-report';
import RepoAnalysisSkeleton from './RepoAnalysis-skeleton';
import RepoAnalysisDuplicationTreeView from './RepoAnalysisDuplicationTreeView';
import RepoAnalysisVersionCompare from './RepoAnalysisVersionCompare';
import RepoAnalysisSecurityTreeView from './RepoAnalysisSecurityTreeView';
import Grid from '@material-ui/core/Grid';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Typography from '@material-ui/core/Typography';
import RepoAnalysisStatusMessage from './RepoAnalysis-modals/RepoAnalysisStatusMessage';
import Snackbars from '../../core/error/Snackbars';
import StatusWatcher from '../reviews/modals/reviewStatus/StatusWatcher'
import { withStyles } from '@material-ui/core/styles';
import IconButton from './IconButton';

var abc = '',
interval = '';

const GET_FULLSCAN_BY_ID = gql`
query fullScanRequestById ($id: String!, $repoScanRequestId: String) 
{   fullScanRequestById(id: $id)
   {
       id
       projectGroupId
       projectGroupName
       projectId
       projectName
       repoGroupName
       repoName
       branchName
       createdOn
       modifiedOn
       createdOnDates
       modifiedOnDates
       requestedUser {
           id
           username
           name
           emailId
       }
       organizationId
       status
       message
       version
       latestCommitId
       repoScanCategory{
           value
           flag
       }
       excludePattern
   }
   fullScanResponseById( repoScanRequestId:$repoScanRequestId)
    {
      repoScanResponseId
      totalComments
      totalNoOfLines
      totalNoOfFiles
      qualityGate
      version
      codeCoverage{
        percentage
      }
       analysisReport{
           category
           description
           totalComments
           coverage
           duplications
           status
           duplicationPercentage
           dependenciesScanned
           vulnerableDependencies
           vulnerabilitiesFound
           vulnerabilitiesSuppressed
           bugsQualityGate
           duplicationQualityGate
           securityQualityGate
           suggestionQualityGate
           suggestionQualityComments
           qualityGateStatusRange{
            qualityGateStatus
            qualityGateRange
            }
     }
      languagePercentage{
          language
          percentage
          fullPercentage
      }
  }
  versionHistoryById(repoScanRequestId: $repoScanRequestId)
       {
         repoName 
         versionHistory{
             version
             qualityGate
             status
             commitId
             dateTime
         }
        
       }
}  
`;

const useStyles = theme => ({
    qualityGateTitle: {
        fontSize: 10
    },
    qualityBadgePassContainer: {
        backgroundColor: "#28a745",
        color: '#fff',
        width: 138,
        textAlign: "center",
        padding: "9px 0px",
        borderRadius: "15px",
    },
    qualityBadgeWarnContainer: {
        backgroundColor: "rgb(255, 191, 0)",
        color: '#000',
        width: 138,
        textAlign: "center",
        padding: "9px 0px",
        borderRadius: "15px",
    },
    qualityBadgeFailContainer: {
        backgroundColor: "#D30B34",
        color: '#fff',
        width: 138,
        textAlign: "center",
        padding: "9px 0px",
        borderRadius: "15px",
    },
    qualityTitle: {
        fontSize: 18,
        fontWeight: "bold"
    },
    qualitySubTitle: {
        fontSize: 11
    },
    headLeftContainer: {
        width: "12%"
    },
    headRightContainer: {
        width: "88%"
    }
});

class RepoAnalysisDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            activeKey: "versions",
            showDashboardComponent: false,
            showVersionComponent: false,
            showDuplicationComponent: false,
            showSecurityComponent: false,
            checkedValues: [],
            snackbarOpen: false,
            errorsnackbarOpen: false,
            message: '',
            repoScanResponseId: "",
            categoryChecked: [],
            scanInProgress: false,
            categoryComments: '',
            resumbmitRequestId: '',
            resumbitUserId: '',
            isLoading: false,
            errorPopup: false
        }
    }

    showDuplicationComponent = (data) => {
        this.setState({ showDuplicationComponent: true })
        this.setState({ showDashboardComponent: data })
    }

    handleDuplicationComponent = () => {
        this.setState({ showDuplicationComponent: false })
        this.setState({ showDashboardComponent: false })
    }

    showVersionCompareComponent = (data) => {
        this.setState({ showVersionComponent: true })
        this.setState({ showDashboardComponent: data })
    }

    handleVersionCompareComponent = () => {
        this.setState({ showVersionComponent: false })
        this.setState({ showDashboardComponent: false })
    }

    showSecurityTreeViewComponent = (data) => {
        this.setState({ showSecurityComponent: true })
        this.setState({ showDashboardComponent: data })
    }

    handleSecurityTreeViewComponent = () => {
        this.setState({ showSecurityComponent: false })
        this.setState({ showDashboardComponent: false })
    }

    handleCheckedValue = (data) => {
        this.setState({ checkedValues: data }, () => { console.log("Checked Version =" + this.state.checkedValues) })
    }

    handleCatSnackbar = (data, msg) => {
        this.setState({ snackbarOpen: data });
        this.setState({ message : msg})
    }

    handleRefresh = (data) => {
        this.setState({ isLoading: data });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="repo-details container-fluid px-4">
                <Query query={GET_FULLSCAN_BY_ID} fetchPolicy="no-cache" variables={{ id: this.state.id, repoScanRequestId: this.state.id }}>
                    {({ loading, error, data, refetch }) => {
                        if (loading) return <RepoAnalysisSkeleton />
                        if (error) return <Error404 />
                        if (data.fullScanRequestById === null) return <Error404 />

                        if (!this.state.repoScanResponseId && data.fullScanResponseById.repoScanResponseId) {
                            this.setState({ repoScanResponseId: data.fullScanResponseById.repoScanResponseId })
                        }
                        var badge = false;
                        if (data?.fullScanRequestById?.status === "Completed") {
                            badge = true
                        }
                        if (this.state.isLoading && data?.fullScanRequestById) {
                            setTimeout(() => {
                                this.setState({ isLoading: false });
                            }, 1200);
                        }
                        return (
                            <>
                                {this.state.isLoading && <RepoAnalysisSkeleton />}
                                {!this.state.isLoading && <div className="repoAnalysis-details-fixed-header">
                                    {/* {this.state.scanInProgress */}
                                    {false && <StatusWatcher id={data.fullScanRequestById.id} onConnect={client => { this.client = client }} isReviewStatus={true} disconnected={(data) => {
                                        this.setState({ open: data }, () => {
                                            refetch();
                                        })
                                    }} />}

                                    <Grid container className=" pl-2">
                                        {
                                            badge
                                                ?
                                                <Grid item style={{ width: badge ? "12%" : "" }}>
                                                    {
                                                        data?.fullScanResponseById?.qualityGate === "Passed"
                                                        &&
                                                        <div className={classes.qualityBadgePassContainer}>
                                                            <Typography className={classes.qualitySubTitle}>Quality Gate</Typography>
                                                            <Typography className={classes.qualityTitle}>Passed</Typography>
                                                        </div>
                                                    }
                                                    {
                                                        data?.fullScanResponseById?.qualityGate === "Warning"
                                                        &&
                                                        <div className={classes.qualityBadgeWarnContainer}>
                                                            <Typography className={classes.qualitySubTitle}>Quality Gate</Typography>
                                                            <Typography className={classes.qualityTitle}>Warning</Typography>
                                                        </div>
                                                    }
                                                    {
                                                        data?.fullScanResponseById?.qualityGate === "Failed"
                                                        &&
                                                        <div className={classes.qualityBadgeFailContainer}>
                                                            <Typography className={classes.qualitySubTitle}>Quality Gate</Typography>
                                                            <Typography className={classes.qualityTitle}>Failed</Typography>
                                                        </div>
                                                    }
                                                </Grid>
                                                :
                                                ""
                                        }
                                        <Grid item style={{ width: badge ? "88%" : "100%" }}>
                                            <Grid container className=" pl-2">
                                                <Grid item xs={12} style={{ fontSize: '15px', color: '#7D7D7E' }}>
                                                    {data.fullScanRequestById.projectGroupName}
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    <Grid container>
                                                        <Grid item className="mr-0" style={{ fontSize: '24px', color: '#3677fe' }}>
                                                            {data.fullScanRequestById.repoName}
                                                        </Grid>&nbsp;&nbsp;&nbsp;
                                                        <Grid item className={"mt-2"}>
                                                            <TrendingFlatIcon color="disabled" />
                                                        </Grid>&nbsp;&nbsp;&nbsp;
                                                        <Grid item className={"mt-2"}>
                                                            <Typography className={" p-0 pl-3 pr-3 badge bg-white"} style={{ fontSize: "12px", fontWeight: 500, verticalAlign: '-2px', borderRadius: "5px" }}>
                                                                {data.fullScanRequestById.branchName}
                                                            </Typography>
                                                        </Grid> &nbsp;&nbsp;&nbsp;
                                                        <Grid item className={"mt-2"}>
                                                            <Typography className={" p-0 pl-3 pr-3 badge badge-" + (data.fullScanRequestById.status === 'Completed'
                                                                ? 'success'
                                                                : data.fullScanRequestById.status === 'In Progress'
                                                                    ? 'warning'
                                                                    : 'danger'
                                                            )} style={{ fontSize: "12px", fontWeight: "400px", verticalAlign: '-2px', borderRadius: "5px" }}>
                                                                {data.fullScanRequestById.status}
                                                            </Typography>
                                                        </Grid> &nbsp;&nbsp;
                                                        {data.fullScanRequestById.message && <Grid item style={{ marginTop: '6px' }}>
                                                            <RepoAnalysisStatusMessage data={data.fullScanRequestById.message} />
                                                        </Grid>}
                                                        <Grid item style={{ marginTop: '1px' }}>
                                                            <div className="mt-2 ml-2">
                                                                <span
                                                                    className={"pt-1 pb-1 mr-2 badge"}
                                                                    style={
                                                                        {
                                                                            borderRadius: '23px',
                                                                            backgroundColor: (
                                                                                data?.fullScanResponseById?.qualityGate === "Passed" && '#28a745' ||
                                                                                data?.fullScanResponseById?.qualityGate === "Warning" && 'rgb(255, 191, 0)' ||
                                                                                data?.fullScanResponseById?.qualityGate === "Failed" && '#D30B34'
                                                                            ),
                                                                            color: 'white',
                                                                            paddingLeft: '13px',
                                                                            paddingRight: '13px'
                                                                        }
                                                                    }
                                                                >
                                                                    {data.fullScanResponseById.version}
                                                                </span>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={3} >
                                                    <IconButton
                                                        reqData={data.fullScanRequestById}
                                                        refetch={() => refetch()}
                                                        showDashboardComponent={this.state.showDashboardComponent}
                                                        showDuplicationComponent={this.state.showDuplicationComponent}
                                                        showVersionComponent={this.state.showVersionComponent}
                                                        showSecurityComponent={this.state.showSecurityComponent}
                                                        handleVersionCompareComponent={this.handleVersionCompareComponent}
                                                        handleSecurityTreeViewComponent={this.handleSecurityTreeViewComponent}
                                                        handleDuplicationComponent={this.handleDuplicationComponent}
                                                        handleRefresh={this.handleRefresh}
                                                        handleCatSnackbar={this.handleCatSnackbar}
                                                        // snackbarMessage={this.state.message}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <div className="row mt-2">
                                        {
                                            !this.state.showDashboardComponent &&
                                            <div className="col-md-8 pl-4 pr-0">
                                                <div className='col-12 pl-0 pr-0'>
                                                    <RepoAnalysisDetail
                                                        reqData={data.fullScanRequestById}
                                                        resData={data.fullScanResponseById}
                                                        scanId={this.state.id}
                                                    />
                                                </div>
                                                <div className='col-12 pl-0 pr-0 mt-4'>
                                                    {
                                                        data.fullScanResponseById.languagePercentage
                                                            ?
                                                            <RepoAnalysisLanguageBar
                                                                data={data.fullScanResponseById}
                                                            />
                                                            :
                                                            ""
                                                    }
                                                </div>
                                                <div className='col-12 pl-0 pr-0 mt-4'>
                                                    {
                                                        data.fullScanResponseById.analysisReport
                                                            ?
                                                            <RepoAnalysisReport
                                                                reportsdata={data.fullScanResponseById.analysisReport}
                                                                scanId={this.state.id}
                                                                showDcComponent={this.showDuplicationComponent}
                                                                showSecurityComponent={this.showSecurityTreeViewComponent}
                                                                reqData={data.fullScanRequestById}
                                                            />
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            !this.state.showDashboardComponent &&
                                            <div className="col-md-4">
                                                <RepoAnalysisVersions
                                                    versionsData={data.versionHistoryById}
                                                    hideDashboardChildComponent={this.showVersionCompareComponent}
                                                    getVersions={this.handleCheckedValue}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.showVersionComponent && this.state.showDashboardComponent
                                        &&
                                        <RepoAnalysisVersionCompare
                                            hideDashboardChildComponent={this.showVersionCompareComponent}
                                            checkedVersions={this.state.checkedValues}
                                            scanId={data.fullScanRequestById.id}
                                            qtyPercentage={data?.fullScanResponseById?.qtyPercentage}
                                        />
                                    }
                                </div>}
                            </>
                        );
                    }}

                </Query>
                {
                    this.state.showDuplicationComponent
                    &&
                    this.state.showDashboardComponent
                    &&
                    <RepoAnalysisDuplicationTreeView
                        scanId={this.state.id}
                        repoScanResponseId={this.state.repoScanResponseId}
                    />
                }
                {
                    this.state.showSecurityComponent
                    &&
                    this.state.showDashboardComponent
                    &&
                    <RepoAnalysisSecurityTreeView
                        repoScanResponseId={this.state.repoScanResponseId}
                    />
                }
                {
                    this.state.snackbarOpen
                    &&
                    <Snackbars
                        status="success"
                        autoHideDuration={3000}
                        message={this.state.message}
                        showSnackbar={true}
                        closeSnackBar={false}
                    />
                }
            </div>
        );

    }
}

export default withStyles(useStyles)(RepoAnalysisDashboard);