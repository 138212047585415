import React from 'react';
import RepoAnalysisReportDetails from './RepoAnalysisReportDetails';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RepoAnalysisReportSecurity from './RepoAnalysisReport/RepoAnalysisReportSecurity';
import RepoAnalysisReportBugs from './RepoAnalysisReport/RepoAnalysisReportBugs';
import RepoAnalysisReportDuplications from './RepoAnalysisReport/RepoAnalysisReportDuplications';
import RepoAnalysisReportSuggestions from './RepoAnalysisReport/RepoAnalysisReportSuggestions';
import Button from '@material-ui/core/Button';
import ConsoleData from './ConsoleData';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1300 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1300, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 180,
        width: 280,
    },
    control: {
        margin: '3px',
        borderRadius: 20
    },
    title: {
        fontWeight: 'bold'
    },
    consoleButton: {
        margin: theme.spacing(1),
        background: "#fff",
        border: "1px solid cornflowerblue",
        boxShadow: "none",
        padding: "4px 8px"
      },
}));

function RepoAnalysisReport(props) {
    const classes = useStyles();
    const reports = props.reportsdata;
    const [consoleLogPopup, setConsoleLogPopup] = React.useState(false);

    const hideDCComponent = (data) => {
        props.showDcComponent(data)
    }
    
    const hideSecurityComponent = (data) => {
        props.showSecurityComponent(data)
    }

    const openConsole = () => {
        setConsoleLogPopup(true);
    }

    const popupClose = () => {
        setConsoleLogPopup(false)
    }
    
    return (
        <div className='w-100'>
            <Typography variant="h5" className={classes.title}>
                Analysis Report
            </Typography>
            {
                < Carousel
                responsive={responsive}
                >
                    {
                        reports.map((report) => {
                            switch(report.category){
                                case "Bugs":
                                    return (
                                        <>
                                            <Paper elevation={0} className={classes.paper && classes.control} key={report.templateName}>
                                                <RepoAnalysisReportBugs
                                                    reqData={props.reqData}
                                                    data={reports.find(bReport => {
                                                        return bReport.category === 'Bugs'
                                                    })}
                                                    repoScanRequestId={props.repoScanRequestId}
                                                    scanId={props.scanId}
                                                    hideSecurityTreeComponent={hideSecurityComponent}
                                                    status={props.reportsdata.status}
                                                    severityData={props.severityData}
                                                />
                                            </Paper>
                                        </>
                                    )

                                case "Duplications":
                                    return (
                                        <>
                                            <Paper elevation={0} className={classes.paper && classes.control} key={report.templateName}>
                                            <RepoAnalysisReportDuplications
                                                reqData={props.reqData}
                                                data={reports.find(dReport => {
                                                    return dReport.category === 'Duplications'
                                                })}
                                                repoScanRequestId={props.repoScanRequestId}
                                                scanId={props.scanId}
                                                hideDashboardChildComponent={hideDCComponent}
                                                status={props.reportsdata.status}
                                            />
                                            </Paper>
                                        </>
                                    )

                                case "Security": 
                                    return (
                                        <>
                                            <Paper elevation={0} className={classes.paper && classes.control} key={report.templateName}>
                                            <RepoAnalysisReportSecurity
                                                reqData={props.reqData}
                                                data={reports.find(secReport => {
                                                    return secReport.category === 'Security'
                                                })}
                                                repoScanRequestId={props.repoScanRequestId}
                                                scanId={props.scanId}
                                                hideSecurityTreeComponent={hideSecurityComponent}
                                                status={props.reportsdata.status}
                                            />
                                            </Paper>
                                        </>
                                    )
                                
                                case "Suggestion": 
                                    return (
                                        <>
                                            <Paper elevation={0} className={classes.paper && classes.control} key={report.templateName}>
                                            <RepoAnalysisReportSuggestions
                                                reqData={props.reqData}
                                                data={reports.find(sugReport => {
                                                    return sugReport.category === 'Suggestion'
                                                })}
                                                repoScanRequestId={props.repoScanRequestId}
                                                scanId={props.scanId}
                                                hideSecurityTreeComponent={hideSecurityComponent}
                                                status={props.reportsdata.status}
                                            />                                            
                                            </Paper>
                                        </>
                                    )
                            }
                        })
                    }
                </Carousel>
            }
            {
                consoleLogPopup
                &&
                <ConsoleData popupClose = { popupClose }/>
            }
        </div>
    )
}

export default RepoAnalysisReport;