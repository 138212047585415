import React, { useState } from 'react'
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useEffect } from "react";
import { refreshHeader } from '../../core/login/services/auth-header';
import Error404 from '../../core/error/404';
import RepoAnalysisDetail from '../RepoAnalysis/RepoAnalysis-detail'
import RepoAnalysisVersions from './RepoAnalysis-versions';
import RepoAnalysisLanguageBar from '../RepoAnalysis/RepoAnalysis-languagebar';
import RepoAnalysisReport from '../RepoAnalysis/RepoAnalysis-report';
import RepoAnalysisSkeleton from './RepoAnalysis-skeleton';
import RepoAnalysisDuplicationTreeView from './RepoAnalysisDuplicationTreeView';
import RepoAnalysisVersionCompare from './RepoAnalysisVersionCompare';
import RepoAnalysisSecurityTreeView from './RepoAnalysisSecurityTreeView';
import Grid from '@material-ui/core/Grid';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Typography from '@material-ui/core/Typography';
import RepoAnalysisStatusMessage from './RepoAnalysis-modals/RepoAnalysisStatusMessage';
import Snackbars from '../../core/error/Snackbars';
import StatusWatcher from '../reviews/modals/reviewStatus/StatusWatcher'
import { withStyles } from '@material-ui/core/styles';
import IconButton from './IconButton';
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Tooltip } from '@material-ui/core';

const GET_FULLSCAN_BY_ID = gql`
query fullScanRequestById ($id: String!, $repoScanRequestId: String) 
{   fullScanRequestById(id: $id)
   {
       id
       projectGroupId
       projectGroupName
       projectId
       projectName
       repoGroupName
       repoName
       branchName
       createdOn
       modifiedOn
       createdOnDates
       modifiedOnDates
       requestedUser {
           id
           username
           name
           emailId
       }
       organizationId
       status
       message
       version
       latestCommitId
       repoScanCategory{
           value
           flag
       }
       excludePattern
   }
   fullScanResponseById( repoScanRequestId:$repoScanRequestId)
    {
      repoScanResponseId
      totalComments
      totalNoOfLines
      totalNoOfFiles
      qualityGate
      version
      codeCoverage{
        percentage
      }
      excludeFilePath
      messageDetail{
        key
        value
      }
    analysisReport{
        category
        description
        totalComments
        coverage
        duplications
        status
        duplicationPercentage
        dependenciesScanned
        vulnerableDependencies
        vulnerabilitiesFound
        vulnerabilitiesSuppressed
        bugsQualityGate
        duplicationQualityGate
        securityQualityGate
        suggestionQualityGate
        suggestionQualityComments
        qualityGateStatusRange{
        qualityGateStatus
        qualityGateRange
        }
     }
      languagePercentage{
          language
          percentage
          fullPercentage
      }
      severityPercentage{
        severityName
        percentage
    }
  }
  versionHistoryById(repoScanRequestId: $repoScanRequestId)
       {
         repoName 
         versionHistory{
             version
             qualityGate
             status
             commitId
             dateTime
         }
        
       }
}  
`;


const useStyles = makeStyles(theme => ({
    qualityGateTitle: {
        fontSize: 10
    },
    qualityBadgePassContainer: {
        backgroundColor: "#28a745",
        color: '#fff',
        width: 138,
        textAlign: "center",
        padding: "9px 0px",
        borderRadius: "15px",
    },
    qualityBadgeWarnContainer: {
        backgroundColor: "rgb(255, 191, 0)",
        color: '#000',
        width: 138,
        textAlign: "center",
        padding: "9px 0px",
        borderRadius: "15px",
    },
    qualityBadgeFailContainer: {
        backgroundColor: "#D30B34",
        color: '#fff',
        width: 138,
        textAlign: "center",
        padding: "9px 0px",
        borderRadius: "15px",
    },
    qualityTitle: {
        fontSize: 18,
        fontWeight: "bold"
    },
    qualitySubTitle: {
        fontSize: 11
    },
    headLeftContainer: {
        width: "12%"
    },
    headRightContainer: {
        width: "88%"
    }
}));

// const messageDetail = {
//     messageDetail: [
//         {
//             key: "Bugs",
//             value: "Bugs error message"
//         },
//         {
//             key: "Duplications",
//             value: "Duplication error message"
//         },
//         {
//             key: "Security",
//             value: "Security error message"
//         },
//         {
//             key: "Suggestion",
//             value: "Suggestion error message"
//         }
//     ],

// }

function FullScanDashboard(props) {
    const { error, data, loading, refetch } = useQuery(GET_FULLSCAN_BY_ID, {
        variables: {
            id: props.match.params.id,
            repoScanRequestId: props.match.params.id
        }
    });

    const classes = useStyles();
    const [selectedTrend, setSelectedTrend] = useState('1');
    const [showDashboardComponent, setShowDashboardComponent] = React.useState(false);
    const [showVersionComponent, setShowVersionComponent] = useState(false);
    const [showDuplicationComponent, setShowDuplicationComponent] = useState(false);
    const [showSecurityComponent, setShowSecurityComponent] = useState(false);
    const [checkedValues, setCheckedValues] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [repoScanResponseId, setRepoScanResponseId] = useState("");
    const [categoryChecked, setCategoryChecked] = useState([]);
    const [scanInProgress, setScanInProgress] = useState(false)
    const [categoryComments, setCategoryComments] = useState("");
    const [resumbmitRequestId, setResumbmitRequestId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [scrollResetValue, setScrollResetValue] = useState('');
    const [copySucess, setCopySucess] = React.useState(false);

    useEffect(() => {
        if (scrollResetValue) {
            window.scrollTo(0, 0);
            setScrollResetValue('')
        }
        const interval = setInterval(() => {
            if (selectedTrend === "1") {
                setSelectedTrend("2")
                if (data?.fullScanRequestById?.status === "In Progress") {
                    refetch();
                }
            } else {
                setSelectedTrend("1")
                if (data?.fullScanRequestById?.status === "In Progress") {
                    refetch();
                }
            }
        }, 15000);
        return () => clearInterval(interval);
    },
        [
            data?.fullScanRequestById?.status === "In Progress" ? selectedTrend : "",
            scrollResetValue
        ]
    );

    if (loading) {
        return <RepoAnalysisSkeleton />
    }

    if (error) {
        return <Error404 />
    }

    if (data?.fullScanRequestById === null) {
        return <Error404 />
    }

    const refresh = () => {
        refetch();
    }
    const resubmit = () => {
        refetch();
    }

    const showDuplicationComponentFunction = (data) => {
        setShowDuplicationComponent(true);
        setShowDashboardComponent(data);
    }

    const handleDuplicationComponent = () => {
        setShowDuplicationComponent(false);
        setShowDashboardComponent(false);
        setScrollResetValue('1')
    }

    const showVersionCompareComponent = (data) => {
        setShowVersionComponent(true);
        setShowDashboardComponent(data);
    }

    const handleVersionCompareComponent = () => {
        setShowVersionComponent(false);
        setShowDashboardComponent(false);
        setScrollResetValue('1')
    }

    const showSecurityTreeViewComponent = (data) => {
        setShowSecurityComponent(true);
        setShowDashboardComponent(data);
    }

    const handleSecurityTreeViewComponent = () => {
        setShowSecurityComponent(false);
        setShowDashboardComponent(false);
        setScrollResetValue('1')
    }

    const handleCheckedValue = (data) => {
        setCheckedValues(data);
    }

    const handleCatSnackbar = (data, msg) => {
        setSnackbarOpen(data);
        setMessage(msg);
    }

    const handleCategoryChecked = (data) => {
        setCategoryChecked(data);
    }

    const handleRefresh = (data) => {
        setIsLoading(data);
    }

    if (!repoScanResponseId && data.fullScanResponseById.repoScanResponseId) {
        setRepoScanResponseId(data.fullScanResponseById.repoScanResponseId);
    }

    const handleVersionBackGround = () => {

        if (data.fullScanRequestById.status === "Completed") {
            if (data?.fullScanResponseById?.qualityGate === "Passed") {
                return '#28a745'
            } else if (data?.fullScanResponseById?.qualityGate === "Warning") {
                return 'rgb(255, 191, 0)'
            } else if (data?.fullScanResponseById?.qualityGate === "Failed") {
                return '#D30B34'
            }
        } else {
            return "rgba(0, 0, 0, 0.54)";
        }
    }

    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false);
        }, 1200);
    }
    return (
        <div className="repo-details container-fluid px-4">
            <>

                {
                    data?.fullScanRequestById?.status === "In Progress"
                    &&
                    !showDashboardComponent
                    &&
                    <>
                        <br></br>
                        <center><p>Processing.....</p></center>
                        <LinearProgress />
                    </>
                }
                {
                    isLoading && <RepoAnalysisSkeleton />
                }
                {
                    !isLoading &&
                    <div className="repoAnalysis-details-fixed-header">
                        <Grid container className=" pl-2">
                            {
                                data?.fullScanRequestById?.status === "Completed"
                                &&
                                <Grid
                                    item
                                    style={{ width: data?.fullScanRequestById?.status === "Completed" ? "12%" : "" }}
                                >
                                    {
                                        data?.fullScanResponseById?.qualityGate === "Passed"
                                        &&
                                        <div className={classes.qualityBadgePassContainer}>
                                            <Typography className={classes.qualitySubTitle}>Quality Gate</Typography>
                                            <Typography className={classes.qualityTitle}>Passed</Typography>
                                        </div>
                                    }
                                    {
                                        data?.fullScanResponseById?.qualityGate === "Warning"
                                        &&
                                        <div className={classes.qualityBadgeWarnContainer}>
                                            <Typography className={classes.qualitySubTitle}>Quality Gate</Typography>
                                            <Typography className={classes.qualityTitle}>Warning</Typography>
                                        </div>
                                    }
                                    {
                                        data?.fullScanResponseById?.qualityGate === "Failed"
                                        &&
                                        <div className={classes.qualityBadgeFailContainer}>
                                            <Typography className={classes.qualitySubTitle}>Quality Gate</Typography>
                                            <Typography className={classes.qualityTitle}>Failed</Typography>
                                        </div>
                                    }

                                </Grid>
                            }
                            <Grid item style={{ width: data?.fullScanRequestById?.status === "Completed" ? "88%" : "100%" }}>
                                <Grid container className=" pl-2">
                                    <Grid item xs={12} style={{ fontSize: '15px', color: '#7D7D7E' }}>
                                        {data.fullScanRequestById.projectGroupName}
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Grid container>
                                            <Grid item className="mr-0" style={{ fontSize: '24px', color: '#3677fe' }}>
                                                {data.fullScanRequestById.repoName}
                                            </Grid>&nbsp;&nbsp;&nbsp;
                                            <Grid item className={"mt-2"}>
                                                <TrendingFlatIcon color="disabled" />
                                            </Grid>&nbsp;&nbsp;&nbsp;
                                            <Grid item className={"mt-2"}>
                                                <Tooltip
                                                    title={copySucess ? "Copied" : data.fullScanRequestById.branchName}
                                                    onClose={() => { setCopySucess(false) }}
                                                >
                                                    <Typography
                                                        className={" p-0 pl-3 pr-3 badge bg-white"}
                                                        style={{ fontSize: "12px", fontWeight: 500, verticalAlign: '-2px', borderRadius: "5px" }}
                                                        onClick={() => navigator.clipboard.writeText((data.fullScanRequestById.branchName)) && setCopySucess(true)}
                                                    >
                                                        {data.fullScanRequestById.branchName.length > 30 ? (data.fullScanRequestById.branchName).slice(0, 30) + '...' : (data.fullScanRequestById.branchName).slice(0, 30)}
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                            {
                                                data.fullScanRequestById.status === "In Progress" || data.fullScanRequestById.status === "Failed"
                                                ?
                                                <Grid item className={"mt-2 ml-2"}>
                                                    <Typography className={" p-0 pl-3 pr-3 badge badge-" + (data.fullScanRequestById.status === 'Completed'
                                                        ? 'success'
                                                        : data.fullScanRequestById.status === 'In Progress'
                                                            ? 'warning'
                                                            : 'danger'
                                                    )} style={{ fontSize: "12px", fontWeight: "400px", verticalAlign: '-2px', borderRadius: "5px" }}>
                                                        {data.fullScanRequestById.status}
                                                    </Typography>
                                                </Grid>                                                
                                                :
                                                ""
                                            }
                                                                                       
                                            {
                                                data.fullScanRequestById.message &&
                                                <Grid item style={{ marginTop: '6px' }}>
                                                    <RepoAnalysisStatusMessage data={data.fullScanRequestById.message} />
                                                </Grid>
                                            }
                                            <Grid item style={{ marginTop: '1px' }}>
                                                <div className="mt-2 ml-2">
                                                    <span
                                                        className={"pt-1 pb-1 mr-2 badge"}
                                                        style={
                                                            {
                                                                borderRadius: '23px',
                                                                backgroundColor: handleVersionBackGround(),
                                                                color: 'white',
                                                                paddingLeft: '13px',
                                                                paddingRight: '13px'
                                                            }
                                                        }
                                                    >
                                                        {data.fullScanResponseById.version}
                                                    </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3} >
                                        <IconButton
                                            reqData={data.fullScanRequestById}
                                            refetch={() => refetch()}
                                            showDashboardComponent={showDashboardComponent}
                                            showDuplicationComponent={showDuplicationComponentFunction}
                                            showVersionComponent={showVersionComponent}
                                            showSecurityComponent={showSecurityComponent}
                                            handleVersionCompareComponent={handleVersionCompareComponent}
                                            handleSecurityTreeViewComponent={handleSecurityTreeViewComponent}
                                            handleDuplicationComponent={handleDuplicationComponent}
                                            handleRefresh={handleRefresh}
                                            currentStatus={data?.fullScanRequestById?.status}
                                            handleCatSnackbar={handleCatSnackbar}
                                            messageDetail={data?.fullScanResponseById?.messageDetail}
                                            location={props.location}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> {/* end grid container*/}

                        {/* body part*/}
                        <div className="row mt-2">
                            {/* body left part*/}
                            {
                                !showDashboardComponent
                                &&
                                <div className="col-md-8 pl-4 pr-0">
                                    <div className='col-12 pl-0 pr-0'>
                                        <RepoAnalysisDetail
                                            reqData={data.fullScanRequestById}
                                            resData={data.fullScanResponseById}
                                            scanId={props.match.params.id}
                                        />
                                    </div>
                                    <div className='col-12 pl-0 pr-0 mt-4'>
                                        {
                                            data?.fullScanResponseById?.languagePercentage
                                            &&
                                            <RepoAnalysisLanguageBar
                                                data={data.fullScanResponseById}
                                            />
                                        }
                                    </div>
                                    <div className='col-12 pl-0 pr-0 mt-4'>
                                        {
                                            data?.fullScanResponseById?.analysisReport
                                            &&
                                            <RepoAnalysisReport
                                                reportsdata={data.fullScanResponseById.analysisReport}
                                                severityData={data?.fullScanResponseById?.severityPercentage}
                                                scanId={props.match.params.id}
                                                showDcComponent={showDuplicationComponentFunction}
                                                showSecurityComponent={showSecurityTreeViewComponent}
                                                reqData={data.fullScanRequestById}
                                            />
                                        }
                                    </div>
                                </div>
                            }

                            {/* body part rigth - version history*/}
                            {
                                !showDashboardComponent
                                &&
                                <div className="col-md-4">
                                    <RepoAnalysisVersions
                                        versionsData={data.versionHistoryById}
                                        hideDashboardChildComponent={showVersionCompareComponent}
                                        getVersions={handleCheckedValue}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    showDuplicationComponent && showDashboardComponent &&
                    <RepoAnalysisDuplicationTreeView
                        scanId={props.match.params.id}
                        repoScanResponseId={repoScanResponseId}
                    />
                }
                {
                    showSecurityComponent && showDashboardComponent &&
                    <RepoAnalysisSecurityTreeView
                        repoScanResponseId={repoScanResponseId}
                    />
                }
                {
                    showVersionComponent && showDashboardComponent &&
                    <RepoAnalysisVersionCompare
                        hideDashboardChildComponent={showVersionCompareComponent}
                        checkedVersions={checkedValues}
                        scanId={props.match.params.id}
                        qtyPercentage={data?.fullScanResponseById?.qtyPercentage}
                    />
                }
                {
                    snackbarOpen &&
                    <Snackbars
                        status="success"
                        autoHideDuration={3000}
                        message={message}
                        showSnackbar={true}
                        closeSnackBar={false}
                    />
                }
            </>
        </div>
    )
}

export default FullScanDashboard