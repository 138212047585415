import React, { useEffect } from 'react'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiTableCell from "@material-ui/core/TableCell";
import { Grid, ListItemText, Typography } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import ErrorIcon from '@material-ui/icons/Error';
import FlagIcon from '@material-ui/icons/Flag';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import BugReportIcon from '@material-ui/icons/BugReport';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import LockIcon from '@material-ui/icons/Lock';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import MessageIcon from '@material-ui/icons/Message';
import Tooltip from '@material-ui/core/Tooltip';
import { colorByPercentage, colorByPercentageDuplication, numberToK } from './helper/helper';
import StarIcon from '@material-ui/icons/Star';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

const GET_VERSIONS = gql`
query versionComparisonById($repoScanRequestId: String!, $version: [String])
{
   versionComparisonById(repoScanRequestId: $repoScanRequestId, version: $version) 
{
    version
    totalComments
    totalNoOfLines
    totalNoOfFiles
    qualityGate
    latestCommitId
    createdOn
    status
    commitUrl
    analysisReport{
           category
           description
           totalComments
           coverage
           duplications
           duplicationPercentage
           dependenciesScanned
           vulnerableDependencies
           vulnerabilitiesFound
           vulnerabilitiesSuppressed
     }
  }
}
`
const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);

const useStyles = makeStyles(theme => ({
    root: {
        width: '98%',
        marginTop: 25,
        marginLeft: 10,
        borderRadius: 10,
        marginBottom: '15px'
    },
    root1: {
        width: '100%',
        // backgroundColor: '#fbfcfe'
    },
    table: {
        minWidth: 650
    },
    tableCell: {
        padding: "0px !important",
        backgroundColor: '#fbfcfe',
        borderRadius: 10,
        maxWidth: 115
    },

    headerCell: {
        backgroundColor: '#fbfcfe'
    },
    listItem: {
        borderBottom: "1px solid beige",
        padding: "20px"
    },
    listItemHeader: {
        padding: "20px"
    },
    sepColumn: {
        "&:hover": {
            backgroundColor: "#fff",
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 15px 30px -12px inset, rgba(0, 0, 0, 0.3) 0px 9px 18px -18px inset"
        }
    },
    redClrStyle: {
        color: "red",
        fontSize: 12
    },
    greenClrStyle: {
        color: "green",
        fontSize: 12
    },
    blueClrStyle: {
        color: "blue",
        fontSize: 12
    },
    descStyle: {
        fontSize: 12,
        marginTop: "-10px !important"
    },
    titleHeader: {
        position: 'absolute',
        width: '203px',
        height: '25px',
        fontWeight: 600,
        fontSize: '21px',
        lineHeight: '25px',
    },
    valueStyle: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '29px',
        color: '#4B4B4B',
    },
    basicTable: {
        display: "table",
        border: "none",
        width: "100% !important"
    },
    basicInnerTable: {
        display: "table",
        border: "none",
        width: "100% !important",
        textAlign: "center",
        margin: "27px 0px"
    },
    basicRow: {
        display: "table-cell",
        border: "none",
        width: "25%"
    },
    basicTableData: {
        display: "block",
        height: "105px !important",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none"
    },
    innerTableData: {
        border: "none",
        width: "50%"
    },
    loopContainerHead: {
        // textAlign: "center"
    },
    starIconClass: {
        fontSize: 37,
        color: "green",
        marginLeft: 6
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: "center",
        margin: "27px 0px"
    },
    gateLoopContainer: {
        marginTop: 20,
        marginLeft: 17,
        textAlign: "center"
    },
    loopContainer: {
        marginTop: 17,
        marginLeft: 17
    },
    loopContainerCenter: {
        margin: "27px 0px",
        textAlign: "center"
    },
    labelContainer: {
        marginLeft: 17,
        marginRight: 17
    },
    labelContainerForVersion: {
        marginLeft: 17,
        marginRight: 17,
        marginTop: 25
    },
    badgeTopContainer: {
        display: "flex",
        marginTop: 5
    },
    badgeContainer: {
        display: "flex"
    },
    badgeIcon: {
        padding: "3px 7px",
        borderRadius: 12,
        color: "#fff",
        backgroundColor: "gray",
        marginRight: 5,
        fontSize: 11
    },
    badgeArrow: {
        marginRight: 5,
        rotate: "180deg"
    },
    versionHeaders: {
        fontWeight: 600,
        fontSize: '24px',
        color: '#535353',
        marginTop: '6px'
    },
    imgProps: {
        width: 100,
        height: 50
    },
    qualityBadgePassContainer: {
        backgroundColor: "#28a745",
        color: '#fff',
        width: 138,
        margin: "0px auto",
        padding: "9px 0px",
        borderRadius: "15px"
    },
    qualityBadgeWarnContainer: {
        backgroundColor: "rgb(255, 191, 0)",
        color: '#000',
        width: 138,
        margin: "0px auto",
        padding: "9px 0px",
        borderRadius: "15px"
    },
    qualityBadgeFailContainer: {
        backgroundColor: "#D30B34",
        color: '#fff',
        width: 138,
        margin: "0px auto",
        padding: "9px 0px",
        borderRadius: "15px"
    },
    qualityTitle: {
        fontSize: 18,
        fontWeight: "bold"
    },
    qualitySubTitle: {
        fontSize: 11
    },
    containerStyle: {
        color: "#797979",
        fontSize: '9px',
        marginTop: '1px',
        marginLeft: '35%'
    },
    itemStyle: {
        marginTop: '1px',
        marginLeft: '2px'
    },
    commitContainerStyle: {
        color: "#797979",
        fontSize: '9px',
        marginTop: '1px',
        marginLeft: '39%'
    },
    commitId: {
        cursor: 'pointer'
    },
    link: {
        fontSize: 'medium',
        marginLeft: '10px',
        color: '#797979'
    }
}));

function RepoAnalysisVersionCompare(props) {
    const headers = [
        {
            id: 0,
            icon: "",
            title: 'Version Comparison ',
            desc: ''
        },
        {
            id: 1,
            icon: <FlagIcon style={{ width: '30px', height: '60px' }} />,
            iconcolor: '#3677FE',
            title: 'Quality Gate',
            desc: 'Quality gate percentage'
        },
        {
            id: 3,
            icon: <CodeIcon style={{ width: '30px', height: '60px' }} />,
            iconcolor: '#229254',
            title: 'Code ',
            desc: 'Total lines of code inclusing all files with and without comments'
        },
        {
            id: 4,
            icon: <InsertDriveFileIcon style={{ width: '30px', height: '60px' }} />,
            iconcolor: '#3677FE',
            title: 'Files ',
            desc: 'Over all files count'
        },
        {
            id: 5,
            icon: <BugReportIcon style={{ width: '30px', height: '60px' }} />,
            iconcolor: '#D30B34',
            title: 'Bugs ',
            desc: 'Bugs report contents all error level int comments and manual review comments'
        },
        {
            id: 6,
            icon: <ControlPointDuplicateIcon style={{ width: '30px', height: '60px' }} />,
            iconcolor: '#3677FE',
            title: 'Duplications ',
            desc: 'Duplication reports contains file level and code level duplicate code comments'
        },
        {
            id: 7,
            icon: <LockIcon style={{ width: '30px', height: '60px' }} />,
            iconcolor: '#4A10C6',
            title: 'Security ',
            desc: 'All library security related issue was list down here'
        },
        {
            id: 8,
            icon: <MessageIcon style={{ width: '30px', height: '60px' }} />,
            iconcolor: '#229254',
            title: 'Suggestions ',
            desc: 'All other comments and suggestion from the manual review and linter warning'
        }
    ];

    const classes = useStyles();
    const [copySucess, setCopySucess] = React.useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const qualityGate = (value) => {
        if (value === "Passed") {
            return <div className={classes.qualityBadgePassContainer}>
                <Typography className={classes.qualitySubTitle}>Quality Gate</Typography>
                <Typography className={classes.qualityTitle}>Passed</Typography>
            </div>;
        } else if (value === "Warning") {
            return <div className={classes.qualityBadgeWarnContainer}>
                <Typography className={classes.qualitySubTitle}>Quality Gate</Typography>
                <Typography className={classes.qualityTitle}>Warning</Typography>
            </div>;
        } else if (value === "Failed") {
            return <div className={classes.qualityBadgeFailContainer}>
                <Typography className={classes.qualitySubTitle}>Quality Gate</Typography>
                <Typography className={classes.qualityTitle}>Failed</Typography>

            </div>;
        }

    }

    const versionDateFormat = (dateAndTime) => {
        const versionDateFull = Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(dateAndTime).replace(/,/g, '').split(" ");
        const hrsMinsSecs = versionDateFull[5].split(":")[0] + ':' + versionDateFull[5].split(":")[1];
        const versionDateAndTime = versionDateFull[2] + '  ' + versionDateFull[1] + '  ' + versionDateFull[3] + '  ' + hrsMinsSecs + '  ' + versionDateFull[6];
        return versionDateAndTime;
    }

    return (
        <Query query={GET_VERSIONS} fetchPolicy="no-cache" variables={{ repoScanRequestId: props.scanId, version: props.checkedVersions }}>
            {({ loading, error, data, refetch }) => {

                if (loading) return <div className="loading"><CircularProgress size={50} /></div>;
                return (
                    <Paper className={classes.root} elevation={0}>
                        <table border="1" className={classes.basicTable}>
                            <tr className={classes.basicRow} style={{ background: "paleturquoise", borderRadius: "10px 0px 0px 10px" }}>
                                {
                                    headers.map((head) => (
                                        <td className={classes.basicTableData}>
                                            <div className={head.id === 0 ? classes.labelContainerForVersion : classes.labelContainer}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item style={{ color: head.iconcolor }}>
                                                        {head.icon ? head.icon : ""}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h6" className={classes.valueStyle}>{head.title}</Typography>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    head.desc
                                                        ?
                                                        <Typography variant="caption" display="block" className={classes.descStyle}>{head.desc}</Typography>
                                                        :
                                                        ""
                                                }
                                                <div className={classes.badgeTopContainer}>
                                                    {
                                                        head.id === 0
                                                            ?
                                                            data?.versionComparisonById?.map((versions, index) => (
                                                                <div className={classes.badgeContainer}>
                                                                    <div
                                                                        className={classes.badgeIcon}
                                                                        style={
                                                                            {
                                                                                //backgroundColor: colorByPercentage(props.qtyPercentage)
                                                                                backgroundColor: (
                                                                                    versions?.qualityGate === "Passed" && '#28a745' ||
                                                                                    versions?.qualityGate === "Warning" && 'rgb(255, 191, 0)' ||
                                                                                    versions?.qualityGate === "Failed" && '#D30B34'
                                                                                )
                                                                            }
                                                                        }
                                                                    >
                                                                        {
                                                                            versions.version
                                                                        }
                                                                    </div>
                                                                    {
                                                                        data?.versionComparisonById?.length !== index + 1
                                                                            ?
                                                                            <div className={classes.badgeArrow}>
                                                                                <TrendingFlatIcon color="disabled" />
                                                                            </div>
                                                                            :
                                                                            ""
                                                                    }
                                                                </div>
                                                            ))
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    ))
                                }
                            </tr>
                            {
                                data?.versionComparisonById?.map((versions, vkey) => (
                                    <tr key={vkey} className={classes.basicRow}>
                                        <td className={classes.basicTableData}>
                                            <div className={classes.loopContainerHead}>
                                                <div className={classes.headerContainer}>
                                                    <Typography variant="h6" className={classes.versionHeaders}>{versions.version}</Typography>
                                                    {
                                                        vkey === 0
                                                            ?
                                                            <StarIcon className={classes.starIconClass} />
                                                            :
                                                            ""
                                                    }
                                                    <Grid container className={classes.containerStyle} spacing={0}>
                                                        <Grid item>
                                                            <i className="material-icons align-middle" style={{ fontSize: "small" }}>access_time</i>
                                                        </Grid>
                                                        <Grid item xs={10} className={classes.itemStyle}>
                                                            <span>{versionDateFormat(versions.createdOn)}</span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container className={classes.commitContainerStyle} spacing={0}>
                                                        <Grid item>
                                                            <i className="material-icons align-middle" style={{ fontSize: "small" }}>commit</i>
                                                        </Grid>
                                                        <Tooltip title={copySucess ? "Copied" : "Click to copy the commit Id"} onClose={() => { setCopySucess(false) }}>
                                                            <Grid item className={classes.itemStyle}>
                                                                <span
                                                                    className={classes.commitId}
                                                                    onClick={() => navigator.clipboard.writeText((versions.latestCommitId)) && setCopySucess(true)}
                                                                >
                                                                    {(versions.latestCommitId).slice(versions.latestCommitId.length - 8)}
                                                                </span>
                                                            </Grid>
                                                        </Tooltip>
                                                        <Tooltip title={"Click to open the Repository"}>
                                                        <Grid item>
                                                            <a href={versions.commitUrl} target="_blank">
                                                                <OpenInBrowserIcon className={classes.link} />
                                                            </a>
                                                        </Grid>
                                                        </Tooltip>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes.basicTableData}>
                                            <div className={classes.gateLoopContainer}>

                                                {
                                                    versions?.qualityGate
                                                        ?
                                                        qualityGate(versions.qualityGate)
                                                        //getQualityBadge("pass") 
                                                        // <img src={passIcon} className={classes.imgProps}/>
                                                        // <div className={classes.qualityBadgeContainer}>
                                                        //     <Typography className={classes.qualityTitle}>Passed</Typography>
                                                        //     <Typography className={classes.qualitySubTitle}>All Conditions Passed</Typography>
                                                        // </div>
                                                        :
                                                        "-"
                                                }

                                            </div>
                                        </td>
                                        {/* <td className={classes.basicTableData}>
                                            <div className={classes.loopContainer}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Tooltip placement="top" id="placement" title={versions.totalComments}>
                                                            <Typography variant="h6" className={classes.valueStyle} >
                                                                {numberToK(versions.totalComments)}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                <Typography variant="caption" display="block" gutterBottom  className={classes.redClrStyle}>Comments</Typography>
                                            </div>
                                        </td> */}
                                        <td className={classes.basicTableData}>
                                            <div className={classes.loopContainerCenter}>
                                                <Tooltip placement="top" id="placement" title={versions.totalNoOfLines}>
                                                    <Typography variant="h6" className={classes.valueStyle}>
                                                        {numberToK(versions.totalNoOfLines)}
                                                    </Typography>
                                                </Tooltip>
                                                <Typography variant="caption" display="block" gutterBottom className={classes.greenClrStyle}>Lines Of Code</Typography>
                                            </div>
                                        </td>
                                        <td className={classes.basicTableData}>
                                            <div className={classes.loopContainerCenter}>
                                                <Tooltip placement="top" id="placement" title={versions.totalNoOfFiles}>
                                                    <Typography variant="h6" className={classes.valueStyle}>
                                                        {numberToK(versions.totalNoOfFiles)}
                                                    </Typography>
                                                </Tooltip>
                                                <Typography variant="caption" display="block" gutterBottom className={classes.greenClrStyle}>Files</Typography>
                                            </div>
                                        </td>
                                        {/* Bugs Start */}
                                        <td className={classes.basicTableData}>
                                            <table className={classes.basicInnerTable}>
                                                <tr>
                                                    <td className={classes.innerTableData}>
                                                        <Tooltip placement="top" id="placement" title={versions.analysisReport.filter((e) => e.category === "Bugs").map((c) => (c.totalComments))}>
                                                            <Typography variant="h6" className={classes.valueStyle}>
                                                                {
                                                                    versions.analysisReport.filter((e) => e.category === "Bugs").map((c) => (c.totalComments)).length
                                                                        ?
                                                                        versions.analysisReport.filter((e) => e.category === "Bugs").map((c) => (numberToK(c.totalComments)))
                                                                        :
                                                                        ""
                                                                }
                                                            </Typography>
                                                        </Tooltip>
                                                        <Typography variant="caption" display="block" gutterBottom className={classes.redClrStyle}>Comments</Typography>
                                                    </td>
                                                    <td className={classes.innerTableData}>
                                                        <Typography variant="h6" className={classes.valueStyle}>
                                                            {
                                                                versions.analysisReport.filter((e) => e.category === "Bugs").map((c) => (c.coverage)).length
                                                                    ?
                                                                    versions.analysisReport.filter((e) => e.category === "Bugs").map((c) => (c.coverage)) + " %"
                                                                    :
                                                                    "-"
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            gutterBottom
                                                            className={classes.greenClrStyle}
                                                            style={{
                                                                color: colorByPercentage(versions.analysisReport.filter((e) => e.category === "Bugs").map((c) => (c.coverage)))
                                                            }}
                                                        >Quality
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        {/* Bugs End */}
                                        {/* Duplication Start */}
                                        <td className={classes.basicTableData}>
                                            <table className={classes.basicInnerTable}>
                                                <tr>
                                                    <td className={classes.innerTableData}>
                                                        <Tooltip placement="top" id="placement" title={versions.analysisReport.filter((e) => e.category === "Duplications").map((c) => (c.duplications))}>
                                                            <Typography variant="h6" className={classes.valueStyle}>
                                                                {
                                                                    versions.analysisReport.filter((e) => e.category === "Duplications").map((c) => (c.duplications)).length
                                                                        ?
                                                                        versions.analysisReport.filter((e) => e.category === "Duplications").map((c) => (numberToK(c.duplications)))
                                                                        :
                                                                        "-"
                                                                }
                                                            </Typography>
                                                        </Tooltip>
                                                        <Typography variant="caption" display="block" gutterBottom className={classes.redClrStyle}>Duplications</Typography>
                                                    </td>
                                                    <td className={classes.innerTableData}>
                                                        <Typography variant="h6" className={classes.valueStyle}>
                                                            {
                                                                versions.analysisReport.filter((e) => e.category === "Duplications").map((c) => (c.duplicationPercentage)).length
                                                                    ?
                                                                    versions.analysisReport.filter((e) => e.category === "Duplications").map((c) => (c.duplicationPercentage)) + " %"
                                                                    :
                                                                    "-"
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            gutterBottom
                                                            className={classes.greenClrStyle}
                                                            style={{
                                                                color: colorByPercentageDuplication(versions.analysisReport.filter((e) => e.category === "Duplications").map((c) => (c.duplicationPercentage)))
                                                            }}
                                                        >Percentage</Typography>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        {/* Duplication End */}
                                        {/* Security Start */}
                                        <td className={classes.basicTableData}>
                                            <table className={classes.basicInnerTable}>
                                                <tr>
                                                    <td className={classes.innerTableData}>
                                                        <Tooltip placement="top" id="placement" title={versions.analysisReport.filter((e) => e.category === "Security").map((c) => (c.dependenciesScanned))}>
                                                            <Typography variant="h6" className={classes.valueStyle}>
                                                                {
                                                                    versions.analysisReport.filter((e) => e.category === "Security").map((c) => (c.dependenciesScanned)).length
                                                                        ?
                                                                        versions.analysisReport.filter((e) => e.category === "Security").map((c) => (numberToK(c.dependenciesScanned)))
                                                                        :
                                                                        "-"
                                                                }
                                                            </Typography>
                                                        </Tooltip>
                                                        <Typography variant="caption" display="block" gutterBottom className={classes.redClrStyle}>Scanned</Typography>
                                                    </td>
                                                    <td className={classes.innerTableData}>
                                                        <Tooltip placement="top" id="placement" title={versions.analysisReport.filter((e) => e.category === "Security").map((c) => (c.vulnerableDependencies))}>
                                                            <Typography variant="h6" className={classes.valueStyle}>
                                                                {
                                                                    versions.analysisReport.filter((e) => e.category === "Security").map((c) => (c.vulnerableDependencies)).length
                                                                        ?
                                                                        versions.analysisReport.filter((e) => e.category === "Security").map((c) => (numberToK(c.vulnerableDependencies)))
                                                                        :
                                                                        "-"
                                                                }
                                                            </Typography>
                                                        </Tooltip>
                                                        <Typography variant="caption" display="block" gutterBottom className={classes.greenClrStyle}>Dependencies</Typography>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        {/* Security End */}
                                        {/* Suggestion Start */}
                                        <td className={classes.basicTableData}>
                                            <table className={classes.basicInnerTable}>
                                                <tr>
                                                    <td className={classes.innerTableData}>
                                                        <Tooltip placement="top" id="placement" title={versions.analysisReport.filter((e) => e.category === "Suggestion").map((c) => (c.totalComments))}>
                                                            <Typography variant="h6" className={classes.valueStyle}>
                                                                {
                                                                    versions.analysisReport.filter((e) => e.category === "Suggestion").map((c) => (c.totalComments)).length
                                                                        ?
                                                                        versions.analysisReport.filter((e) => e.category === "Suggestion").map((c) => (numberToK(c.totalComments)))
                                                                        :
                                                                        "-"
                                                                }
                                                            </Typography>
                                                        </Tooltip>
                                                        <Typography variant="caption" display="block" gutterBottom className={classes.redClrStyle}>Comments</Typography>
                                                    </td>
                                                    <td className={classes.innerTableData}>
                                                        <Typography variant="h6" className={classes.valueStyle}>
                                                            {
                                                                versions.analysisReport.filter((e) => e.category === "Suggestion").map((c) => (c.coverage)).length
                                                                    ?
                                                                    versions.analysisReport.filter((e) => e.category === "Suggestion").map((c) => (c.coverage)) + "%"
                                                                    :
                                                                    "-"
                                                            }
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className={classes.greenClrStyle}>Coverage</Typography>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        {/* Suggestion End */}

                                        {
                                            // versions.analysisReport.map((ana) => (
                                            //     ana.category !== "Suggestions" 
                                            //     ?
                                            //     <td className={classes.basicTableData}>
                                            //         <table className={classes.basicInnerTable}>
                                            //         <tr>
                                            //             <td className={classes.innerTableData}>
                                            //                 <Grid container direction="row" alignItems="center">
                                            //                     <Grid item>
                                            //                         <Typography variant="h6" className={classes.valueStyle}>
                                            //                             {
                                            //                                 ana.category === 'Bugs' && ana.totalComments || 
                                            //                                 ana.category === 'Duplications' && ana.duplications ||
                                            //                                 ana.category === 'Security' && ana.dependenciesScanned ||
                                            //                                 ana.category === 'Suggestions' && ana.totalComments
                                            //                             }
                                            //                         </Typography>
                                            //                     </Grid>
                                            //                 </Grid>
                                            //                 <Typography variant="caption" display="block" gutterBottom className={classes.redClrStyle}>
                                            //                     {
                                            //                         ana.category === 'Bugs' && 'Total Comments' || 
                                            //                         ana.category === 'Duplications' && 'Duplications' || 
                                            //                         ana.category === 'Security' && 'Scanned' || 
                                            //                         ana.category === 'Suggestions' && 'Total Comments'
                                            //                     }
                                            //                 </Typography>
                                            //             </td>
                                            //             <td className={classes.innerTableData}>
                                            //                 <Grid container direction="row" alignItems="center">
                                            //                     <Grid item>
                                            //                         <Typography variant="h6" className={classes.valueStyle}>
                                            //                             {
                                            //                                 ana.category === 'Bugs' && ana.coverage+" %" || 
                                            //                                 ana.category === 'Duplications' && ana.duplicationPercentage+" %" ||
                                            //                                 ana.category === 'Security' && ana.vulnerableDependencies ||
                                            //                                 ana.category === 'Suggestions' && ana.totalComments
                                            //                             }
                                            //                         </Typography>
                                            //                     </Grid>
                                            //                 </Grid>
                                            //                 <Typography variant="caption" display="block" gutterBottom className={classes.greenClrStyle}>
                                            //                     {
                                            //                         ana.category === 'Bugs' && 'Quality' || 
                                            //                         ana.category === 'Duplications' && 'Percentage' || 
                                            //                         ana.category === 'Security' && 'Dependencies' || 
                                            //                         ana.category === 'Suggestions' && 'Coverage'
                                            //                     }
                                            //                 </Typography>
                                            //             </td>
                                            //         </tr>
                                            //         </table>
                                            //     </td>
                                            //     :
                                            //     ""
                                            // ))
                                        }
                                    </tr>
                                ))
                            }

                        </table>
                    </Paper>
                )
            }}
        </Query>
    )
}

export default RepoAnalysisVersionCompare