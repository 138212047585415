import React, { Component, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { avatarImage } from '../components/service/avatar-image';


function RepoAnalysisUserDetails(props) {
    const userIcon = avatarImage.getImageLocation(props.user.id);
    return (
        <Grid container>
            <Grid item sm={2}>
                {props.user.id ? <img src={userIcon} className="align-self-start" height="100%" /> : <i className="material-icons ml-1 mr-0" style={{ color:"rgb(0, 118, 254)",fontSize:"60px",marginTop:"-5px"}}>account_circle</i>}
            </Grid>
            <Grid item sm={8} className='ml-3 mt-1'>
                <Typography>{props.user.name}</Typography>
                <div className="email-id" style={{ fontSize: 10, color: 'gray', width: '250px' }}>{props.user.emailId}</div>
            </Grid>
        </Grid>
    )
}

export default RepoAnalysisUserDetails;