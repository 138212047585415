import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import moment from 'moment';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap-daterangepicker/daterangepicker.css';
import '../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import '../node_modules/ag-grid-community/dist/styles/ag-theme-material.css';
import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

import Login from './core/login/login';
import Signup from './core/login/signup';
import ForgotPassword from './core/login/forgot-password';
import Main from './qBot/Main';
import clientService from './core/service/clientUri';
import preferenceService from './core/service/preference';
import { loginService } from './core/login/services/login-service';
import SessionContinue from './core/login/session-continue';

import './App.css';
import { ApolloProvider } from 'react-apollo';
import Idle from 'react-idle'
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
const darkTheme = createMuiTheme({
    palette: {
      type: 'light',
    },
  });
var jwt = require('jsonwebtoken');
const client = clientService.getClienUri();
const accessToken = preferenceService.getValue('user', 'local').accessToken;
var decoded = jwt.decode(accessToken, { complete: true });
var time = decoded && decoded.payload && decoded.payload.exp * 1000;
var isIdle = false;

function setIdleState(data) {
    isIdle = data;
}

function getIdleState() {
    return isIdle;
} 
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionModal: false
        }
        this.handleRefresh = this.handleRefresh.bind(this);
        this.refreshInitMethod = this.refreshInitMethod.bind(this);
        if (this.props.location.pathname !== "/signup" && this.props.location.pathname !== "/forgot-password") {
            this.refreshInitMethod();
        }

    }

    handleRefresh() {
        loginService.refreshToken(() => {
            const accessToken = preferenceService.getValue('refreshToken', 'local').accessToken;
            var decoded = jwt.decode(accessToken, { complete: true });
            time = decoded && decoded.payload && decoded.payload.exp * 1000;
            this.refreshInitMethod();
        });
        this.setState({
            sessionModal: false
        });
    }

    refreshInitMethod() {
        this.interval = setInterval(() => {
            var timeCal = time - 1000 * 60 * 2;
            if (moment.now() < time) {
                if (moment.now() > timeCal) {
                    if (getIdleState()) {
                        this.setState({
                            sessionModal: true
                        })
                    } else {
                        this.handleRefresh();
                    }
                    clearInterval(this.interval);
                }
            } else { 
                clearInterval(this.interval);   
            }
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidMount = () => window.scrollTo(0, 0);

    componentDidUpdate = prevProps => {
      if (this.props.location.pathname !== prevProps.location.pathname) window.scrollTo(0, 0);
    };

    render() {
        return (
            <ThemeProvider theme={darkTheme}>
            <ApolloProvider client={client} component={Main}>
                <div>
                    <Idle
                        timeout={1000 * 60 * 1}
                        render={({ idle }) => { setIdleState(idle); return null }}
                    />
                    <Switch>
                        <Route exact path="/" component={Login} />,
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/signup" component={Signup} />
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route component={Main} />
                    </Switch>
                </div>
                {this.state.sessionModal && <SessionContinue showModal={this.state.sessionModal} />}
            </ApolloProvider>
            </ThemeProvider>
        );
    }
}

export default withRouter(App);
