import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Checkbox, ListItemText, Input, Tooltip } from '@material-ui/core';
import PropTypes, { func } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Grid from '@material-ui/core/Grid';
import ViewSslCertificate from './ViewSslCertificate';

const UPDATE_ORG = gql`
mutation updateOrganization(
    $id: Long,
    $organizationName: String!,
	$organizationDescription: String,
	$reviewersGroupId: String, 
	$developersGroupId: String, 
	$repositoryInfo: [RepositoryInput], 
    $workflowInfo: [WorkflowInput],
    $pushNotification: Boolean,
    $pluginToken:String) 
    {
        updateOrganization(
            id: $id,
            organizationName: $organizationName
            organizationDescription: $organizationDescription
            reviewersGroupId: $reviewersGroupId
            developersGroupId: $developersGroupId
            repositoryInfo: $repositoryInfo
            workflowInfo: $workflowInfo
            pushNotification: $pushNotification
            pluginToken: $pluginToken
        )
  }
`;

const RESET_ORG_PLUGIN_TOKEN = gql`
mutation resetOrganizationPluginToken(
    $pluginTokenResetFlag: Boolean)
    {
        resetOrganizationPluginToken(
            pluginTokenResetFlag: $pluginTokenResetFlag,
        )
    }
`;

const GET_USER_ORGANIZATION = gql`
query organizationById($id: Long!) {
    organizationById(id: $id) {
        id
        repositoryInfo {
            userOrganizationMapping
        }
    }
}
`;

const useStyles = makeStyles(theme => ({
    editButton: {
        top: '13px',
        marginLeft: '3px'
    },
    openIcon: {
        color: '#3f51b5',
        cursor: 'pointer'
    },
    label: {
        color: 'gray',
        fontSize: '11px'
    },
    errorMsg: {
        color: 'red',
        marginTop: '2px'
    },
    fileName: {
        marginTop: '2px'
    }
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const names = [
    'STASH',
    'GITLAB',
    'AZUREGIT'
];

const workflowNames = [
    'JIRA',
    'REDMINE'
];

export default function UpdateOrganization(props) {

    const fileRef = React.useRef();
    const [deleteFlag, setDeleteFlag] = React.useState(false)
    const classes = useStyles();
    const [removeFlag, setRemoveFlag] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [sslCertificateFlag, setSslCertificateFlag] = React.useState(false);
    const form = React.useRef(null);
    const [organization, setOrganization] = React.useState(props.data);
    const [errorMsg, setErrorMsg] = React.useState("")
    const [repoName, setRepoName] = React.useState(
        props.data.repositoryInfo && props.data.repositoryInfo
            ? props.data.repositoryInfo.map(e => e.repository)
            : []
    );
    const [workflowName, setWorkflowName] = React.useState(
        props.data.workflowInfo && props.data.workflowInfo
            ? props.data.workflowInfo.map(e => e.workflow)
            : []
    );
    const [selectedTab, setSelectedTab] = React.useState(
        props.data.repositoryInfo && props.data.repositoryInfo.length
            ? 0
            : null
    );

    const [workflowSelectedTab, setWorkflowSelectedTab] = React.useState(
        props.data.workflowInfo && props.data.workflowInfo.length
            ? 0
            : null
    );

    const [pluginData, setPluginData] = React.useState({
        pluginTokenResetFlag: true,
        pluginToken: ""
    });

    React.useEffect(() => {
        var organizationData = organization;
        organizationData.repositoryInfo.map((data, i) => {
            if (data.sslCertificate !== null) {
                setSslCertificateFlag(true)
            }
        })

    }, [organization])

    const handleChange = (e) => {
        var organizationData = organization;
        organizationData[e.target.name] = e.target.value;
        setOrganization({ ...organizationData });
    }

    const handleRepoChanges = (e, i) => {
        var organizationData = organization;
        organizationData.repositoryInfo[i][e.target.name] = e.target.value;
        setOrganization({ ...organizationData });
    }

    const handleWorkflowChanges = (e, i) => {
        var organizationData = organization;
        organizationData.workflowInfo[i][e.target.name] = e.target.value;
        setOrganization({ ...organizationData });
    }

    function handleSelectChange(e) {
        let repos = e.target.value;
        const orgData = organization;
        orgData.repositoryInfo = repos.map((data) => {
            var repoInfo = {
                repository: data
            };
            if (orgData.repositoryInfo && orgData.repositoryInfo.length) {
                orgData.repositoryInfo.forEach(item => {
                    if (item.repository === data) {
                        repoInfo = item;
                    }
                })
            } else {
                orgData.repositoryInfo = [];
            }
            return repoInfo;

        })

        setOrganization({ ...orgData });
        setRepoName(repos);
        setSelectedTab(0);
    }

    function handleSelectChangeworkflow(e, i) {
        let workflows = e.target.value;
        const orgData = organization;
        orgData.workflowInfo = workflows.map((data) => {
            var workInfo = {
                workflow: data
            };
            if (orgData.workflowInfo && orgData.workflowInfo.length) {
                orgData.workflowInfo.forEach(item => {
                    if (item.workflow === data) {
                        workInfo = item;
                    }
                })
            } else {
                orgData.workflowInfo = [];
            }
            return workInfo;

        })

        setOrganization({ ...orgData });
        setWorkflowName(workflows);
        setWorkflowSelectedTab(0);
    }

    function handleTabChange(e, i) {
        setSelectedTab(i);
    }

    function handleWorkflowTabChange(e, i) {
        setWorkflowSelectedTab(i);
    }

    function handleDeleteToken() {
        setDeleteFlag(true);
        setPluginData({ pluginTokenResetFlag: true, pluginToken: "" });

        var organizationData = organization;
        organizationData.pluginToken = "";
        setOrganization({ ...organizationData });
    }

    const getCheckBoxValue = (e, i) => {
        var organizationData = organization;
        organization.repositoryInfo[i].userOrganizationMapping = organization.repositoryInfo[i].userOrganizationMapping ? false : true;
        organizationData.repositoryInfo[i][e.target.name] = organization.repositoryInfo[i].userOrganizationMapping;
        setOrganization({ ...organizationData });
    };

    const getCheckBoxValueRemoteComments = (e, i) => {
        var organizationData = organization;
        organization.repositoryInfo[i].syncRemoteReviewComments = organization.repositoryInfo[i].syncRemoteReviewComments ? false : true;
        organizationData.repositoryInfo[i][e.target.name] = organization.repositoryInfo[i].syncRemoteReviewComments;
        setOrganization({ ...organizationData });
    };

    const getCheckValueNotification = (e) => {
        let organizationData = organization;
        organizationData[e.target.name] = e.target.checked;
        setOrganization({ ...organizationData });
    };

    const getWorkflowCheckBoxValue = (e, i) => {
        var organizationData = organization;
        organization.workflowInfo[i].defectTracking = organization.workflowInfo[i].defectTracking ? false : true;
        organizationData.workflowInfo[i][e.target.name] = organization.workflowInfo[i].defectTracking;
        setOrganization({ ...organizationData });
    };

    const getCheckBoxValueRepositoryFullScan = (e, i) => {
        var organizationData = organization;
        organization.repositoryInfo[i].repoScanFlag = organization.repositoryInfo[i].repoScanFlag ? false : true;
        organizationData.repositoryInfo[i][e.target.name] = organization.repositoryInfo[i].repoScanFlag;
        setOrganization({ ...organizationData });
    };

    const handleRemoveFlag = (e, i, data) => {
        setRemoveFlag(data);
        var organizationData = organization;
            organizationData.repositoryInfo[i].sslCertificate = null;
            organizationData.repositoryInfo[i].sslCertificateEnabled = false;
            setOrganization({ ...organizationData });
        setSslCertificateFlag(false);
    }

    const handleDialogOpenClose = (data) => (e) => {
        setDialogOpen(data)
    }

    const handleFileChange = async (e, i) => {
        setErrorMsg("");
        setRemoveFlag(false);
        var supportedFile = ["txt"];
        var sslCertificateId = document.getElementById("sslCertificate");
        var Extension = sslCertificateId.value.substring(sslCertificateId.value.lastIndexOf('.') + 1).toLowerCase();
        if (supportedFile.indexOf(Extension) <= -1) {
            setErrorMsg("Please Upload only txt extension file");
            return false;
        }
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0]);
        fileReader.onload = e => {
            var organizationData = organization;
            organizationData.repositoryInfo[i].sslCertificate = e.target.result;
            organizationData.repositoryInfo[i].sslCertificateEnabled = true;
            setOrganization({ ...organizationData });
        };
    };

    return (
        <div>
            <Mutation mutation={UPDATE_ORG} onCompleted={() => { props.handleClose(); }}>
                {(updateOrganization, { loading, error, data }) => {
                    if (error || (data && !data.createOrganization)) {
                        return null
                    }
                    return (
                        <ValidatorForm id="projectAdd"
                            ref={form}
                            onSubmit={e => {
                                var orgInfo = organization;
                                delete orgInfo['repositoryInfo']['__typename'];
                                updateOrganization({ variables: { ...orgInfo } })
                            }}
                            instantValidate
                        >
                            <Dialog
                                open={props.open}
                                fullWidth={true}
                                onClose={props.handleClose}
                                maxWidth={"sm"}
                                aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Organization</DialogTitle>
                                <DialogContent>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="">
                                                <TextValidator type="text" name="organizationName"
                                                    label="Organization name"
                                                    validators={['required']}
                                                    errorMessages={['This field is Required']}
                                                    value={organization.organizationName}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="">
                                                <TextValidator type="text" name="organizationDescription"
                                                    label="Organization Description"
                                                    validators={[]}
                                                    errorMessages={[]}
                                                    value={organization.organizationDescription}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-md-6">
                                            <div className="">
                                                <TextValidator type="text" name="reviewersGroupId"
                                                    label="Reviewers Group Id"
                                                    validators={[]}
                                                    errorMessages={[]}
                                                    value={organization.reviewersGroupId}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="">
                                                <TextValidator type="text" name="developersGroupId"
                                                    label="Developer group"
                                                    validators={[]}
                                                    errorMessages={[]}
                                                    value={organization.developersGroupId}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row my-4">
                                        <div className="col-md-6">
                                            <FormControl className="project mb3">
                                                <InputLabel>Repository</InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={repoName}
                                                    onChange={handleSelectChange}
                                                    input={<Input />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {names.map((name) => (
                                                        <MenuItem key={name} value={name}>
                                                            <Checkbox checked={repoName.indexOf(name) > -1} />
                                                            <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div>
                                        <AppBar position="static" color="default" className="shadow-none">
                                            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="simple tabs example">
                                                {
                                                    repoName.map((data) => {
                                                        return (<Tab label={data} />)
                                                    })
                                                }
                                            </Tabs>
                                        </AppBar>
                                        {
                                            repoName.map((data, i) => {
                                                return (
                                                    <TabPanel value={selectedTab} index={i}>
                                                        <div className="col-md-12 p-0 mt-3">
                                                            <h6 className="text-capitalize">{`${data.toLowerCase()} configuration`}</h6>
                                                        </div>
                                                        <div className="row my-4">
                                                            <div className="col-md-6">
                                                                <TextValidator type="text" name="host"
                                                                    label={`Host`}
                                                                    validators={['required']}
                                                                    errorMessages={['This field is Required']}
                                                                    value={organization.repositoryInfo[i].host}
                                                                    onChange={(e) => handleRepoChanges(e, i)}
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <TextValidator type="text" name="port"
                                                                    label={`Port`}
                                                                    validators={[]}
                                                                    errorMessages={[]}

                                                                    value={organization.repositoryInfo[i].port}
                                                                    onChange={(e) => handleRepoChanges(e, i)}
                                                                />
                                                            </div>

                                                            <div className="col-md-6 my-2">
                                                                <TextValidator type="text" name="scheme"
                                                                    label={`Scheme`}
                                                                    validators={['required']}
                                                                    errorMessages={['This field is Required']}
                                                                    value={organization.repositoryInfo[i].scheme}
                                                                    onChange={(e) => handleRepoChanges(e, i)}
                                                                />
                                                            </div >

                                                            <div className="col-md-6 my-2">
                                                                {organization?.repositoryInfo[i]?.sslCertificate?.length
                                                                    &&
                                                                    sslCertificateFlag
                                                                    &&
                                                                    !removeFlag
                                                                    ?
                                                                    <Grid container spacing={2} alignItems="flex-end">
                                                                        <Grid item>
                                                                            <TextValidator type="text" name="sslCertificate"
                                                                                label={`SSL Certificate`}
                                                                                value={organization.repositoryInfo[i].sslCertificate}
                                                                                readOnly={true}
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Tooltip title='Click to view the SSL Certificate' placement="top">
                                                                                <OpenInNewIcon
                                                                                    className={classes.openIcon}
                                                                                    onClick={handleDialogOpenClose(false)}
                                                                                />
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    <>
                                                                        <label className={classes.label}>SSL Certificate</label><br />
                                                                        <input
                                                                            ref={fileRef}
                                                                            style={{ display: "none" }}
                                                                            id="sslCertificate"
                                                                            type="file"
                                                                            onChange={(e) => handleFileChange(e, i)}
                                                                            accept="text/*"
                                                                            disabled={true}
                                                                        />
                                                                        <Button
                                                                            variant="contained"
                                                                            component="label"
                                                                            color='primary'
                                                                            style={{ textTransform: 'none' }}
                                                                            onClick={() => fileRef.current?.click()}
                                                                            disabled={true}
                                                                        >
                                                                            Choose File
                                                                        </Button>
                                                                        {errorMsg && <Typography variant='body2' className={classes.errorMsg}> {errorMsg} </Typography>}
                                                                    </>
                                                                }
                                                                {!removeFlag && sslCertificateFlag ?
                                                                    <Button className={classes.editButton} disabled={true} variant="contained" onClick={(e) => handleRemoveFlag(e, i, true)} color={!removeFlag ? "primary" : "default"}>
                                                                        Remove
                                                                    </Button>
                                                                    :
                                                                    ''
                                                                }
                                                                {dialogOpen && <ViewSslCertificate dialogOpen={dialogOpen} data={organization.repositoryInfo[i].sslCertificate} onClose={handleDialogOpenClose(false)} />}
                                                            </div >
                                                        </div>
                                                        {organization.id &&
                                                            <div>
                                                                <label>
                                                                    <Checkbox
                                                                        name="userOrganizationMapping"
                                                                        color="primary"
                                                                        defaultChecked={organization.repositoryInfo[i].userOrganizationMapping}
                                                                        value={organization.repositoryInfo[i].userOrganizationMapping}
                                                                        onChange={(e) => getCheckBoxValue(e, i)} />
                                                                    {data === 'GITLAB' ?
                                                                        'Upload User Organization Mapping Name'
                                                                        : 'Upload User Organization Mapping ID'}
                                                                </label>
                                                            </div>
                                                        }
                                                        <div >
                                                            <label>
                                                                <Checkbox
                                                                    name="syncRemoteReviewComments"
                                                                    color="primary"
                                                                    defaultChecked={organization.repositoryInfo[i].syncRemoteReviewComments}
                                                                    value={organization.repositoryInfo[i].syncRemoteReviewComments}
                                                                    onChange={(e) => getCheckBoxValueRemoteComments(e, i)} />
                                                                Sync Remote Review Comments
                                                            </label>
                                                        </div >
                                                        <div >
                                                            <label>
                                                                <Checkbox
                                                                    name="repoScanFlag"
                                                                    color="primary"
                                                                    defaultChecked={organization.repositoryInfo[i].repoScanFlag}
                                                                    value={organization.repositoryInfo[i].repoScanFlag}
                                                                    onChange={(e) => getCheckBoxValueRepositoryFullScan(e, i)} />
                                                                Repository Full Scan
                                                            </label>
                                                        </div >
                                                    </TabPanel>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="row my-4">
                                        <div className="col-md-6">
                                            <FormControl className="project mb3">
                                                <InputLabel>Workflow</InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={workflowName}
                                                    onChange={handleSelectChangeworkflow}
                                                    input={<Input />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {workflowNames.map((name) => (
                                                        <MenuItem key={name} value={name}>
                                                            <Checkbox checked={workflowName.indexOf(name) > -1} />
                                                            <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div>
                                        <AppBar position="static" color="default" className="shadow-none">
                                            <Tabs value={workflowSelectedTab} onChange={handleWorkflowTabChange} aria-label="simple tabs example">
                                                {
                                                    workflowName.map((data) => {
                                                        return (<Tab label={data} />)
                                                    })
                                                }
                                            </Tabs>
                                        </AppBar>
                                        {
                                            workflowName.map((data, i) => {
                                                return (
                                                    <TabPanel value={workflowSelectedTab} index={i}>
                                                        <div className="col-md-12 p-0 mt-3">
                                                            <h6 className="text-capitalize">{`${data.toLowerCase()} configuration`}</h6>
                                                        </div>
                                                        <div className="row my-4">
                                                            <div className="col-md-6">
                                                                <TextValidator type="text" name="workflowUserId"
                                                                    label={`User Id`}
                                                                    validators={['required']}
                                                                    errorMessages={['This field is Required']}
                                                                    value={organization.workflowInfo[i].workflowUserId}
                                                                    onChange={(e) => handleWorkflowChanges(e, i)}
                                                                />


                                                            </div>
                                                            <div className="col-md-6">
                                                                <TextValidator type="password" name="workflowPassword"
                                                                    label={`Password`}
                                                                    validators={['required']}
                                                                    errorMessages={['This field is Required']}

                                                                    value={organization.workflowInfo[i].workflowPassword}
                                                                    onChange={(e) => handleWorkflowChanges(e, i)}
                                                                />
                                                            </div>

                                                            <div className="col-md-6 my-2">
                                                                <TextValidator type="text" name="workflowBaseURL"
                                                                    label={`Base URL`}
                                                                    validators={['required']}
                                                                    errorMessages={['This field is Required']}
                                                                    value={organization.workflowInfo[i].workflowBaseURL}
                                                                    onChange={(e) => handleWorkflowChanges(e, i)}
                                                                />
                                                            </div >

                                                            <div className="col-md-6 my-2">
                                                                <br />
                                                                <label>
                                                                    <Checkbox
                                                                        name="defectTracking"
                                                                        color="primary"
                                                                        defaultChecked={organization.workflowInfo[i].defectTracking}
                                                                        value={organization.workflowInfo[i].defectTracking}
                                                                        onChange={(e) => getWorkflowCheckBoxValue(e, i)} />
                                                                    Defect Tracking
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="row my-12">
                                        <div className="col-md-12">
                                            <hr />
                                            <h6>{'Notification :'}</h6>
                                            <br />
                                            <div>
                                                <label>
                                                    <Checkbox
                                                        name="pushNotification"
                                                        color="primary"
                                                        defaultChecked={organization.pushNotification}
                                                        onChange={(e) => getCheckValueNotification(e)} />
                                                    {'Push Notification'}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <Mutation mutation={RESET_ORG_PLUGIN_TOKEN}>
                                        {
                                            (tokenResetFlag, { loading, error, data }) => {

                                                if (data?.resetOrganizationPluginToken) {
                                                    if (pluginData.pluginTokenResetFlag && !deleteFlag) {
                                                        setPluginData({ pluginTokenResetFlag: false, pluginToken: data.resetOrganizationPluginToken });
                                                        var organizationData = organization;
                                                        organizationData.pluginToken = data.resetOrganizationPluginToken;
                                                        setOrganization({ ...organizationData });
                                                        setDeleteFlag(true)
                                                    }

                                                }

                                                return (
                                                    <div className="row my-4">
                                                        <div className="col-md-6">
                                                            <hr />
                                                            <h6>{'Plugin Token :'}</h6>
                                                            <br />
                                                            <TextValidator
                                                                type="text"
                                                                name="pluginToken"
                                                                label={`Read Only`}
                                                                validators={['required']}
                                                                errorMessages={['This field is Required']}
                                                                value={(pluginData.pluginToken ? pluginData.pluginToken : organization.pluginToken) ? (pluginData.pluginToken ? pluginData.pluginToken : organization.pluginToken) : ""}
                                                                variant="outlined"
                                                                InputProps={{ readOnly: true }}
                                                            >
                                                            </TextValidator>
                                                        </div>
                                                        <hr />
                                                        <div className="col-md-6 my-2 button-down">

                                                            {
                                                                pluginData.pluginToken || organization.pluginToken
                                                                    ?
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleDeleteToken()

                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (data) {
                                                                                setDeleteFlag(false)
                                                                            }
                                                                            tokenResetFlag({ variables: { pluginTokenResetFlag: true } });
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                    </Mutation>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={props.handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button variant="contained" onClick={() => {
                                        form.current.submit()
                                    }} color="primary">
                                        Save
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </ValidatorForm>
                    )
                }}
            </Mutation>
        </div>
    );
}
