import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbars from '../../../../core/error/Snackbars';
import preferenceService from '../../../../core/service/preference';
import { Typography } from '@material-ui/core';

const Update_User_Mail_Id = gql`
mutation updateUserMailId($userId: Long!, $email: String!) {
    updateUserMailId(
        userId: $userId,
        email: $email,
    )
}
`;

const Get_Domain_Names = gql`
query domainNames {
    domainNames
}
`;

const user = preferenceService.getValue('users', 'local');
class UpdateUserMailId extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: props.userData ? props.userData.id : null,
            email: this.props.userData.email,
            mailDomainValid: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
    }

    toggleSnackbars() {
        this.setState({
            showSnackbar: false
        })
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        this.setState({
            valid: ""
        })
    }

    handleCheck(){
        this.setState({
            mailDomainValid: "Mail-Id is not matching with our domain pattern, Please contact administrator"
        })
    }

    render() {
        var mailDomain = [];
        return (
            <div className="w-100">
                <Query query={Get_Domain_Names}>
                    {({ loading, error, data, refetch }) => {
                        if (data) {
                            mailDomain = data.domainNames
                        }
                        return (
                            <Mutation mutation={Update_User_Mail_Id}>
                                {(UpdateUserMailID, { loading, error, data }) => (
                                    <ValidatorForm id="email" name="form" onSubmit={e => {
                                        e.preventDefault();
                                        this.setState({
                                            mailDomainValid: ""
                                        });
                                        mailDomain.includes(this.state.email.split('@').pop()) == true
                                            ?
                                            UpdateUserMailID({ variables: { ...this.state } })
                                            :
                                            this.handleCheck()
                                    }}>
                                        {error && <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={this.toggleSnackbars} />}
                                        {data &&
                                            <Snackbars status="success" message='Updated Mail ID Successfully' showSnackbar={true} closeSnacker={true} />
                                        }
                                        <div className="col-md-7 mb-4">
                                            <TextValidator type="text"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                label="Enter registered Mail-ID"
                                                helperText={"Example: username@domain.com"}
                                                validators={['required']}
                                                errorMessages={['This field is Required']} />
                                        </div>
                                        <div className="col-md-5">
                                            <Button color="primary" type="submit" variant="contained">
                                                {loading ? <CircularProgress disableShrink size={24} /> : 'Submit'}
                                            </Button>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <Typography style={{ color: "red" }}>{this.state.mailDomainValid}</Typography>
                                        </div>
                                    </ValidatorForm>
                                )}
                            </Mutation>
                        )
                    }}
                </Query>

            </div>
            // <div className="w-100">
            //     <Mutation mutation={Update_User_Mail_Id}>
            //         {(UpdateUserMailID, { loading, error, data }) => (
            //             <ValidatorForm id="email" name="form" onSubmit={e => {
            //                 e.preventDefault();
            //                 this.setState({
            //                     mailDomainValid: ""
            //                 });
            //                 mailDomain.includes(this.state.email.split('@').pop()) == true 
            //                 ?
            //                 UpdateUserMailID({ variables: { ...this.state } })
            //                 :
            //                 this.handleCheck()
            //             }}>
            //                 {error && <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={this.toggleSnackbars} />}
            //                 {data &&
            //                     <Snackbars status="success" message='Updated Mail ID Successfully' showSnackbar={true} closeSnacker={true} />
            //                 }
            //                 <div className="col-md-7 mb-4">
            //                     <TextValidator type="text"
            //                         name="email"
            //                         value={this.state.email}
            //                         onChange={this.handleChange}
            //                         label="Enter registered Mail-ID"
            //                         helperText={"Example: username@domain.com"}
            //                         validators={['required']}
            //                         errorMessages={['This field is Required']} />
            //                 </div>
            //                 <div className="col-md-5">
            //                     <Button color="primary" type="submit" variant="contained">
            //                         {loading ? <CircularProgress disableShrink size={24} /> : 'Submit'}
            //                     </Button>
            //                 </div>
            //                 <div className="col-md-12 mt-2">
            //                     <Typography style={{ color: "red" }}>{this.state.mailDomainValid}</Typography>
            //                 </div>
            //             </ValidatorForm>
            //         )}
            //     </Mutation>
            // </div>
        );
    }
}

export default UpdateUserMailId;