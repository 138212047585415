import React, { Component } from 'react';
import Auth from '../../core/service/auth';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import AnalysisReportCategoryFailedMessage from './AnalysisReportCategoryFailedMessage';
import RepoAnalysisActions from './RepoAnalysisActions';
import { withStyles } from '@material-ui/core/styles';

var user = JSON.parse(localStorage.getItem('users'));
var snackbarMessage = ''; 

const useStyles = theme => ({
    rotateIcon: {
        animation: "spin 1s infinite linear"
    },    
});

class IconButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorPopup: false,
            actionDialogOpen: false,
            resumbmitUserId: '',
            categoryChecked: '',
            resumbmitRequestId: '',
            categoryComments: '',
            actionName: '',
            message: ''
        }
    }
    failedMessages = () => {
        this.setState({ errorPopup: !this.state.errorPopup });
    }

    handleResubmitOpen = () => {
        this.setState({ actionDialogOpen: !this.state.actionDialogOpen })
        this.setState({ actionName: 'resubmit' })
    }

    handleStopOpen = () => {
        this.setState({ actionDialogOpen: !this.state.actionDialogOpen })
        this.setState({ actionName: 'stop' })
    }

    handlePauseOpen = () => {
        this.setState({ actionDialogOpen: !this.state.actionDialogOpen })
        this.setState({ actionName: 'pause' })
    }

    handlePlayOpen = () => {
        this.setState({ actionDialogOpen: !this.state.actionDialogOpen })
        this.setState({ actionName: 'play' })
    }

    handleActionClose = (data = false, msg) => {
        this.setState({ actionDialogOpen: false });
        snackbarMessage = msg
    }

    handleRefresh = () => {
        this.props.refetch();
        this.props.handleRefresh(true)
    }

    popupColose = () => {
        this.setState({ errorPopup: false })
    }

    handleClose = () => {
        this.props.handleVersionCompareComponent();
        this.props.handleSecurityTreeViewComponent();
        this.props.handleDuplicationComponent();
    }

    handleCatSnackbar = (data) => {
        this.props.handleCatSnackbar(data, snackbarMessage);
    }

    handleResumbmitUserId = (data) => {
        this.setState({ resumbmitUserId: data })
    }

    handleCategoryChecked = (data) => {
        this.setState({ categoryChecked: data })
    }

    handleResumbmitRequestId = (data) => {
        this.setState({ resumbmitRequestId: data })
    }

    handleCategoryComments = (data) => {
        this.setState({ categoryComments: data })
    }

    handleInProgress = (data) => {
        this.setState({ scanInProgress: data })
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container justify="flex-end">
                {
                    !this.props.showDashboardComponent &&
                    this.props?.messageDetail?.length > 0 &&
                    <Tooltip
                        className=""
                        title="Scaning Failure Message"
                        placement="top">
                        <i
                            className="material-icons text-danger mr-3"
                            onClick={() => this.failedMessages()}
                        >
                            error
                        </i>
                    </Tooltip>
                }
                {
                    Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']) &&
                        !this.props.showDashboardComponent &&
                        (this.props.reqData.status === "Completed") ||
                        (this.props.reqData.status === "Cancelled") ||
                        (this.props.reqData.status === "Failed")
                        ?
                        <Grid item >
                            <Tooltip
                                title="Resubmit"
                                placement="top"
                            >
                                <i
                                    className="material-icons text-primary mr-3 rightSideIcons"
                                    onClick={() => { this.handleResubmitOpen() }}
                                >
                                    flip_camera_android
                                </i>
                            </Tooltip>
                        </Grid>
                        :
                        null
                }
                {/* {
                    this.props.reqData.status === "Failed"
                    &&
                    <Grid item >
                        <Tooltip
                            title="Play"
                            placement="top">
                            <i
                                className="material-icons mr-3 playCircle rightSideIcons"
                                onClick={() => { this.handlePlayOpen() }}
                            >
                                play_circle
                            </i>
                        </Tooltip>
                    </Grid>
                }
                {
                    this.props.reqData.status === "In Progress"
                    &&
                    <Grid item >
                        <Tooltip
                            title="Stop"
                            placement="top">
                            <i
                                className="material-icons mr-3 stopCircle rightSideIcons"
                                onClick={() => this.handleStopOpen()}
                            >
                                stop_circle
                            </i>
                        </Tooltip>
                    </Grid>
                }
                {
                    this.props.reqData.status === "In Progress"
                    &&
                    <Grid item >
                        <Tooltip
                            title="Pause"
                            placement="top">
                            <i
                                className="material-icons mr-3 pauseCircle rightSideIcons"
                                onClick={() => { this.handlePauseOpen() }}
                            >
                                pause_circle
                            </i>
                        </Tooltip>
                    </Grid>
                } */}
                {
                    this.props.currentStatus !== "In Progress" &&
                    !this.props.showDashboardComponent &&
                    <Grid item >
                        <Tooltip
                            className="refresh"
                            title="Refresh"
                            placement="top">
                            <i
                                className="material-icons text-primary mr-3 rightSideIcons"
                                onClick={() => { this.handleRefresh() }}
                            >
                                refresh
                            </i>
                        </Tooltip>
                    </Grid>
                }
                {
                    !this.props.showDashboardComponent &&
                    this.props.currentStatus === "In Progress"
                    &&
                    <Grid item>
                        <Tooltip
                            className="refresh"
                            title="The auto refresh timer is set to 30 seconds once in the process."
                            placement="top">
                            <i
                                className={` ${ classes.rotateIcon } material-icons text-primary mr-3 rightSideIcons`}
                            >
                                refresh
                            </i>
                        </Tooltip>
                    </Grid>
                }
                {
                    !this.props.showDashboardComponent &&
                    <Grid item >
                        <Tooltip
                            title="Close"
                            placement="top">
                            <Link
                                to={{
                                    pathname: `/fullscans`,
                                    style: { textDecoration: 'none' },
                                    state: { fromClose: true }
                                }}
                            >
                                <i
                                    className="material-icons text-primary rightSideIcons"
                                >
                                    clear
                                </i>
                            </Link>
                        </Tooltip>
                    </Grid>
                }
                {
                    this.props.showDashboardComponent &&
                    ((this.props.showDuplicationComponent) ||
                        (this.props.showVersionComponent) ||
                        (this.props.showSecurityComponent)) &&
                    <Grid item >
                        <Tooltip
                            title="Close"
                            placement="top"
                        >
                            <i
                                onClick={this.handleClose}
                                className="material-icons text-primary rightSideIcons"
                                alt="duplication"
                            >
                                clear
                            </i>
                        </Tooltip>
                    </Grid>}
                {
                    this.state.errorPopup
                    &&
                    <AnalysisReportCategoryFailedMessage
                        popupClose={this.popupColose}
                        messageDetail={this.props.messageDetail}
                    />
                }
                {
                    this.state.actionDialogOpen
                    &&
                    <RepoAnalysisActions
                        showModal={this.state.actionDialogOpen}
                        actionName={this.state.actionName}
                        userId={this.props.reqData.requestedUser.id}
                        scanId={this.props.reqData.id}
                        onClose={this.handleActionClose}
                        category={this.props.reqData.repoScanCategory}
                        getCategory={this.handleCategoryChecked}
                        inProgress={this.handleInProgress}
                        categoryComments={this.handleCategoryComments}
                        requestId={this.handleResumbmitRequestId}
                        requestedUser={this.handleResumbmitUserId}
                        snackbarsOpen={this.handleCatSnackbar}
                        refresh={() => this.props.refetch()}
                    />

                }
            </Grid>
        );
    }
}

export default withStyles(useStyles)(IconButton);