import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import { vulnerablityColor, qualityGateColor } from '../helper/helper';
import HelpIcon from '@material-ui/icons/Help';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0),
        borderRadius: 10
    },
    card: {
        maxWidth: 400,
        minHeight: 200,
        margin: `${theme.spacing(0)}px auto`,
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    title: {
        fontWeight: 'bold',
        marginTop: 10,
        fontSize: 23
    },
    vCount: {
        marginTop: "-4px",
        color: '#D30B34',
        fontSize: '17px',
    },
    dCount: {
        marginTop: "-8px",
        color: '#0076FE',
        fontSize: '13.5px',
    },
    totalVulnerablities: {
        marginTop: "-20px",
        fontSize: 40,
        fontWeight: 'bold',

    },
    dependencies: {
        paddingRight: '200px',
        marginTop: "-10.5px",
        fontSize: 20,
        fontWeight: 'bold',
    },
    description: {
        minHeight: 80,
        minWidth: 80,
        fontSize: '12px'
    },
    headTableData: {
        borderCollapse: 'separate',
        width: '100% !important',
        margin: '0px'
    }
}));

const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            h5: {
                fontSize: [22, "!important"]
            }
        }
    }
});

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: '#797979',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

function RepoAnalysisReportSecurity(props) {
    const classes = useStyles();

    const handleSecurityOpen = () => {
        props.hideSecurityTreeComponent(true)
    }
    return (   
        <Card elevation={0} className={classes.root}>
            <CardActionArea className={classes.card}
                style={{ borderBottom: props.data.status === 'Completed' && '2px solid ' + qualityGateColor(props.data.securityQualityGate) }}
                disabled={props.data.status === "Completed" ? false : true} 
                onClick={() => {
                    {
                        props.data.status === 'Completed' && (props?.data?.dependenciesScanned !== 0 || props?.data?.vulnerableDependencies !== 0 || props?.data?.vulnerabilitiesFound !== 0 ) && handleSecurityOpen()
                    }
                }}>
                <table className={classes.headTableData}>
                    <tr>
                        <td style={{ width: '5%' }}>
                            <i className="material-icons reportIcon" style={{ color: '#4A10C6' }}>lock</i>
                        </td>
                        <td style={{ width: '70%', paddingBottom: '7px' }}>
                            <ThemeProvider theme={theme}>
                                <Typography className={classes.title} variant="h5">
                                    {props.data.category}
                                </Typography>
                            </ThemeProvider>
                        </td>
                        <td style={{ width: '5%' }} className='mt-2 pt-2'>
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">Security</Typography>
                                        {
                                            props.data.qualityGateStatusRange.map((statusRange, index) => (
                                                <>
                                                    {
                                                        <Typography>
                                                            <i className="material-icons align-middle" style={{ fontSize: "bold", color: qualityGateColor(statusRange.qualityGateStatus) }}>horizontal_rule</i>
                                                            <span> : {statusRange.qualityGateRange}</span>
                                                        </Typography>
                                                    }
                                                </>
                                            ))
                                        }
                                    </React.Fragment>
                                }
                            >
                                <HelpIcon style={{ color: '#0076fe', marginBottom: '25px' }} />
                            </HtmlTooltip>
                        </td>
                    </tr>
                </table>
                <Grid container spacing={2} className={classes.description}>
                    <Grid item>
                        <Typography color="textSecondary" component="p">
                            {props.data.description}
                        </Typography>
                    </Grid>
                </Grid>
                {
                    props.data.status != 'Failed'
                    ?
                    <Grid container spacing={2} style={{height:'70px'}}>
                        <Grid item xs={6}>
                            <Typography className={classes.totalVulnerablities}>
                                <b><Tooltip placement="top" id="placement" title={props.data.vulnerabilitiesFound}><span>{props.data.vulnerabilitiesFound.toString().length >= 4 ? Math.trunc(props.data.vulnerabilitiesFound / 1000) + 'K' : props.data.vulnerabilitiesFound}</span></Tooltip></b>
                            </Typography>
                            <Typography className={classes.vCount} style={{ color: qualityGateColor(props.data.securityQualityGate)}}>
                                Vulnerabilities
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography className={classes.dependencies}>
                                                <Tooltip placement="top" id="placement" title={props.data.vulnerableDependencies}><span>{props.data.vulnerableDependencies.toString().length >= 4 ? Math.trunc(props.data.vulnerableDependencies / 1000) + 'K' : props.data.vulnerableDependencies}</span></Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography className={classes.dCount}>
                                                Dependencies
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="mt-4" >
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography className={classes.dependencies}>
                                                <Tooltip placement="top" id="placement" title={props.data.dependenciesScanned}><span>{props.data.dependenciesScanned.toString().length >= 4 ? Math.trunc(props.data.dependenciesScanned / 1000) + 'K' : props.data.dependenciesScanned}</span></Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography className={classes.dCount}>
                                                Scanned
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    props.data.status === 'Failed'
                    ?
                    <Grid container spacing={4} className={classes.description} style={{ height: '10px'}}>
                        <Grid item>
                            <Typography color="textSecondary" style={{ color:'red'}} component="p">
                                At the moment we couldn't process this report, Please try again later.
                            </Typography>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={4} className={classes.description}>
                        <Grid item className='pl-5 ml-4 pr-5 mt-2'>
                            <Typography color="textSecondary" component="p">
                                Category is not chosen
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </CardActionArea>
            {(props.data.status === "New" || props.data.status === "In Progress") && <LinearProgress />}
        </Card>
    )
}

export default RepoAnalysisReportSecurity;