import React from 'react';
import MaterialTable from 'material-table';
import PdfHeader from './PdfHeader';

const columnDefs = [
    {
        title: "RepoName", field: "repoName"
    }, {
        title: "# Reviews", field: "noOfReviews"
    }, {
        title: "# LinesAdded", field: "numOfLinesAdded"
    }
];

const ReviewByRepositoryTable = React.forwardRef((props, ref) => {

    return (
        <div className="container p-5" ref={ref} style={{ margin: '0 0 0 -30px' }}>
            <PdfHeader startDate={props.startDate} endDate={props.endDate} />
            <div className="col-12 py-5 my-2">
                <h2 className="ml-4 mb-2">Repository</h2>
                <MaterialTable
                    title="Repos"
                    columns={columnDefs}
                    data={props.data}
                    options={{
                        filtering: false,
                        toolbar: false,
                        headerStyle: {
                            padding: '5px',
                            fontSize: '15px',
                            fontWeight: "bold",
                            color: 'black',
                        },
                        paging: false
                    }}
                />
            </div>
        </div>
    );
});

export default ReviewByRepositoryTable;