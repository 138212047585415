import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "darkslategrey",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        marginBottom: 20,
        fontSize: "0.875rem"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 600
    },
    depTable: {
        marginBottom: 15,
        border: "1px solid lightgray"
    }
}));

function RelatedDependencies(props) {
    const classes = useStyles();
    const dependencies = props.data
    return (
        <TableContainer>
            {
                dependencies?.map((dep, index) => (
                    <Table key={index} className={classes.depTable} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell width="10%">File Name</StyledTableCell>
                                <StyledTableCell width="90%">{ dep.fileName}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell width="10%">File Path</StyledTableCell>
                                <StyledTableCell width="90%">{dep.filePath}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell width="10%">MD5</StyledTableCell>
                                <StyledTableCell width="90%">{dep.md5}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell width="10%">SHA1</StyledTableCell>
                                <StyledTableCell width="90%">{dep.sha1}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell width="10%">SHA256</StyledTableCell>
                                <StyledTableCell width="90%">{dep.sha256}</StyledTableCell>
                            </StyledTableRow>
                            {
                                dep?.packageIds?.map((packageIds, pIndex) => (
                                    <StyledTableRow key={pIndex}>
                                        <StyledTableCell colSpan={2} >
                                            <Link target="_blank" href={packageIds.url}>
                                                {packageIds.id}
                                            </Link>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            }
                            
                        </TableBody>
                    </Table>
                ))
            }
        </TableContainer>
    )
}

export default RelatedDependencies