import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Grid from '@material-ui/core/Grid';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Divider from '@material-ui/core/Divider';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import DuplicationTreeView from './DuplicationTreeView';
import { useEffect } from "react";
import { useQuery } from 'react-apollo';



const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: '15px'
    },
    tablehead: {
        backgroundColor: '#F5F5F5',
        borderRadius: 0,
        fontSize: '15px',
        padding: '10px',
        maxWidth: '96%',
    },
    content: {
        fontSize: '15px',
    },
    header: {
        fontWeight: 'bold',
        padding: '15px 15px 0px 15px',
    },
    grid: {
        paddingLeft: '2px',
        paddingRight: '8px',
    },
    box: {
        backgroundColor: '#FFFFFF',
        minHeight: '97%'
    },
    paper: {
        fontSize: '15px',
        padding: '10px',
        maxWidth: '96%',
    },
    typography: {
        fontSize: '15px',
        lineHeight: '2.0'
    },
    stepContent: {
        overflowWrap: 'anywhere',
        paddingLeft: 0
    }

}));

const GET_DUPLICATIONS_LIST = gql`
query duplicationsById (
    $repoScanResponseId: Long!, 
    $pageNum: Int, 
    $pageSize: Int, 
    $direction: Direction
    )
    {   duplicationsById(
            repoScanResponseId: $repoScanResponseId, 
            pageNum:$pageNum,
            pageSize:$pageSize,
            direction:$direction
        )
        {
            cpdScanId
            repoScanResponseId
            repoUrl
            status
            format
            scannedReport {
                duplication {
                    lines
                    tokens
                    file {
                        column
                        endcolumn
                        endline
                        line
                        path
                    }
                    codefragment
                    duplicationCodeFragment{
                        text
                    }
                }
            }
            textReport
            minimumTokens
            localScan
            dispatchStatus
            eligibleForRescan
            scanThreadId
            scanStartedOn
            scanCompletedOn	
            totalPages
            totalElements
            last
            first
            numberOfElements
            size
            number
        }
    }`;

function RepoAnalysisDuplicationTreeView(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [row, setRow] = React.useState(10);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);    

    const { error, data, loading, refetch } = useQuery(GET_DUPLICATIONS_LIST, {
        variables: {
            repoScanResponseId: props.repoScanResponseId,  
            pageNum: page,
            pageSize: row,
            sortBy: "scanDate",
            direction: "ASC"
        }
    });

    const parentFunct = async (pa, ro) => {
        setPage(pa);
        setRow(ro);
        await refetch();
        window.scrollTo(0, 0);
    }

    

    return (
        <div className={classes.root}>
            {
                loading ? <div className="loading"><CircularProgress size={50} /></div> : ""
            }
            <Box className={classes.box}>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant='h5' className={classes.header}>Duplications</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={"p-0 pl-3 pr-3 badge bg-gray"} style={{ fontSize: "12px", fontWeight: 800, borderRadius: "5px", marginTop: '25px' }}>
                            Total Count : {data?.duplicationsById?.totalElements}
                        </Typography>
                    </Grid>
                    
                </Grid>

                <Stepper activeStep={0} orientation="vertical" className='pt-2 pl-4 pr-4 pb-4'>
                    <Step>
                        {/* <StepLabel StepIconComponent={InsertDriveFileIcon}>
                            <Typography variant="p" className={classes.content}>
                                {"Duplications found at the following page"}
                            </Typography>
                        </StepLabel> */}
                        <StepContent className={classes.stepContent}>
                            <DuplicationTreeView 
                            data={data?.duplicationsById?.scannedReport?.duplication}
                            totalElements={data?.duplicationsById?.totalElements}
                            parentFunct={parentFunct}
                            number={data?.duplicationsById?.number}
                            size={data?.duplicationsById?.size}
                        />
                        </StepContent>
                    </Step>
                </Stepper>
            </Box>
        </div>
    )
}
export default RepoAnalysisDuplicationTreeView