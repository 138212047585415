import React from 'react';
import './languagebarmodel.css'
const color = [
    '#3677FE',
    '#009999',
    '#cc3399',
    '#4A10C6',
    '#eb4d4b',
    '#229254',
    '#cc00cc',
    '#339966',
    '#FF6666',
    '#9933FF',
    '#FF9900',
    '#990000',
    '#22a6b3',
    '#660066',
    '#ffcc00',
    '#6ab04c',
    '#e056fd',
    '#89CF4E',
    '#DA8542',
    '#7E504E',
    '#CFBE11',
    '#265693',
    '#5C853D',
    
];

const roundTo = require('round-to');
class LanguageBarRepo extends React.Component {

    render() {
        const parent = this.props;
        var bars = parent.readings && parent.readings.length && parent.readings.map(function (item, i) {
           return (item.percentage > 0 ?
                    (<div className="bar" style={{ 'backgroundColor': color[i], 'width': item.fullPercentage || item.percentage - 0.01 + '%' }} key={i}>

                    </div>) : null)
        }, this);

        var legends = parent.readings && parent.readings.length && parent.readings.map(function (item, i) {
            return (
                item.percentage > 0 ? (<div className="legend mt-2" key={i}>
                    <span className="dot" style={{ 'backgroundColor': color[i] }}></span>
                    <span className="label">{item.language} {roundTo.up(item.percentage, 2)}%</span>
                </div>) : null)
        }, this);

        return (
            <div className="multicolor-bar">
                <div className="bars">
                    {bars ? bars: ""}
                </div>
                <div className="legends">
                    {legends ?legends:""}
                </div>
            </div>
        );
    }
}
export default LanguageBarRepo;