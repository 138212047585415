import React from 'react';
import ReactToPrint from 'react-to-print';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReviewByRepositoryTable from './ReviewByRepository/ReviewByRepositoryTable';

function PdfExportIcon(props) {
    const componentRef = React.useRef(null);

    return (
        <span>
            <ReactToPrint
                trigger={() => (
                    <IconButton className={"mr-2 floate-right default"} color="default">
                        <Tooltip title="Export PDF" placement="top">
                            <PictureAsPdfIcon />
                        </Tooltip>
                    </IconButton>
                )}
                content={() => {
                    return componentRef.current;
                }}
            />
            <div style={{ display: "none" }}>
                {
                    props.pdfExport
                    &&
                    <ReviewByRepositoryTable
                        data={props.data}
                        ref={componentRef}
                        startDate={props.startDate}
                        endDate={props.endDate}
                    />
                }
            </div>
        </span>
    )
}

export default PdfExportIcon;