import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
// import BaseTree from './TreeChild';
import React, { Component } from 'react';
import RepoAnalysisTreeChild from './RepoAnalysisTreeChild';

class RepoAnalysisTreeBase extends Component {
    constructor(props) {
        super(props);
        this.updateFile = this.updateFile.bind(this);
    }

    updateFile(filePath, commentsCount) {
        this.props.updateFile(filePath, commentsCount)
    }
     
    render() {
        return (
            <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultExpanded={this.props.node}
                defaultEndIcon={<div style={{ width: 24 }} />}
            >
                <RepoAnalysisTreeChild updateFile={this.updateFile} data={this.props.data} />
            </TreeView>
        );
    }
}

export default RepoAnalysisTreeBase;